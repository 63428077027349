<div class="header-strip" [style.background]="salectedColor">
  <div class="humberger-wrapper">
    <a class="header-btn" (click)="onSideMenuClick($event)"><i class="pi pi-bars"></i>
    </a>
    <img src="/assets/images/kona_OS_icon.png" />
  </div>

  <ul class="header--menu fadeInDown">
    <li class="user-profile" (click)="onChooseProfile($event)">
      <a><img alt="Profile image" [src]="profileImage" /></a>
      <!-- <a><i class="pi pi-user"></i></a> -->
      <ul class="fadeInDown">
        <li *ngIf="isFranchise || isAdmin || isStaffManager" role="menuitem" (click)="onProfileClick()">
          <a href="#"><i class="fa fa-user"></i><span>Profile</span></a>
        </li>
        <li role="menuitem" (click)="doSignOut()">
          <a href="#"><i class="fa fa-sign-out"></i><span>Logout</span></a>
        </li>
      </ul>
    </li>

    <li *ngIf="isFranchise || isStaffManager" class="user-profile" (click)="waffleMenuShow.toggle($event)" title="Intranet">
      <a><img alt="Profile image" src="/assets/images/waffle_menu_main.svg" /></a>
    </li>
    <li *ngIf="isFranchise || isStaffManager" class="user-profile" (click)="showNotification()">
      <a href="javascript:void(0)">
        <!-- <i class="pi pi-bell mr-4 p-text-secondary" pBadge style="font-size: 2rem" value="2" severity="danger"></i> -->
        <img src="/assets/images/notification.svg" pBadge value="2" severity="danger" alt="Notification"
          class="notification-bell">
        <div class="notification-oval" *ngIf="this.notificationsCount != 0">
          <div class="notification-count">{{this.notificationsCount}}</div>
        </div>
      </a>
    </li>
    <li class="user-profile mob-show" *ngIf="isFranchise || isStaffManager">
      <a  (click)="openMultiselect($event)"> <img src="/assets/images/kurbside_icon@2x.png"></a>
    </li>

    <p-overlayPanel #waffleMenuShow>
      <ng-template pTemplate>
        <div class="grid waffle-menu">
          <div class="col-3 waffle-menu-box" *ngFor="let item of waffleMenu; let i = index">
            <a [href]="redirectToUrl(waffleMenu[i].targetUrl)" target="_blank" [title]="waffleMenu[i].menuTitle">
              <img class="menu-img" [src]="waffleMenu[i].signUrl" />
              <div class="menu-lable" [title]="waffleMenu[i].menuTitle">
                {{ waffleMenu[i].menuTitle }}
              </div>
            </a>
          </div>
        </div>
      </ng-template>
    </p-overlayPanel>
  </ul>

  <div class="filter-item col-12 lg:col-2 md:col-3 brand-div" *ngIf="(isFranchise || isStaffManager) && showBrandfilter">
    <div class="form-control input-container brand">
      <p-multiSelect  #brandButton [options]="brandNameOptions" (onChange)="onSelectBrand($event)" [(ngModel)]="selectedBrandFromFilter"
        [maxSelectedLabels]="1" [showHeader]="false" [filter]="false" selectedItemsLabel="{0} Brands selected" placeholder="Select Brand" (onPanelHide)="hideBrandfilter()">
      </p-multiSelect>
    </div>
  </div>
</div>
<!-- <app-notification-strip [broadcastData]="broadcastData" *ngIf="!isStaff && broadcastData"></app-notification-strip> -->
<app-notification-strip [broadcastData]="broadcastData" *ngIf="broadcastData" [isStaff] = "isStaff"></app-notification-strip>
<p-sidebar [(visible)]="displayNotification" position="right" showCloseIcon="true" (onShow)="getNotificationList()">
  <div class="notification-list" *ngFor="let notify of notifications;">
    <div [ngClass]="checkPermission(notify.type) ? 'access' : 'deny'">
      <div class="notification-box" [ngClass]="notify.notificationViewed ? 'read' : 'unread'" 
      (click)="navNotify(notify.type, notify.id)">
        <div class="notification-title">{{notify.notificationText}}</div>
        <div class="notification-date">{{commonBindingDataService.convertedTimeAsPerTz1(notify.createdAt) | date: 'MMM dd, yyy. hh:mm a'}}</div>
      </div>
    </div>

  </div>
</p-sidebar> 

<!-- <p-confirmDialog header="Confirmation" [blockScroll]="'false'" icon="pi pi-exclamation-triangle" defaultFocus="none"
  maskStyleClass="ui-dialog-mask ui-widget-overlay ui-dialog-visible ui-dialog-mask-scrollblocker" [ngClass] = "'custom-confirmation-style'"></p-confirmDialog> -->

<p-confirmDialog header="Confirmation" [blockScroll]="'false'" defaultFocus="none"
  maskStyleClass="p-dialog-mask p-widget-overlay p-dialog-visible p-dialog-mask-scrollblocker"
  [ngClass]="'custom-confirmation-style'"></p-confirmDialog>