<div class="container">
    <div class="graph-container">
        <div class ="arrow-div" title ="up" >
            <div class="fa fa-angle-up up-arrow" (click) = "loadMore('up')"></div>
        </div>
        <div>
            <canvas #barCanvas style="height:12vh; width:10vw"></canvas>
        </div>
        <div class ="arrow-div" title ="down">
            <div class="fa fa-angle-down down-arrow" (click) = "loadMore('down')"></div>
        </div>
    </div>
</div>
