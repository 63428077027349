import { Injectable } from '@angular/core';
import { RestApiService } from '../../shared/services/rest-api.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private restApiService: RestApiService) { }

  addFranchise(data): Observable<any> {
    return this.restApiService.post('addFranchise', '/secure/franchises', data, 'page-center');
  }


  getFranchiseDetails(id): Observable<any> {
    return this.restApiService.get('franchiseDetails', `/secure/franchises/details/${id}`, 'page-center');
  }
  getFranchiseListBySearchText(text): Observable<any> {
    return this.restApiService.get('getFranchise', `/secure/franchises/grid-data-minimal?searchText=${text}`, 'page-center');
  }

  updateFranchise(data): Observable<any> {
    return this.restApiService.put('updatFranchise', '/secure/franchises', data, 'page-center');
  }

  activeInactiveFranchise(data): Observable<any> {
    return this.restApiService.put('activeInactiveFranchise', '/secure/franchises/activate-deactivate/status',
      data, 'page-center');
  }

  getFranchiseSetting(id): Observable<any> {
    return this.restApiService.get('franchiseDetails', `/secure/settings/${id}`, 'page-center');
  }

  updateFranchiseSetting(data): Observable<any> {
    return this.restApiService.put('updatFranchise', '/secure/settings', data, 'page-center');
  }

  addFranchiseConatcts(data): Observable<any> {
    return this.restApiService.post('addFranchiseContact', '/secure/contact-persons', data, 'page-center');
  }

  activeInactiveContact(data): Observable<any> {
    return this.restApiService.put('activeInactiveFranchise', '/secure/contact-persons/activate-deactivate/status',
      data, 'page-center');
  }

  getContactTypeList(): Observable<any> {
    return this.restApiService.get('contactTypeList', `/secure/contact-types`, 'page-center');
  }


  addTemplates(data): Observable<any> {
    return this.restApiService.post('addTemplates', '/secure/templates', data, 'page-center');
  }

  getTemplateByTemplateId(templateId): Observable<any> {
    return this.restApiService.get('franchiseDetails', `/secure/templates/${templateId}`, 'page-center');
  }

  //newly addded
  getAllMenuCategoryList(): Observable<any> {
    return this.restApiService.get('getAllMenuCategory',
        '/secure/menus-categories/grid-data?applyActivatedStatus=false&activated=true&searchText=&offset=0&limit=999&sortColumn=&sortType=asc', 'page-center');
  }


  updateTemplateDetails(data): Observable<any> {
    return this.restApiService.put('updateDetails', `/secure/templates`, data, 'page-center');
  }
  getTemplateDetails(data): Observable<any> {
    return this.restApiService.put('updateDetails', `/secure/templates`, data, 'page-center');
  }
  activeInactiveTemplates(data): Observable<any> {
    return this.restApiService.put('activeInactiveTemplates', '/secure/templates/activate-deactivate/status',
      data, 'page-center');
  }

  deleteTemplates(data): Observable<any> {
    return this.restApiService.put('deleteMenuTemplates', '/secure/templates/delete-template',
      data, 'page-center');
  }

  getContactDetailByContactId(contactId): Observable<any> {
    return this.restApiService.get('franchiseDetails', `/secure/contact-persons/details/${contactId}`, 'page-center');
  }

  menuCategoryList(): Observable<any> {
    return this.restApiService.get('menuCategoryList', `/secure/menus-categories/grid-data?applyActivatedStatus=false&activated=false&searchText=&offset=0&limit=0&sortColumn=&sortType=asc`, 'page-center');
  }
  templateCategoryList(): Observable<any> {
    return this.restApiService.get('menuCategoryList', `/secure/menus-categories`, 'page-center');
  }

  updateContactDetails(data): Observable<any> {
    return this.restApiService.put('updatFranchise', '/secure/contact-persons', data, 'page-center');
  }

  getDashboardDetails(id): Observable<any> {
    return this.restApiService.get('dashboardDetails', `/secure/franchises/dashboard?franchiseId=${id}`, 'page-center');
  }
  tempateCategoryList(templateId): Observable<any> {
    return this.restApiService.get('templateDetails', `/secure/templates/${templateId}`, 'page-center');
  }

  activeInactiveWaffle(data): Observable<any> {
    return this.restApiService.put('activeInactiveWaffle', '/secure/waffle-menu/activate-deactivate/status', data, 'page-center');
  }

  deleteWaffle(id): Observable<any> {
    return this.restApiService.delete('deleteWaffle', `/secure/waffle-menu/${id}`, 'page-center');
  }

  getWaffleDetails(id): Observable<any> {
    return this.restApiService.get('waffleDetails', `/secure/waffle-menu/${id}`, 'page-center');
  }

  addWaffleMenu(data): Observable<any> {
    return this.restApiService.post('addWaffleMenu', `/secure/waffle-menu`, data, 'page-center');

  }

  updateWaffleMenu(data): Observable<any> {
    return this.restApiService.put('updateWaffleMenu', `/secure/waffle-menu`, data, 'page-center');

  }

  fetchWaffleMenu(): Observable<any> {
    return this.restApiService.get('getWaffleMenu', `/secure/waffle-menu?applyActivatedStatus=&activated=&searchText=&offset=0&limit=999&sortColumn=indexOrder&sortType=asc`, 'page-center');
  }

  updateWaffleMenuIndexOrder(data): Observable<any> {
    return this.restApiService.put('updateWaffleMenuIndexOrder', `/secure/waffle-menu/index-order`, data, 'page-center');
  }

  getAllBrandsList(): Observable<any> {
    return this.restApiService.get('getBrandsList', `/secure/brands`, 'page-center');
  }

  addNewBrand(data): Observable<any> {
    return this.restApiService.post('addBrand', `/secure/franchise-brands`, data, 'page-center');
  }

  deleteBrand(data): Observable<any> {
    return this.restApiService.put('deleteBrand', `/secure/franchise-brands/delete`, data, 'page-center');
  }

  activeDeactiveBrand(data): Observable<any> {
    return this.restApiService.put('deleteBrand', `/secure/franchise-brands/activate-deactivate/status`, data, 'page-center');
  }


  getFranchisebyCode(code): Observable<any> {
    return this.restApiService.get('getFranchiseList', `/secure/franchises/list/${code}/autocomplete`, 'page-center');
  }

  getFranchiseDetailsByCode(id): Observable<any> {
    return this.restApiService.get('getFranchiseDetails', `/secure/franchises/search/${id}`, 'page-center');
  }

  partialMigration(data): Observable<any> {
    return this.restApiService.post('partialMigration', `/secure/franchises/partial-migration/franchise-data`, data, 'page-center');
  }

  aggregateContacts(data): Observable<any> {
    return this.restApiService.post('aggregateContacts', `/secure/franchises/partial-migration/aggregate-contacts`, data, 'page-center');
  }

  getMessageReceiversList(): Observable<any> {
    return this.restApiService.get('getFranchise', `/secure/super-settings/broadcast-message-receivers`, 'page-center');
  }

  getAdminSettings(): Observable<any> {
    return this.restApiService.get('getAdminSetting', `/secure/super-settings`)
  }

  updateAdminSettings(data): Observable<any> {
    return this.restApiService.put('updateAdminSettings', `/secure/super-settings`, data, 'page-center');
  }


}
