import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { UiService } from '../../services/ui.service';


@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  providers: [MessageService]
})
export class MessageComponent implements OnInit {

  constructor(public uiService: UiService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.uiService.showMessageEvent.subscribe(result => {
      this.messageService.add({ severity: result.severity, summary: result.summary, detail: result.detail,life: 1000 }); // life: 10000,sticky: true,
    });

  }

  closeDialog() {
    this.uiService.isShowMessage = false;
  }

}
