import { Injectable } from '@angular/core';
import { UntypedFormGroup, ValidationErrors, UntypedFormArray } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ErrorBindingService {

  constructor() { }

  getFormValidationErrors(formGroup: UntypedFormGroup, index?: number) {
    let setFirstFocus = true;
    Object.keys(formGroup.controls).forEach((key) => {
      const controlErrors: ValidationErrors = formGroup.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(() => {
          if (setFirstFocus && document.getElementById(key)) {
            document.getElementById(key).focus();
            setFirstFocus = false;
          }

          if (setFirstFocus && document.getElementById(key + index)) {
            document.getElementById(key + index).focus();
            setFirstFocus = false;
          }
        });
      }

      const formArray = (<UntypedFormArray>formGroup.get(key));
      if (formArray.length > 0) {
        for (let i = formArray.length - 1; i >= 0; i--) {
          if (setFirstFocus) {
            this.getFormValidationErrors(<UntypedFormGroup>formArray.controls[i], i);
          }
        }
      }
    });
  }
}
