<div class="container">
    <!-- <div class="graph-legend">
        <div class="category">Sales</div>
        <div class="data-value">
            <div id="current-value"class="current">{{actualValue}}</div>
            <div id="compared-value"class="compared">{{comparedValue}}</div>
        </div>
    </div> -->
    <div class="graph-container">
        <div class ="arrow-div" title ="up" >
            <div class="fa fa-angle-up up-arrow" (click) = "loadMore('up')"></div>
        </div>
        <div>
            <canvas #barCanvas style="height:12vh; width:10vw"></canvas>
        </div>
        <div class ="arrow-div" title ="down">
            <div class="fa fa-angle-down down-arrow" (click) = "loadMore('down')"></div>
        </div>
    </div>
</div>
