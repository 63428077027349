<form [formGroup]="reportGenerateForm">
    <div class="form-item">
        <div class="form-field">
            <div class="form-label">
                <label for="email" translate>Email<span class="required-star">*</span></label>
            </div>
            <div class="form-control">
                <input formControlName="email" id="email" class="full-width" type="text" appNoWhiteSpace pInputText appPreventSomeSpecialCharacters allowQuote="no"/>
                <div class="form-errors"
                    *ngIf="reportGenerateForm.controls.email.errors  && (reportGenerateForm.controls.email.dirty || reportGenerateForm.controls.email.touched)">
                    <div [hidden]="!reportGenerateForm.controls.email.errors.required" translate>
                        Field is required
                    </div>
                    <div [hidden]="!reportGenerateForm.controls.email.errors.minlength" translate>
                        min_length_1char
                    </div>
                    <div [hidden]="!reportGenerateForm.controls.email.errors.maxlength" translate>
                        max_length_120char
                    </div>
                    <div [hidden]="!reportGenerateForm.controls.email.errors.pattern" translate>
                        error_email_invalid
                    </div>
                </div>
            </div>
            <div class="form-item col-12 lg:col-10 md:col-1 p-dialog p-add-report-dialog">
                <div class="form-field">
                    <div class="form-label">
                        <label for="emailNote" translate>label_email_notes</label>
                    </div>
                    <div class="form-control">
                        <textarea formControlName="emailNote" id="emailNote" class="full-width" type="text" pInputTextarea
                            autoResize="">
                        </textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="p-justify-end page-buttons p-mt-2">
        <button pButton [label]="'btn_cancel' | translate" type="submit" class="cancel-btn"
        (click)="onCancelClick()" ></button>
        <button pButton [label]="'Generate' | translate" type="submit" class="btn-save"
        (click)="isPost===false ? onConfirmClick() : onConfirmPostClick()" ></button>
    </div>
</form>