import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonBindingDataService } from '../../services/common-binding-data.service';

@Component({
  selector: 'app-customer-footer',
  templateUrl: './customer-footer.component.html'
})
export class CustomerFooterComponent implements OnInit, OnDestroy {
  termsDialog: boolean;
  privacyDialog: boolean;
  cookiesDialog: boolean;
  ccpaDialog: boolean;
  isDisplayFooter = true;
  subscribedVariable: Subscription;
  constructor(
    private router: Router,
    private commonService: CommonBindingDataService,
  ) {
    this.checkRoute();
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkRoute();
      }
    });
    this.subscribedVariable = this.commonService.isProfileUpdated.subscribe(result => {
      this.showTermsDialog();
    });
  }


  checkRoute() {
    if (this.router.url.includes('pay')) {
      this.isDisplayFooter = false;
    } else {
      this.isDisplayFooter = true;
    }
  }

  showTermsDialog() {
    this.termsDialog = true;
    this.privacyDialog = false;
    this.cookiesDialog = false;
    this.ccpaDialog = false;
  }

  showPrivacyDialog() {
    this.termsDialog = false;
    this.privacyDialog = true;
    this.cookiesDialog = false;
    this.ccpaDialog = false;
  }

  showCookiesDialog() {
    this.termsDialog = false;
    this.privacyDialog = false;
    this.cookiesDialog = true;
    this.ccpaDialog = false;
  }

  showCcpaDialog() {
    this.termsDialog = false;
    this.privacyDialog = false;
    this.cookiesDialog = false;
    this.ccpaDialog = true;
  }

  ngOnDestroy() {
    this.subscribedVariable.unsubscribe();
  }
}
