<div class = "page-layout">
<div class="grid form-container">
    <form [formGroup]='setPasswordForm'>
  
      <div class="grid">
        <div class="form-field col-12 md:col-12 lg:col-12">
          <div class="form-errors alert alert-danger" [hidden]='!passwordError[0]["general"]'>
            <div *ngFor="let ErrorMessage of passwordError['general']">
              {{ErrorMessage.message}}
            </div>
          </div>
        </div>
      </div>
  
      <div class="grid">
        <div class="form-field col-12">
          <div class="form-label">
            <label for="newPassword" translate>label_new_password</label>
          </div>
          <div class="form-control">
            <input type="password" pInputText appNoWhiteSpace formControlName="newPassword" autocomplete="off"/>
          </div>
          <div class="form-errors" *ngIf="setPasswordForm.controls.newPassword.errors  && (setPasswordForm.controls.newPassword.dirty || setPasswordForm.controls.newPassword.touched)">
            <div [hidden]="!setPasswordForm.controls.newPassword.errors.required" translate>
              error_new_password_required
            </div>
            <div [hidden]="!setPasswordForm.controls.newPassword.errors.minlength" translate>
              password_too_short
            </div>
            <div [hidden]="!setPasswordForm.controls.newPassword.errors.maxlength" translate>
              exceed_max_length
            </div>
          </div>
        </div>
  
        <div class="form-field col-12">
          <div class="form-label">
            <label for="newConfirmPassword" translate>label_new_confirm_password</label>
          </div>
          <div class="form-control">
            <input type="password" pInputText appNoWhiteSpace formControlName="newConfirmPassword" autocomplete="off"/>
          </div>
          <div class="form-errors" *ngIf="setPasswordForm.controls.newConfirmPassword.errors  && (setPasswordForm.controls.newConfirmPassword.dirty || setPasswordForm.controls.newConfirmPassword.touched)">
            <div [hidden]="!setPasswordForm.controls.newConfirmPassword.errors.required" translate>
              error_new_confirm_password_required
            </div>
            <div [hidden]="!setPasswordForm.controls.newConfirmPassword.errors.minlength" translate>
              password_too_short
            </div>
            <div [hidden]="!setPasswordForm.controls.newConfirmPassword.errors.maxlength" translate>
              exceed_max_length
            </div>
          </div>
        </div>
  
      </div>
  
      <div class="form-buttons col-12">
        <button pButton type="button" label="Save" icon="fa fa-check" [disabled]="!setPasswordForm.valid" (click)="doChangePassword()"></button>
        <button class="negative-button" pButton type="button" icon="fa fa-close" label="Cancel" (click)="onCancel()"></button>
      </div>
    </form>
  
  </div>
</div>
  