import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AppSettings } from '../../app.settings';
import { Validators, UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { UserCommonService } from '../../services/user-common.service';
import { UiService } from '../../services/ui.service';
import { Message } from 'primeng/api';
import { Subscription } from 'rxjs';
import { CommonMessageTransferService } from '../../services/common-message-transfer.service';
import { ErrorDto } from '../../models/error-dto';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  @Output() popClose: EventEmitter<any> = new EventEmitter();
  @Output() popSave: EventEmitter<any> = new EventEmitter();
  forgotPasswordErrors = [
    {
      username: false
    }
  ];
  message: Message[] = [];
  subscription: Subscription;
  submitOnce = false;
  editforgotForm = new UntypedFormGroup({
    username: new UntypedFormControl()
  });



  constructor(
    private formBuilder: UntypedFormBuilder,
    // private translate: TranslateService,
    private commonServices: UserCommonService,
    private uiService: UiService,
    private commonMessageTransferService: CommonMessageTransferService,
  ) { }

  ngOnInit() {
    this.editforgotForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.pattern(AppSettings.EMAIL_PATTERN)]],
    });

    this.subscription = this.commonMessageTransferService.restAPIGeneralErrorEvent.subscribe(
      (error: ErrorDto) => {
        this.submitOnce = false;
        this.uiService.showMessage(error.message, AppSettings.MESSAGE_TYPE.ERROR);
      }
    );
  }

  closeEditForgot(event) {
    this.editforgotForm.reset();
    this.popClose.emit();
  }

  changePassword(event) {
    this.submitOnce = true;
    this.message = [];
    let input;
    let otpEmail;
    let otpPhone;
    if (this.editforgotForm.valid) {

      const userName = this.editforgotForm.controls.username.value;
      if (userName.match(AppSettings.EMAIL_PATTERN)) {
        otpEmail = userName;
        input = {
          email: userName.trim().toLowerCase().trim(),
          // tenantId: AppSettings.TENANT
        };
      } else if (userName.match(AppSettings.PHONE_PATTERN)) {
        otpPhone = userName;
        input = {
          phoneNum: userName,
          // tenantId: AppSettings.TENANT
        };
      } else {
        input = {
          username: userName,
          // tenantId: AppSettings.TENANT
        };
      }
      this.commonServices.forgotPassword(JSON.stringify(input)).subscribe(results => {
        results.email = otpEmail;
        // results.phone = otpPhone;
        this.popSave.emit(results);
        this.editforgotForm.reset();
        this.uiService.showMessage(results.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
        this.submitOnce = false;
      }, (error) => {
        console.log(error);
        // this.errorBindingService.onAPIValidationError(error, this.forgotPasswordErrors);
      });
    } else {
      this.editforgotForm.markAllAsTouched();

    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
