import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-customer-header',
  templateUrl: './customer-header.component.html'
})
export class CustomerHeaderComponent implements OnInit {
  isDisplayHeader: boolean;
  n;
  hideBack = false;
  fromComponent = '';
  constructor(
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.checkRoute();
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkRoute();
      }
    });

    window.onhashchange = () => {
      this.fromComponent = this.route.snapshot.queryParams.from;
      setTimeout(() => {
        if (this.router.url.includes('top-donners') || this.router.url.includes('top-comments')) {
          if (this.fromComponent && this.fromComponent === 'direct-donation') {
            this.router.navigate(['/direct-campaign-donation']);
          } else {
            this.router.navigate(['/receipt']);
          }
        }
      }, 500);
    };



  }

  checkRoute() {
    this.fromComponent = '';
    this.hideBack = false;
    if (this.router.url.includes('products') || this.router.url.includes('customer-details') ||
      this.router.url.includes('cart') || this.router.url.includes('pay')
      || this.router.url.includes('order-fail') || this.router.url.includes('order-complete') ||
      this.router.url.includes('receipt')
      || this.router.url.includes('direct-campaign-donation')
      || this.router.url.includes('top-donners') || this.router.url.includes('top-comments')) {
      this.isDisplayHeader = true;
    } else {
      this.isDisplayHeader = false;
    }

    if (this.router.url.includes('pay') ||
      this.router.url.includes('search') ||
      this.router.url.includes('receipt') ||
      this.router.url.includes('order-complete')) {
      this.hideBack = true;
    }
    this.fromComponent = this.route.snapshot.queryParams.from;
  }

  onBack() {
    if (this.router.url.includes('top-donners') || this.router.url.includes('top-comments')) {
      if (this.fromComponent && this.fromComponent === 'direct-donation') {
        this.router.navigate(['/direct-campaign-donation']);
      } else {
        this.router.navigate(['/receipt']);
      }
    } else if (this.router.url.includes('direct-campaign-donation')) {
      this.router.navigate(['/details']);
    } else if (this.router.url.includes('customer-details')) {
      this.location.back();
    } else if (this.router.url.includes('details')) {
      this.router.navigate(['/search']);
    } else {
      this.location.back();
    }

  }

  onBrowserBack() {
    // if (this.router.url.includes('top-donners') || this.router.url.includes('top-comments')) {
    //   if (this.fromComponent && this.fromComponent === 'direct-donation') {
    //     this.router.navigate(['/direct-campaign-donation']);
    //   } else {
    //     this.router.navigate(['/receipt']);
    //   }
    // }
    // else if (this.router.url.includes('direct-campaign-donation')) {
    //   this.router.navigate(['/details']);
    // }
    // else {
    //   this.location.back();
    // }
  }

  navigateToHome() {
    this.router.navigate(['/search']);
  }

}
