import { MbscModule } from '@mobiscroll/angular';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { HttpClientModule } from '@angular/common/http';
import { HttpErrorHandler } from './modules/shared/services/http-error-handler.service';
import { NgModule } from '@angular/core';
import { SharedModule } from './modules/shared/shared.module';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { StripeComponent } from './pages/stripe/stripe.component';
import { PanelModule } from 'primeng/panel';
import { EditorModule } from 'primeng/editor';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { FieldsetModule } from 'primeng/fieldset';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CalendarModule } from 'primeng/calendar';
import { FileUploadModule } from 'primeng/fileupload';
import { MessageService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { TreeModule } from 'primeng/tree';
import { DropdownModule } from 'primeng/dropdown';
import { PrintWeekComponent } from './pages/print-week/print-week.component';
import { PrintWeekEventsListComponent } from './components/print-week-events-list/print-week-events-list.component';

@NgModule({
  declarations: [AppComponent, SignUpComponent, SignInComponent, StripeComponent, PrintWeekComponent, PrintWeekEventsListComponent],
  imports: [
    MbscModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PanelModule,
    InputTextModule,
    EditorModule,
    DialogModule,
    MultiSelectModule,
    AppRoutingModule,
    FieldsetModule,
    OverlayPanelModule,
    CalendarModule,
    FileUploadModule,
    ConfirmDialogModule,
    TreeModule,
    DropdownModule,
    SharedModule.forRoot(),
  ],
  providers: [HttpErrorHandler, MessageService],
  bootstrap: [AppComponent],
})
export class AppModule { }


