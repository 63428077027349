
 
<div style="padding-right: 7px;">
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:center;'><strong><span style='font-size:21px;font-family:"Times New Roman","serif";'>Terms of Service</span></strong></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:8.05pt;'><span style='font-size:16px;font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:center;'><span style='font-size:16px;font-family:"Times New Roman","serif";'>(Effective August 24th, 2020)</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:10.0pt;'><span style='font-size:16px;font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:10.4pt;'><span style='font-size:16px;font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-left:.25in;'><strong><span style='font-size:19px;font-family:"Times New Roman","serif";'>1. &nbsp; Acceptance of the Terms of Service</span></strong></p>

    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:119%;'><span style='font-size:16px;line-height:119%;font-family:"Times New Roman","serif";'>These Terms of Service are entered into by and between You and KONA ICE, Inc., (&quot;<strong>KONA</strong>&quot;, &quot;<strong>we</strong>&quot;, &quot;<strong>us</strong>&quot; or &ldquo;<strong>our</strong>&rdquo;). The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, &quot;<strong>Terms of Service</strong>&quot;), govern your access to and use of the KONA website https://www.kona-ice.com/ (&ldquo;<strong>Website</strong>&rdquo;) or any of our other websites, applications, or services that we place this Terms of Service<strong><u>, including but not limited to</u></strong> <strong><u>[</u></strong></span><a href="https://www.kona-ice.com" target="_blank"><span style="font-size:15px;line-height:119%;color:#0563C1;">www.kona-ice.com</span><strong><span style='font-size:16px;line-height:119%;font-family:"Times New Roman","serif";color:windowtext;'>&nbsp;</span></strong></a><strong><span style='font-size:16px;line-height:119%;font-family:"Times New Roman","serif";'>and www.konaicepay.com]&nbsp;</span></strong><span style='font-size:16px;line-height:119%;font-family:"Times New Roman","serif";'>(collectively, the &quot;<strong>Services</strong>&rdquo;). Our Services&nbsp;</span><span style='font-size:16px;line-height:123%;font-family:"Times New Roman","serif";'>include all other location on which we place these Terms of Service. To the extent there is any conflict between these terms of service and the Franchisee terms, the Franchisee terms will control with respect to subject matter of the Franchise agreement.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:9.4pt;'><span style='font-size:16px;font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:118%;'><span style='font-size:16px;line-height:118%;font-family:"Times New Roman","serif";'>The Services are available to users who are 18 years of age or older and reside in the United States or any of its territories. You may use the Services on behalf of individuals under 18 only if You are the parent or legal guardian of such individuals</span><span style='font-family:"Times New Roman","serif";'>.</span><span style='font-size:16px;line-height:118%;font-family:"Times New Roman","serif";'>&nbsp;By using the Services, you represent and warrant that you are of legal age to form a binding contract with KONA and meet all of the preceding eligibility requirements. If you do not meet all of these requirements, you must not access or use the Services. In addition, you understand that certain privacy practices apply to children under the age of 13, and you agree that you have read and understand such privacy practices as they apply to your use of our Services.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:9.8pt;'><span style='font-size:16px;font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:118%;'><strong><span style='font-size:15px;line-height:118%;font-family:"Times New Roman","serif";'>THESE TERMS OF SERVICE CONTAIN AN ARBITRATION PROVISION IN SECTION 21. BY USING THE SERVICES, YOU ACCEPT AND AGREE TO BE BOUND AND ABIDE BY THESE TERMS OF SERVICE AND OUR PRIVACY POLICY, FOUND ON OUR WEBSITE INCORPORATED HEREIN BY REFERENCE</span></strong><span style='font-size:15px;line-height:118%;font-family:"Times New Roman","serif";'>. If you do not want to<strong>&nbsp;</strong>agree to these Terms of Service or the Privacy Policy, you must not access or use the Services.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:118%;'><span style='font-size:15px;line-height:118%;font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-indent:.5in;'><strong><span style='font-size:19px;font-family:"Times New Roman","serif";'>2. &nbsp;Changes to the Terms of Service</span></strong></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:111%;'><span style='font-size:16px;line-height:111%;font-family:"Times New Roman","serif";'>We may revise and update these Terms of Service from time to time at our sole discretion. All changes are effective immediately when we post them and apply to all access to and use of the Services. However, any changes to the dispute resolution provisions set out in <strong><u>Governing Law</u></strong> <strong><u>and Jurisdiction</u>&nbsp;</strong>will not apply to any disputes for which the parties have actual notice on or<strong>&nbsp;</strong>before the date the change is posted on the Services.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:3.95pt;'><span style='font-size:16px;font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:115%;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman","serif";'>Your continued use of the Services following the posting of revised Terms of Service means that you accept and agree to the changes. You are expected to check this page frequently each time you access these Services, so you are aware of any changes, as they are binding on you.</span></p>
    <p><span style='font-size:16px;line-height:115%;font-family:"Times New Roman","serif";'><br>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:115%;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman","serif";'>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;</span><strong><span style='font-size:19px;line-height:115%;font-family:"Times New Roman","serif";'>3. &nbsp;Your Privacy</span></strong></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-right:1.0pt;text-align:justify;line-height:123%;'><span style='font-size:16px;line-height:123%;font-family:"Times New Roman","serif";'>All information that you provide to us or that we collect through your use of the Services is subject to our Privacy Policy found on our Website. By using the Services, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-right:1.0pt;text-align:justify;line-height:123%;'><span style='font-size:16px;line-height:123%;font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-indent:.5in;'><strong><span style='font-size:19px;font-family:"Times New Roman","serif";'>4. &nbsp;The Services</span></strong></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-right:1.0pt;text-align:justify;line-height:111%;'><span style='font-size:16px;line-height:111%;font-family:"Times New Roman","serif";'>Our Services enable you to make online purchases of our products for a contactless purchase experience with our KONA franchisee partners (&ldquo;<strong>KONA Partners</strong>&rdquo;) and to obtain access (or purchase a product) at our events for a specific location and time (&ldquo;<strong>KONA Event</strong>&rdquo;). You can obtain these services for yourself, your child, or for someone else through our Pay It Forward service (&ldquo;<strong>Recipient</strong>&rdquo;). The Services will be provided based on your purchase for a designated event or place.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:3.9pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-right:1.0pt;text-align:justify;line-height:139%;'><span style='font-size:15px;line-height:139%;font-family:"Times New Roman","serif";'>BY USING THE SERVICES, YOU ARE REPRESENTING AND WARRANTING THAT YOU HAVE AUTHORIZATION TO ORDER OUR PRODUCT FOR YOURSELF OR A RECIPIENT</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:7.4pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-right:1.0pt;text-align:justify;line-height:129%;'><span style='font-size:16px;line-height:129%;font-family:"Times New Roman","serif";'>IN USING THE SERVICES, YOU AGREE THAT WHETHER YOU ARE THE RECIPIENT OR NOT, YOU ARE THE PERSON FINANCIALLY RESPONSIBLE FOR THE SERVICES.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-right:1.0pt;text-align:justify;line-height:129%;'><span style='font-size:16px;line-height:129%;font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-left:.25in;text-indent:.25in;'><strong><span style='font-size:19px;font-family:"Times New Roman","serif";'>5. &nbsp;Accessing the Website and Account Security</span></strong></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-right:1.0pt;text-align:justify;line-height:112%;'><span style='font-size:16px;line-height:112%;font-family:"Times New Roman","serif";'>We reserve the right to withdraw or amend the Services, and any service or material we provide in connection with the Services, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Services or Service Content is unavailable at any time or for any period. Our Services are dependent on Event hours and available KONA Partners.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:3.7pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='font-size:16px;font-family:"Times New Roman","serif";'>You are responsible for both:</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:7.2pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <div style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'>
        <ul style="margin-bottom:0in;list-style-type: undefined;margin-left:25px;">
            <li style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='line-height:115%;font-family:"Times New Roman","serif";'>Making all arrangements necessary for you to access the Services, including being present for your event and providing a safe, sanitary, and inviting space for our KONA Partner, including adhering to <u><span style="color:#0563C1;">CDC guidance</span></u>.</span></li>
        </ul>
    </div>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:3.35pt;'><span style='font-size:15px;font-family:"Arial","sans-serif";'>&nbsp;</span></p>
    <div style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'>
        <ul style="margin-bottom:0in;list-style-type: undefined;margin-left:25px;">
            <li style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='line-height:121%;font-family:"Times New Roman","serif";'>Ensuring that all persons who access the Services are aware of these Terms of Service and our Privacy Policy and comply with them.</span></li>
        </ul>
    </div>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:2.4pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-right:1.0pt;text-align:justify;line-height:110%;'><span style='font-size:16px;line-height:110%;font-family:"Times New Roman","serif";'>To access the Services or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Services that all the information you provide on the Services are correct, current, complete, and that you are authorized to disclose such information. You agree that all information you provide to register with the Services or otherwise, including, but not limited to, through the use of any interactive features on the Services, is governed by our <em>Privacy Policy</em> [</span><a href="javascript:void(0)" (click)="showPrivacy()"><span style="font-size:15px;line-height:110%;color:#0563C1;" translate>label_privacy_policy</span></a><a href="javascript:void(0)" (click)="showPrivacy()" ><span style="font-size:15px;line-height:110%;color:#0563C1;"></span></a><span style='font-size:16px;line-height:110%;font-family:"Times New Roman","serif";color:black;'>],</span><span style="font-size:15px;line-height:110%;color:#0563C1;">&nbsp;</span><span style='font-size:16px;line-height:110%;font-family:"Times New Roman","serif";color:black;'>and you consent to all actions we take with respect to your information</span><span style="font-size:15px;line-height:110%;color:#0563C1;">&nbsp;</span><span style='font-size:16px;line-height:110%;font-family:"Times New Roman","serif";color:black;'>consistent with our Privacy Policy. &nbsp; &nbsp;&nbsp;&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-right:1.0pt;text-align:justify;line-height:110%;'><span style='font-size:16px;line-height:110%;font-family:"Times New Roman","serif";color:black;'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-right:1.0pt;text-align:justify;text-indent:.5in;line-height:110%;'><strong><span style='font-size:19px;line-height:110%;font-family:"Times New Roman","serif";'>6. &nbsp;Mobile Device Access</span></strong></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:112%;'><span style='font-size:16px;line-height:112%;font-family:"Times New Roman","serif";'>To the extent you access the Services through a mobile device, your wireless service carrier&rsquo;s standard charges, data rates, and other fees may apply. In addition, downloading, installing, or using certain mobile services or applications may be prohibited or restricted by your carrier, and not all mobile services or applications may work with all carriers or devices.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:112%;'><span style='font-size:16px;line-height:112%;font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-left:.25in;'><strong><span style='font-size:19px;font-family:"Times New Roman","serif";'>&nbsp; &nbsp; &nbsp;&nbsp;7.</span></strong><span style='font-size:19px;font-family:"Times New Roman","serif";'>&nbsp; &nbsp;I</span><strong><span style='font-size:18px;font-family:"Times New Roman","serif";'>ntellectual Property Rights</span></strong></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:111%;'><span style='font-size:16px;line-height:111%;font-family:"Times New Roman","serif";'>The Services and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, photographs, video, and audio, and the design, selection, and arrangement thereof) are owned by KONA ICE, Inc., its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:3.95pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:110%;'><span style='font-size:16px;line-height:110%;font-family:"Times New Roman","serif";'>These Terms of Service permit you to use the Services for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the materials on or through the Services, except as follows: (1) your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials; (2) you may store files that are automatically cached by your Web browser for display enhancement purposes; and (3) except for materials owned by third parties and except for materials that may be subject to additional or different terms, you may print or download one copy of a reasonable number of pages of the Services for your own personal, non-commercial use and not for further reproduction, publication or distribution. If we provide social media features with certain content, you may take such actions as are enabled by such features.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:4.05pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='font-size:16px;font-family:"Times New Roman","serif";'>You must not:</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:7.2pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <div style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'>
        <ul style="margin-bottom:0in;list-style-type: undefined;margin-left:25px;">
            <li style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='font-family:"Times New Roman","serif";font-size:12.0pt;'>Modify copies of any materials from the Website.</span></li>
        </ul>
    </div>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:7.2pt;'><span style='font-size:15px;font-family:"Arial","sans-serif";'>&nbsp;</span></p>
    <div style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'>
        <ul style="margin-bottom:0in;list-style-type: undefined;margin-left:25px;">
            <li style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='line-height:121%;font-family:"Times New Roman","serif";'>Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from the Services.</span></li>
        </ul>
    </div>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:2.4pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:110%;'><span style='font-size:16px;line-height:110%;font-family:"Times New Roman","serif";'>If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Services in breach of the Terms of Service, your right to use the Services will stop immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title, or interest in or to the Services or any content on the Services is transferred to you, and all rights not expressly granted are reserved by KONA. Any use of the Services not expressly permitted by these Terms of Service is a breach of these Terms of Service and may violate copyright, trademark, and other laws.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-left:.25in;'><strong><span style='font-size:19px;font-family:"Times New Roman","serif";'>&nbsp;</span></strong></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-left:.25in;'><strong><span style='font-size:19px;font-family:"Times New Roman","serif";'>&nbsp; &nbsp; &nbsp;&nbsp;8.</span></strong><span style='font-size:19px;font-family:"Times New Roman","serif";'>&nbsp; &nbsp;</span><strong><span style='font-size:18px;font-family:"Times New Roman","serif";'>Trademark</span></strong></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='font-size:16px;font-family:"Times New Roman","serif";'>The KONA ICE name, the terms KONA and KONA ICE, KONA ICE logo, and all related names, logos, product and service names, designs, and slogans are trademarks of KONA ICE or its affiliates or licensors. You must not use such marks without the prior written permission of KONA. All other names, logos, product and service names, designs, and slogans on the Services are the trademarks of their respective owners.</span></p>
    <p><span style='font-size:16px;font-family:"Times New Roman","serif";'><br>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-indent:.5in;'><strong><span style='font-size:19px;font-family:"Times New Roman","serif";'>9. &nbsp;Respectful Use</span></strong></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:114%;'><span style='font-size:16px;line-height:114%;font-family:"Times New Roman","serif";'>You agree to treat KONA Partners courteously and professionally, and you understand that your right to use the Services will be terminated if the KONA Partners are not treated appropriately. In turn, you also should expect to be treated courteously and professionally by the KONA Partners. If you feel you have not been treated appropriately, please contact us to let us know.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:114%;'><span style='font-size:16px;line-height:114%;font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-left:.25in;text-indent:.25in;'><strong><span style='font-size:19px;font-family:"Times New Roman","serif";'>10. &nbsp;Prohibited Uses</span></strong></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='font-size:16px;font-family:"Times New Roman","serif";'>You may use the Services only for lawful purposes and in accordance with these Terms of</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:1.8pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='font-size:16px;font-family:"Times New Roman","serif";'>Services. You agree not to use the Services:</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:6.6pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <div style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'>
        <ul style="margin-bottom:0in;list-style-type: undefined;margin-left:25px;">
            <li style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='line-height:115%;font-family:"Times New Roman","serif";'>In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries).</span></li>
        </ul>
    </div>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:3.35pt;'><span style='font-size:15px;font-family:"Arial","sans-serif";'>&nbsp;</span></p>
    <div style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'>
        <ul style="margin-bottom:0in;list-style-type: undefined;margin-left:25px;">
            <li style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='line-height:115%;font-family:"Times New Roman","serif";'>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.</span></li>
        </ul>
    </div>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:3.35pt;'><span style='font-size:15px;font-family:"Arial","sans-serif";'>&nbsp;</span></p>
    <div style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'>
        <ul style="margin-bottom:0in;list-style-type: undefined;margin-left:25px;">
            <li style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='line-height:115%;font-family:"Times New Roman","serif";'>To transmit, or procure the sending of, any advertising or promotional material without our prior written consent, including any &quot;junk mail,&quot; &quot;chain letter,&quot; &quot;spam,&quot; or any other similar solicitation.</span></li>
        </ul>
    </div>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:3.35pt;'><span style='font-size:15px;font-family:"Arial","sans-serif";'>&nbsp;</span></p>
    <div style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'>
        <ul style="margin-bottom:0in;list-style-type: undefined;margin-left:25px;">
            <li style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='line-height:112%;font-family:"Times New Roman","serif";'>To impersonate or attempt to impersonate KONA, a KONA employee, a KONA Partner, or another user or recipient of our beauty services, or any other person or entity (including, without limitation, by using the email addresses or screen names associated with any of the foregoing).</span></li>
        </ul>
    </div>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:3.65pt;'><span style='font-size:15px;font-family:"Arial","sans-serif";'>&nbsp;</span></p>
    <div style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'>
        <ul style="margin-bottom:0in;list-style-type: undefined;margin-left:25px;">
            <li style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='line-height:115%;font-family:"Times New Roman","serif";'>To engage in any other conduct that restricts or inhibits anyone&#39;s use or enjoyment of the Services, or which, as determined by us, may harm KONA or users of the Services, or expose them to liability.</span></li>
        </ul>
    </div>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:3.4pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='font-size:16px;font-family:"Times New Roman","serif";'>Additionally, you agree not to:</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:7.2pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <div style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'>
        <ul style="margin-bottom:0in;list-style-type: undefined;margin-left:25px;">
            <li style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='line-height:115%;font-family:"Times New Roman","serif";'>Use the Services in any manner that could disable, overburden, damage, or impair the Services or interfere with any other party&#39;s use of the Service, including their ability to engage in real time activities through the Service.</span></li>
        </ul>
    </div>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:3.35pt;'><span style='font-size:15px;font-family:"Arial","sans-serif";'>&nbsp;</span></p>
    <div style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'>
        <ul style="margin-bottom:0in;list-style-type: undefined;margin-left:-0.25in;">
            <li style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='line-height:121%;font-family:"Times New Roman","serif";'>Abuse the Services by requesting an unreasonable amount of request that are not reasonably related to purchasing a KONA shaven ice product for consumption or donation, i.e., requesting numerous kona ice beverages in a single day or time period that are interpreted to be an attempt to overburden the Services.</span></li>
        </ul>
    </div>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:2.4pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <div style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'>
        <ul style="margin-bottom:0in;list-style-type: undefined;margin-left:25px;">
            <li style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='line-height:115%;font-family:"Times New Roman","serif";'>Abuse the KONA Partner, whether through words or actions, including but not limited to, any type of verbal or physical intimidation. Such abuse and intimidation shall include the use of social media.</span></li>
        </ul>
    </div>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:3.35pt;'><span style='font-size:15px;font-family:"Arial","sans-serif";'>&nbsp;</span></p>
    <div style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'>
        <ul style="margin-bottom:0in;list-style-type: undefined;margin-left:25px;">
            <li style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='line-height:121%;font-family:"Times New Roman","serif";'>Use any robot, spider, or other automatic device, process, or means to access the Services for any purpose, including monitoring or copying any of the material on the Services.</span></li>
        </ul>
    </div>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:2.4pt;'><span style='font-size:15px;font-family:"Arial","sans-serif";'>&nbsp;</span></p>
    <div style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'>
        <ul style="margin-bottom:0in;list-style-type: undefined;margin-left:25px;">
            <li style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='line-height:115%;font-family:"Times New Roman","serif";'>Use any manual process to monitor or copy any of the material on the Services, or for any other purpose not expressly authorized in these Terms of Service, without our prior written consent.</span></li>
        </ul>
    </div>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:3.35pt;'><span style='font-size:15px;font-family:"Arial","sans-serif";'>&nbsp;</span></p>
    <div style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'>
        <ul style="margin-bottom:0in;list-style-type: undefined;margin-left:25px;">
            <li style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='line-height:121%;font-family:"Times New Roman","serif";'>Use any device, software, or routine that interferes with the proper working of the Services.</span></li>
        </ul>
    </div>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:2.4pt;'><span style='font-size:15px;font-family:"Arial","sans-serif";'>&nbsp;</span></p>
    <div style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'>
        <ul style="margin-bottom:0in;list-style-type: undefined;margin-left:25px;">
            <li style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='line-height:121%;font-family:"Times New Roman","serif";'>Introduce any viruses, Trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.</span></li>
        </ul>
    </div>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:2.4pt;'><span style='font-size:15px;font-family:"Arial","sans-serif";'>&nbsp;</span></p>
    <div style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'>
        <ul style="margin-bottom:0in;list-style-type: undefined;margin-left:25px;">
            <li style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='line-height:115%;font-family:"Times New Roman","serif";'>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Services, the server on which the Services is stored, or any server, computer, or database connected to the Services.</span></li>
        </ul>
    </div>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:3.35pt;'><span style='font-size:15px;font-family:"Arial","sans-serif";'>&nbsp;</span></p>
    <div style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'>
        <ul style="margin-bottom:0in;list-style-type: undefined;margin-left:25px;">
            <li style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='font-family:"Times New Roman","serif";font-size:12.0pt;'>Attack the Services via a denial-of-service attack or a distributed denial-of-service attack.</span></li>
        </ul>
    </div>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:7.2pt;'><span style='font-size:15px;font-family:"Arial","sans-serif";'>&nbsp;</span></p>
    <div style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'>
        <ul style="margin-bottom:0in;list-style-type: undefined;margin-left:25px;">
            <li style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='font-family:"Times New Roman","serif";font-size:12.0pt;'>Otherwise attempt to interfere with the proper working of the Services.</span></li>
        </ul>
    </div>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:7.2pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='font-size:16px;font-family:"Times New Roman","serif";'>Your engaging in a prohibited use may result in the termination of your right to use the Services.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='font-size:19px;font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-indent:.5in;'><strong><span style='font-size:19px;font-family:"Times New Roman","serif";'>11. Monitoring and Enforcement; Termination</span></strong></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='font-size:16px;font-family:"Times New Roman","serif";'>We have the right to:</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:7.2pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <div style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'>
        <ul style="margin-bottom:0in;list-style-type: undefined;margin-left:25px;">
            <li style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='line-height:121%;font-family:"Times New Roman","serif";'>Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Services.</span></li>
        </ul>
    </div>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:2.4pt;'><span style='font-size:15px;font-family:"Arial","sans-serif";'>&nbsp;</span></p>
    <div style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'>
        <ul style="margin-bottom:0in;list-style-type: undefined;margin-left:25px;">
            <li style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='line-height:121%;font-family:"Times New Roman","serif";'>Terminate or suspend your access to all or part of the Services for any or no reason, including without limitation, any violation of these Terms of Service.</span></li>
        </ul>
    </div>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:2.4pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:110%;'><span style='font-size:16px;line-height:110%;font-family:"Times New Roman","serif";'>Without limiting the foregoing, we have the right to cooperate fully with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Services. YOU WAIVE AND HOLD HARMLESS KONA ICE, INC. AND ITS AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY KONA ICE, INC. AND ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER KONA ICE, INC., OR LAW ENFORCEMENT AUTHORITIES.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:111%;'><span style='font-size:16px;line-height:111%;font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:111%;'><span style='font-size:16px;line-height:111%;font-family:"Times New Roman","serif";'>However, we do not undertake to review all material before it is posted on the Services and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications, or content provided by any user or third party. We have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:111%;'><span style='font-size:16px;line-height:111%;font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;text-indent:.5in;line-height:111%;'><strong><span style='font-size:19px;line-height:111%;font-family:"Times New Roman","serif";'>12. &nbsp;Reliance on Information Posted</span></strong></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:117%;'><span style='font-size:15px;line-height:117%;font-family:"Times New Roman","serif";'>The information presented on or through the Services is made available solely for general information purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor&rsquo;s use of the Services, or by anyone who may be informed of any of its contents.</span></p>
    <p><span style='font-size:15px;line-height:117%;font-family:"Times New Roman","serif";'><br>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:117%;'><span style='font-size:15px;line-height:117%;font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;text-indent:.5in;line-height:117%;'><strong><span style='font-size:19px;line-height:117%;font-family:"Times New Roman","serif";'>13.&nbsp;</span></strong><strong><span style='font-size:19px;line-height:117%;font-family:"Times New Roman","serif";'>Changes to the Website</span></strong></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:115%;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman","serif";'>We may update the content on the Services from time to time, but its content is not necessarily complete or up-to-date. Any of the material corresponding with these Services may be out of date at any given time, and we are under no obligation to update such material.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:115%;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-indent:.5in;'><strong><span style='font-size:19px;font-family:"Times New Roman","serif";'>14. &nbsp;Information About You and Your Visits to the Website</span></strong></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:121%;'><span style='font-size:16px;line-height:121%;font-family:"Times New Roman","serif";'>All information we collect on the Services is subject to our Privacy Policy&nbsp;</span><a href="javascript:void(0)" (click)="showPrivacy()"><span style="font-size:15px;line-height:121%;color:#0563C1;" translate>label_privacy_policy</span></a><a href="javascript:void(0)" (click)="showPrivacy()" ><span style="font-size:15px;line-height:121%;color:#0563C1;"></span></a><span style='font-size:16px;line-height:121%;font-family:"Times New Roman","serif";color:black;'>.</span><span style="font-size:15px;line-height:121%;color:#0563C1;">&nbsp;</span><span style='font-size:16px;line-height:121%;font-family:"Times New Roman","serif";color:black;'>By using the Services, you consent to all actions taken by us with</span><span style="font-size:15px;line-height:121%;color:#0563C1;">&nbsp;</span><span style='font-size:16px;line-height:121%;font-family:"Times New Roman","serif";color:black;'>respect to your information in compliance with the Privacy Policy.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='font-size:19px;font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-indent:.5in;'><strong><span style='font-size:19px;font-family:"Times New Roman","serif";'>15. &nbsp;Links from the Website</span></strong></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:110%;'><span style='font-size:16px;line-height:110%;font-family:"Times New Roman","serif";'>If the Services contain links to other websites and resources provided by third parties, these links are provided for your convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those websites or resources and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third-party websites linked to these Services, you do so entirely at your own risk and subject to the terms and conditions of use for such websites.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='font-size:16px;font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-indent:.5in;'><strong><span style='font-size:19px;font-family:"Times New Roman","serif";'>16. &nbsp;Geographic Restrictions</span></strong></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:111%;'><span style='font-size:16px;line-height:111%;font-family:"Times New Roman","serif";'>The owner of these Services is based in the State of Kentucky in the United States. We provide these Services for use only by persons located in the United States. We make no claims that the Services or any of its content is accessible or appropriate outside of the United States. Access to the Services may not be legal by certain persons or in certain countries. If you access the Services from outside the United States, you do so on your own initiative and are responsible for compliance with local laws.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:111%;'><span style='font-size:19px;line-height:111%;font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;text-indent:.5in;line-height:111%;'><strong><span style='font-size:19px;line-height:111%;font-family:"Times New Roman","serif";'>17. &nbsp;Disclaimer of Warranties</span></strong></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='font-size:16px;font-family:"Times New Roman","serif";'>You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Services will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our Services for any reconstruction of any lost data.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:3.65pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-right:1.0pt;text-align:justify;line-height:113%;'><strong><span style='font-size:16px;line-height:113%;font-family:"Times New Roman","serif";'>WE ARE NOT LIABLE FOR ANY INJURY A KONA PARTNER MIGHT CAUSE WHILE ONSITE OR AT YOUR EVENT. YOU AGREE THAT EVENTS MAY CARRY INHERENT RISK, AND BY REQUESTING THE CONTACTLESS PAYMENT SERVICES, YOU CHOOSE TO ASSUME THOSE RISKS VOLUNTARILY</span></strong><strong><span style='font-family:"Times New Roman","serif";'>.</span></strong></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:3.5pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-right:1.0pt;text-align:justify;line-height:116%;'><span style='font-size:15px;line-height:116%;font-family:"Times New Roman","serif";'>TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:3.1pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:110%;'><strong><span style='font-size:16px;line-height:110%;font-family:"Times New Roman","serif";color:#252525;'>THE KONA PRODUCTS ARE PURCHASED &quot;AS IS&quot; WITH ALL FAULTS. KONA MAKES NO WARRANTY WHATSOEVER, EXPRESS OR IMPLIED, WITH RESPECT TO THE SHAVED ICE PRODUCTS, INCLUDING ANY WARRANTY OF WHOLESOMENESS, CONDITION, QUALITY, OR SUITABILITY, WARRANTY OF MERCHANTABILITY, WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE, WHETHER EXPRESS OR IMPLIED BY LAW, COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE OR OTHERWISE.</span></strong></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:4.25pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-right:1.0pt;text-align:justify;line-height:121%;'><span style='font-size:16px;line-height:121%;font-family:"Times New Roman","serif";'>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-right:1.0pt;text-align:justify;line-height:121%;'><span style='font-size:19px;line-height:121%;font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-right:1.0pt;text-align:justify;text-indent:.5in;line-height:121%;'><strong><span style='font-size:19px;line-height:121%;font-family:"Times New Roman","serif";'>18. &nbsp;Limitation on Liability</span></strong></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:102%;'><span style='font-size:15px;line-height:102%;font-family:"Times New Roman","serif";color:#333333;'>YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES AND PURCHASE OF KONA PRODUCTS REMAINS WITH YOU. NEITHER KONA ICE, INC., NOR ANY OTHER PERSON OR ENTITY INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE KONA ICE, INC. SERVICES WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA OR LOSS OF GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE PRODUCTS OR SERVICES, OR FOR ANY DAMAGES FOR PERSONAL OR BODILY INJURY OR EMOTIONAL DISTRESS ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES OR KONA WEBSITE, OR THE INABILITY TO USE OR ACCESS A PROFILE OR ANY SPECIFIC PROFILE CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT KONA ICE, INC. HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-right:1.0pt;'><span style="font-size:15px;">&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-right:1.0pt;text-align:justify;line-height:115%;'><span style='font-size:15px;line-height:115%;font-family:"Times New Roman","serif";color:#333333;'>IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:4.65pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:103%;'><span style='font-size:15px;line-height:103%;font-family:"Times New Roman","serif";color:#333333;'>IN NO EVENT WILL KONA&rsquo;S AGGREGATE LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES OR KONA PRODUCT OR WEBSITE EXCEED THE AMOUNTS YOU HAVE PAID FOR THE KONA PRODUCT, IF YOU HAVE MADE ANY PAYMENTS TO KONA ICE, INC., FOR USE OF THE SAME OR ONE HUNDRED DOLLARS ($100), WHICHEVER IS GREATER. THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN KONA ICE, INC. AND YOU. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:7.4pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-right:1.0pt;text-align:justify;line-height:115%;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman","serif";'>The limitation of liability set out above does not apply to liability resulting from our gross negligence or willful misconduct or death or bodily injury caused by products you purchase through the Services.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:3.4pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-right:1.0pt;text-align:justify;line-height:121%;'><span style='font-size:16px;line-height:121%;font-family:"Times New Roman","serif";'>THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</span></p>
    <p><span style='font-size:19px;font-family:"Times New Roman","serif";'><br>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-indent:.5in;'><strong><span style='font-size:19px;font-family:"Times New Roman","serif";'>19. &nbsp; Indemnification</span></strong></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-right:1.0pt;text-align:justify;line-height:110%;'><span style='font-size:16px;line-height:110%;font-family:"Times New Roman","serif";'>You agree to defend, indemnify, and hold harmless KONA ICE, INC., its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys&#39; fees) arising out of or relating to your violation of these Terms of Service or your use of the Services, including, but not limited to, your User Contributions, any use of the Services&rsquo; content, services, and products other than as expressly authorized in these Terms of Service, or your use of any information obtained from the Services.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='font-size:19px;font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-indent:.5in;'><strong><span style='font-size:19px;font-family:"Times New Roman","serif";'>20. &nbsp;Governing Law and Jurisdiction</span></strong></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-right:1.0pt;text-align:justify;line-height:111%;'><span style='font-size:16px;line-height:111%;font-family:"Times New Roman","serif";'>All matters relating to the Services and these Terms of Service, and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of Kentucky without giving effect to any choice or conflict of law provision or rule (whether of the State of Kentucky or any other jurisdiction).</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:3.95pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-right:1.0pt;text-align:justify;line-height:111%;'><span style='font-size:16px;line-height:111%;font-family:"Times New Roman","serif";'>Any legal suit, action, or proceeding arising out of, or related to, these Terms of Service or the Services shall be instituted exclusively in the federal courts of the United States or the courts of the State of Kentucky in each case located in the Boone County, although we retain the right to bring any suit, action, or proceeding against you for breach of these Terms of Service in your country of residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='font-size:19px;font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-indent:.5in;'><strong><span style='font-size:19px;font-family:"Times New Roman","serif";'>21. &nbsp;Arbitration</span></strong></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='font-size:16px;font-family:"Times New Roman","serif";'>At KONA&#39;s sole discretion, it may require You to submit any disputes arising from these Terms of Service or use of the Service, including disputes arising from or concerning their interpretation, violation, invalidity, non-performance, or termination, to final and binding arbitration under the Rules of Arbitration of the American Arbitration Association applying Michigan law.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='font-size:16px;font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:2.15pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-indent:.5in;'><strong><span style='font-size:19px;font-family:"Times New Roman","serif";'>22. &nbsp;Limitation on Time to File Claims</span></strong></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:112%;'><span style='font-size:16px;line-height:112%;font-family:"Times New Roman","serif";'>ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF SERVICE OR THE SERVICES MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:112%;'><span style='font-size:16px;line-height:112%;font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-indent:.5in;'><strong><span style='font-size:19px;font-family:"Times New Roman","serif";'>23. &nbsp;Waiver and Severability</span></strong></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:112%;'><span style='font-size:16px;line-height:112%;font-family:"Times New Roman","serif";'>No waiver by KONA of any term or condition set out in these Terms of Service shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of KONA to assert a right or provision under these Terms of Service shall not constitute a waiver of such right or provision.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:3.7pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:112%;'><span style='font-size:16px;line-height:112%;font-family:"Times New Roman","serif";'>If any provision of these Terms of Service is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Service will continue in full force and effect.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:13.85pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-left:.25in;text-indent:.25in;'><strong><span style='font-size:19px;font-family:"Times New Roman","serif";'>24. &nbsp;Entire Agreement</span></strong></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:112%;'><span style='font-size:16px;line-height:112%;font-family:"Times New Roman","serif";'>The Terms of Service, and our Privacy Policy constitute the sole and entire agreement between you and KONA regarding the Services and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the Services.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:10.0pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:12.85pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";margin-left:.25in;text-indent:.25in;'><strong><span style='font-size:19px;font-family:"Times New Roman","serif";'>25. &nbsp;Your Comments and Concerns</span></strong></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";'><span style='font-size:16px;font-family:"Times New Roman","serif";'>These Services are operated by KONA ICE, INC. 5945 Centennial Circle, Florence, KY 41042.</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";line-height:7.2pt;'><span style='font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin:0in;margin-bottom:.0001pt;font-size:13px;font-family:"Calibri","sans-serif";text-align:justify;line-height:121%;'><span style='font-size:16px;line-height:121%;font-family:"Times New Roman","serif";'>All other feedback, comments, requests for technical support, and other communications relating to the Services should be directed to: Korporate@kona-ice.com.</span></p>
</div>

