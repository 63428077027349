<div class="page-container mar-top-30">
  <div class="page-header">
    <div class="page-heading">
      <h1 class="heading" translate>label_dashboard</h1>
    </div>
  </div>
  <div class="form-control input-container">
    <p-tabView (onChange)="handleChange($event)" [scrollable]="true">
      <p-tabPanel [header]="lable_top_clients">
        <div
          class="form-container col-12 lg:col-12 pad-l-0"
          *ngIf="tabSelected == 'topClients'"
        >
          <div>
            <app-dashboard-filters
              (filterObjectChangeEmmiter)="filterChangedHandler($event)"
              (noCompareEmmiter)="noCompareHandler($event)"
              [showCompare]="showCompare"
              [showGranularity]="showGranularity"
            ></app-dashboard-filters>
          </div>
        </div>
        <div class="form-container col-12 lg:col-12 pad-l-0">
          <!-- graph div -->
          <div class="col-12 grid mar-pad-0 flex-center cart-flex col-nogutter">
            <div
              class="col-12 sm:col-12 md:col-8 lg:col-12 xl:col-12 mar-pad-0 box-content"
            >
              <div class="card" *ngIf="topClientsDataPrepared">
                <app-chart-top-clients
                  [inputDataObject]="eventSalesGivebackObjectToPass"
                >
                </app-chart-top-clients>
              </div>
            </div>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel [header]="lable_event_sales_average">
        <div
          class="form-container col-12 lg:col-12 pad-l-0"
          *ngIf="tabSelected == 'eventSalesAverage'"
        >
          <div>
            <app-dashboard-filters
              (filterObjectChangeEmmiter)="filterChangedHandler($event)"
              (noCompareEmmiter)="noCompareHandler($event)"
              [showGranularity]="false"
            ></app-dashboard-filters>
          </div>
        </div>

        <div class="chart-container" *ngIf="averageDataPrepared">
          <div class="sales-avg-container">
            <div class="current-container">
              <div class="title">Sales Average</div>
              <div class="current-value">
                {{ eveSaleAvg ? "$" + eveSaleAvg : "$0" }}
              </div>
              <div class="timeline">
                {{ selectedFromDate | date: "dd-MMM-yy" }} -
                {{ selectedtoDate | date: "dd-MMM-yy" }}
              </div>
              <div class="total-value">
                <div class="label">Total Sales</div>
                <div class="value">
                  {{ totalSales ? "$" + totalSales : "$0" }}
                </div>
              </div>
              <div class="total-value">
                <div class="label">Total Events</div>
                <div class="value">{{ totalEvent ? totalEvent : "0" }}</div>
              </div>
            </div>

            <div class="current-container gray-container" *ngIf="isCompare">
              <div class="title">Sales Average (Compared)</div>
              <div class="current-value">
                {{ eveCompSale ? "$" + eveCompSale : "$0" }}
              </div>
              <div class="timeline">
                {{ comparedFromDate | date: "dd-MMM-yy" }} -
                {{ comparedToDate | date: "dd-MMM-yy" }}
              </div>
              <div class="total-value">
                <div class="label">Total Sales</div>
                <div class="value">
                  {{ compTotalSales ? "$" + compTotalSales : "$0" }}
                </div>
              </div>
              <div class="total-value">
                <div class="label">Total Events</div>
                <div class="value">
                  {{ compTotalEvent ? compTotalEvent : "0" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="graph-no-data" *ngIf="!averageDataPrepared" translate>
          msg_no_sales_data
        </div>
      </p-tabPanel>
      <p-tabPanel [header]="lable_event_sales_by_location">
        <div
          class="form-container col-12 lg:col-12 pad-l-0"
          *ngIf="tabSelected == 'salesByLocation'"
        >
          <div>
            <app-dashboard-filters
              (filterObjectChangeEmmiter)="filterChangedHandler($event)"
              (noCompareEmmiter)="noCompareHandler($event)"
              [showCompare]="showCompare"
              [showGranularity]="false"
            ></app-dashboard-filters>
          </div>
        </div>
        <div class="form-container col-12 lg:col-12 pad-l-0">
          <!-- graph div -->
          <div class="col-12 grid mar-pad-0 flex-center cart-flex col-nogutter">
            <div
              class="col-12 sm:col-12 md:col-8 lg:col-12 xl:col-12 mar-pad-0 box-content"
            >
              <div class="card" *ngIf="salesByLocationDataPrepared">
                <app-chart-sales-by-location
                  [inputDataObject]="eventSalesGivebackObjectToPass"
                >
                </app-chart-sales-by-location>
              </div>
            </div>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel [header]="lable_event_sales_by_industry">
        <div
          class="form-container col-12 lg:col-12 pad-l-0"
          *ngIf="tabSelected == 'salesByIndustry'"
        >
          <div>
            <app-dashboard-filters
              (filterObjectChangeEmmiter)="filterChangedHandler($event)"
              (noCompareEmmiter)="noCompareHandler($event)"
              [showCompare]="showCompare"
              [showGranularity]="showGranularity"
            ></app-dashboard-filters>
          </div>
        </div>
        <div class="form-container col-12 lg:col-12 pad-l-0">
          <!-- graph div -->
          <div class="col-12 grid mar-pad-0 flex-center cart-flex col-nogutter">
            <div
              class="col-12 sm:col-12 md:col-8 lg:col-12 xl:col-12 mar-pad-0 box-content"
            >
              <div class="card" *ngIf="salesByIndustrydataPrepared">
                <app-chart-sales-by-industry
                  [inputDataObject]="eventSalesGivebackObjectToPass"
                >
                </app-chart-sales-by-industry>
              </div>
            </div>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel [header]="lable_equipment_summary">
        <div
          class="form-container col-12 lg:col-12 pad-l-0"
          *ngIf="tabSelected == 'equipmentSummary'"
        >
          <div>
            <app-dashboard-filters
              (filterObjectChangeEmmiter)="filterChangedHandler($event)"
              (noCompareEmmiter)="noCompareHandler($event)"
              [showGranularity]="showGranularity"
              [showCompare]="showCompare"
            ></app-dashboard-filters>
          </div>
        </div>
        <div class="form-container col-12 lg:col-12 pad-l-0">
          <!-- graph div -->
          <div class="col-12 grid mar-pad-0 flex-center cart-flex col-nogutter">
            <div
              class="col-12 sm:col-12 md:col-8 lg:col-12 xl:col-12 mar-pad-0 box-content"
            >
              <div class="card" *ngIf="equipmentSummaryDataPrepared">
                <app-chat-equipment-summary
                  [inputDataObject]="eventSalesGivebackObjectToPass"
                >
                </app-chat-equipment-summary>
              </div>
            </div>
          </div>
        </div>
      </p-tabPanel>

      <p-tabPanel [header]="lable_sales_giveback">
        <div
          class="form-container col-12 lg:col-12 pad-l-0"
          *ngIf="tabSelected == 'salesGiveback'"
        >
          <div>
            <app-dashboard-filters
              (filterObjectChangeEmmiter)="filterChangedHandler($event)"
              (noCompareEmmiter)="noCompareHandler($event)"
            ></app-dashboard-filters>
          </div>
        </div>
        <div class="form-container col-12 lg:col-12 pad-l-0">
          <div class="page-filters">
            <div class="filter-left-container grid" style="margin-left: 0">
              <div
                class="filter-item col-12 lg:col-2 md:col-3 sm:col-12 mob-ptb-0 mr-l-5"
              >
                <div class="form-control input-container">
                  <p-dropdown
                    [options]="salesGivebackModeOptions"
                    (onChange)="onClickFilter('salesGiveback')"
                    [(ngModel)]="selectedSalesGivebackMode"
                  >
                  </p-dropdown>
                </div>
              </div>
            </div>
          </div>
          <!-- graph div -->
          <div class="col-12 grid mar-pad-0 flex-center cart-flex col-nogutter">
            <div
              class="col-12 sm:col-12 md:col-8 lg:col-12 xl:col-12 mar-pad-0 box-content"
            >
              <div class="card" *ngIf="salesGivbackDataPrepared">
                <app-bar-chart-sales-givback
                  [eventSalesGivebackObjectToPass]="
                    eventSalesGivebackObjectToPass
                  "
                >
                </app-bar-chart-sales-givback>
              </div>
            </div>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel [header]="lable_staff_overview">
        <div
          class="form-container col-12 lg:col-12 pad-l-0"
          *ngIf="tabSelected == 'staffOverview'"
        >
          <div>
            <app-dashboard-filters
              (filterObjectChangeEmmiter)="filterChangedHandler($event)"
              (noCompareEmmiter)="noCompareHandler($event)"
            ></app-dashboard-filters>
          </div>
        </div>
        <div class="form-container col-12 lg:col-12 pad-l-0">
          <!-- graph div -->
          <div class="col-12 grid mar-pad-0 flex-center cart-flex col-nogutter">
            <div
              class="col-12 sm:col-12 md:col-8 lg:col-12 xl:col-12 mar-pad-0 box-content"
            >
              <div class="card" *ngIf="staffOverviewDataPrepared">
                <app-chart-staff-overview
                  [inputDataObject]="eventSalesGivebackObjectToPass"
                >
                </app-chart-staff-overview>
                <!-- <p-chart type="bar" [data]="dataSource" [options] = "options"
                 responsive = "false" height="80vh"></p-chart> -->
              </div>
            </div>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel [header]="lable_prepay_events">
        <div
          class="form-container col-12 lg:col-12 pad-l-0"
          *ngIf="tabSelected == 'prepayEventsGraph'"
        >
          <div>
            <app-dashboard-filters
              (filterObjectChangeEmmiter)="filterChangedHandler($event)"
              (noCompareEmmiter)="noCompareHandler($event)"
              [showGranularity]="showGranularity"
              [showCompare]="showCompare"
            ></app-dashboard-filters>
          </div>
        </div>
        <div class="form-container col-12 lg:col-12 pad-l-0">
          <!-- graph div -->
          <div class="col-12 grid mar-pad-0 flex-center cart-flex col-nogutter">
            <div
              class="col-12 sm:col-12 md:col-8 lg:col-12 xl:col-12 mar-pad-0 box-content"
            >
              <div class="card" *ngIf="prepayEventDataPrepared">
                <app-bar-chart-prepay-events
                  [inputDataObject]="prepayEventsObjectToPass"
                >
                </app-bar-chart-prepay-events>
                <!-- <p-chart type="bar" [data]="dataSource" [options] = "options"
                 responsive = "false" height="80vh"></p-chart> -->
                <!-- <app-bar-chart></app-bar-chart> -->
              </div>
            </div>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel [header]="lable_sales_event_day_of_week">
        <div
          class="form-container col-12 lg:col-12 pad-l-0"
          *ngIf="tabSelected == 'salesEventDay'"
        >
          <div>
            <app-dashboard-filters
              (filterObjectChangeEmmiter)="filterChangedHandler($event)"
              (noCompareEmmiter)="noCompareHandler($event)"
              [showGranularity]="showGranularity"
            ></app-dashboard-filters>
          </div>
        </div>
        <div class="form-container col-12 lg:col-12 pad-l-0">
          <div class="page-filters">
            <div class="filter-left-container grid" style="margin-left: 0">
              <div
                class="filter-item col-12 lg:col-2 md:col-3 sm:col-12 mob-ptb-0 mr-l-5"
              >
                <div class="form-control input-container">
                  <p-dropdown
                    [options]="salesEventDayOptions"
                    (onChange)="onClickFilter('salesEventDay')"
                    [(ngModel)]="selectedSalesEventWeekDayMode"
                  >
                  </p-dropdown>
                </div>
              </div>
            </div>
          </div>
          <!-- graph div -->
          <div class="col-12 grid mar-pad-0 flex-center cart-flex col-nogutter">
            <div
              class="col-12 sm:col-12 md:col-8 lg:col-12 xl:col-12 mar-pad-0 box-content"
            >
              <div class="card" *ngIf="salesEventWeekDayDataPrepared">
                <app-bar-chart-sales-events-week-day
                  [eventSalesGivebackObjectToPass]="
                    eventSalesGivebackObjectToPass
                  "
                >
                </app-bar-chart-sales-events-week-day>
              </div>
            </div>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
