import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppSettings } from '../../../app.settings';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { DashBoardService } from '../../../services/dashboard-service.service';
import { StorageService } from '../../../services/storage.service';
import { V2Service } from '../../../services/v2-service.service';
import * as moment from 'moment';
import { DashboardFiltersComponent } from '../dashboard-filters/dashboard-filters.component';
import { BarChartSalesGivbackComponent } from '../../bar-chart-sales-givback/bar-chart-sales-givback.component';
import { Subscription } from 'rxjs';
import { UserCommonService } from '../../../services/user-common.service';
import { FranchiseService } from '../../../../franchise/services/franchise-service.service';

@Component({
  selector: 'app-dashboard-content',
  templateUrl: './dashboard-content.component.html',
  styleUrls: ['./dashboard-content.component.scss']
})
export class DashboardContentComponent implements OnInit, OnDestroy {

  @ViewChild(DashboardFiltersComponent) dashboardFilters: DashboardFiltersComponent;
  // @ViewChild(BarChartSalesGivbackComponent) salesGiveBackComponent: BarChartSalesGivbackComponent;

  currentYear = new Date().getFullYear();
  currentDateYear: string;
  dataSource: any;
  options: any;
  loginData: any;
  franchiseRole: boolean;
  dashboardDetails: any;
  allCustomerCount: number;
  registeredCustomerCount: number;
  guestCustomerCount: number;
  allFranchiseCount: number;
  ordersPlacedCount: number;
  franchiseBusinessAmount: number;
  totalBusinessAmount: number;
  newCustomers: number;
  eveSaleAvg: any;
  totalSales: any;
  totalEvent: any;
  eveCompSale: any;
  compTotalSales: any;
  compTotalEvent: any;
  //graph related start
  isCompare = false;
  salesGivbackDataPrepared = false;
  salesEventWeekDayDataPrepared = false;
  prepayEventDataPrepared = false;
  salesByLocationDataPrepared = false;
  salesByIndustrydataPrepared = false;
  staffOverviewDataPrepared = false;
  topClientsDataPrepared = false;
  equipmentSummaryDataPrepared = false;
  averageDataPrepared = false
  fromDate = new Date();
  toDate = new Date();
  primeryEventSalesAverageData;
  compairedEventSalesAverageData;
  showOnCard: any;
  selectedBrands = JSON.parse(this.storageService.getItem('selectedBrands'));
  tabSelected = 'topClients';
  // sales giveback related
  salesGivebackModeOptions = [
    { label: 'Events', value: 'events' },
    { label: 'Sales', value: 'eventSalesCollected' },
    { label: 'Sales Tax', value: 'salesTax' },
    { label: 'Giveback', value: 'giveback' }
  ];
  granulityOptions = [
    { label: 'Day', value: 'day' },
    { label: 'Week', value: 'week' },
    { label: 'Year', value: 'year' },
  ];
  salesEventDayOptions = [
    { label: 'Sales', value: 'eventSalesCollected' },
    { label: 'Events', value: 'events' }
  ];
  selectedGranulity = 'week';
  selectedSalesGivebackMode = 'events';
  selectedSalesEventWeekDayMode = 'events';
  showGranularity = true;
  showCompare = true;
  // dayLabels = ['Sunday', 'Monday' , 'Tuesday']
  selectedFromDate;
  selectedtoDate;
  comparedFromDate;
  comparedToDate;
  datesArr = [];
  salesGivebackWholeResult;
  eventSalesGivebackObjectToPass = {
    'mode': '',
    'labels': [],
    'eventSalesDataSet1': [],
    'eventSalesDataSet2': [],
    'primaryUnit': '$',
  };
  prepayEventsObjectToPass = {
    'mode': '',
    'labels': [],
    'orders': [],
    'onlineSales': [],
    'eventSalesCollected': [],
    'primaryUnit': '$',
  };

  dashboardUrl = '/secure/customers/franchises/dashboard/';
  lable_sales_giveback = this.commonService.getLabel('lable_sales_giveback');
  lable_sales_event_day_of_week = this.commonService.getLabel('lable_sales_event_day_of_week');
  lable_prepay_events = this.commonService.getLabel('lable_prepay_events');
  lable_event_sales_average = this.commonService.getLabel('lable_event_sales_average');
  lable_event_sales_by_location = this.commonService.getLabel('lable_event_sales_by_location');
  lable_event_sales_by_industry = this.commonService.getLabel('lable_event_sales_by_industry');
  lable_staff_overview = this.commonService.getLabel('lable_staff_overview');
  lable_top_clients = this.commonService.getLabel('lable_top_clients');
  lable_equipment_summary = this.commonService.getLabel('lable_equipment_summary');
  // lable_event_sales_by_industry = this.commonService.getLabel('lable_event_sales_by_industry');

  defaultSingleSet = {
    label: '',
    backgroundColor: 'green',
    fill: false,
    data: []
  };
  currentDataForFilter;
  subscribeBrand: Subscription;

  constructor(private storageService: StorageService,
    private adminService: V2Service,
    public franchiseService: FranchiseService,
    private commonService: CommonBindingDataService,
    private dashBoardService: DashBoardService,
    private userCommonService: UserCommonService) {

    this.allCustomerCount = 0;
    this.registeredCustomerCount = 0;
    this.guestCustomerCount = 0;
    this.allFranchiseCount = 0;

    this.ordersPlacedCount = 0;
    this.franchiseBusinessAmount = 0;
    this.totalBusinessAmount = 0;
    this.newCustomers = 0;

    this.currentDateYear = '1940:' + this.currentYear;
    this.franchiseRole = true;

    this.loginData = this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA);
    if (this.loginData) {
      const data = JSON.parse(this.loginData);
      if (data.roles[0].roleCode === AppSettings.USER_ROLE_ADMIN) {
        this.franchiseRole = false;
        this.dashboardUrl = '/secure/customers/admin/dashboard';
      } else {
        this.dashboardUrl = this.dashboardUrl + data?.franchiseId;
      }
    }

    this.subscribeBrand = this.userCommonService.changeBrand.subscribe((res:any)=>{
      this.selectedBrands = JSON.parse(this.storageService.getItem('selectedBrands'));
      this.filterChangedHandler(this.currentDataForFilter);
    })
  }

  ngOnInit(): void {
    this.franchiseService.dashboardFilters.timeframe = 'MONTH';
  }

  filterChangedHandler(data) {
    // console.log('filtered data',data);
    this.currentDataForFilter = data;
    if (data) {
      // this.selectedFromDate = data.fromDate;
      // this.selectedtoDate = data.toDate;
      // this.comparedFromDate = data.comparedfromDate;
      // this.comparedToDate = data.comparedtoDate;
      this.selectedFromDate = data.fromDate? new Date(this.commonService.convertedUTCTime(data.fromDate)).getTime():null;
      this.selectedtoDate = data.toDate? new Date(this.commonService.convertedUTCTime(data.toDate)).setHours(23,59,59,999):null;
      this.comparedFromDate = data.comparedfromDate? new Date(this.commonService.convertedUTCTime(data.comparedfromDate)).getTime():null;
      this.comparedToDate = data.comparedtoDate? new Date(this.commonService.convertedUTCTime(data.comparedtoDate)).setHours(23,59,59,999):null;
      this.selectedGranulity = data.granuality;

    }
    this.isCompare = data.compare;
    if (this.tabSelected == 'salesGiveback') {
      this.callSalesGivebackDatafunction();
    }
    if (this.tabSelected == 'salesEventDay') {
      this.callSalesEventsWeekDayDatafunction();
    }
    if (this.tabSelected == 'prepayEventsGraph') {
      this.callPrepayEventsDatafunction();
    }
    if (this.tabSelected == 'eventSalesAverage') {
      this.callEventSalesAverageDatafunction();
    }
    if (this.tabSelected == 'equipmentSummary') {
      this.callEquipmentSummaryDatafunction();
    }
    if (this.tabSelected == 'staffOverview') {
      this.callStaffOverviewDatafunction();
    }
    if (this.tabSelected == 'topClients') {
      this.showGranularity = false;
      this.showCompare = false;
      this.callTopClientDatafunction();
    }
    if (this.tabSelected == 'salesByIndustry') {
      this.callSalesByIndustryDatafunction();
    }
    if (this.tabSelected == 'salesByLocation') {
      this.callSalesByLocationDatafunction();
    }
  }

  prepareGeneralPostData(passedFromDate, passedToDate) {
    return {
      "fromDate": passedFromDate ? passedFromDate: this.getTodayInTz('startOfDay'),
      "toDate": passedToDate ? passedToDate: this.getTodayInTz('endOfDay'),
      "compareFromDate": this.isCompare ? (this.comparedFromDate ? this.comparedFromDate : this.getTodayInTz('startOfDay')) : '',
      "compareToDate": this.isCompare ? (this.comparedToDate ? this.comparedToDate : this.getTodayInTz('endOfDay')) : '',
      "brandIds":this.selectedBrands,
    }
  }

  callSalesGivebackDatafunction() {
    const selectedPostData = this.prepareGeneralPostData(this.selectedFromDate, this.selectedtoDate);
    selectedPostData['granularity'] = this.selectedGranulity;
    this.loadSalesGivback(selectedPostData);
  }

  callSalesEventsWeekDayDatafunction() {
    const selectedPostData = this.prepareGeneralPostData(this.selectedFromDate, this.selectedtoDate);
    this.loadSalesEventsWeekDay(selectedPostData);
  }

  callPrepayEventsDatafunction() {
    const postData = {
      "fromDate": this.selectedFromDate ? this.selectedFromDate : this.getTodayInTz('startOfDay'),
      "toDate": this.selectedtoDate ? this.selectedtoDate : this.getTodayInTz('endOfDay'),
      "brandIds":this.selectedBrands,
    }
    this.loadPrepayEvents(postData);
  }

  callEventSalesAverageDatafunction() {
   
    const selectedPostData = this.prepareGeneralPostData(this.selectedFromDate, this.selectedtoDate);
    this.loadEventSalesAverageData(selectedPostData);
    if (this.isCompare) {
      const comparedPostData = this.prepareGeneralPostData(this.comparedFromDate, this.comparedToDate);
      this.loadEventSalesAverageData(comparedPostData, true);
    }
  }

  callEquipmentSummaryDatafunction() {
    const selectedPostData = this.prepareGeneralPostData(this.selectedFromDate, this.selectedtoDate);
    this.loadEquipmentSummaryData(selectedPostData);
  }

  callStaffOverviewDatafunction() {
    const selectedPostData = this.prepareGeneralPostData(this.selectedFromDate, this.selectedtoDate);
    selectedPostData['granularity'] = this.selectedGranulity;
    this.loadStaffOverview(selectedPostData);
  }

  callTopClientDatafunction() {
    // const selectedPostData = {
    //   "fromDate": this.selectedFromDate ? this.selectedFromDate.setHours(0, 0, 0, 1) : new Date().getTime(),
    //   "toDate": this.selectedtoDate ? this.selectedtoDate.setHours(23, 59, 59, 999) : new Date().getTime(),
    //   "brandIds":this.selectedBrands,
    // }
    const selectedPostData = {
      "fromDate": this.selectedFromDate ? this.selectedFromDate: this.getTodayInTz('startOfDay'),
      "toDate": this.selectedtoDate ? this.selectedtoDate: this.getTodayInTz('endOfDay'),
      "brandIds":this.selectedBrands,
    }
    // console.log('client date',selectedPostData);
    this.loadTopClientsData(selectedPostData);
  }

  callSalesByIndustryDatafunction() {
    const selectedPostData = {
      "fromDate": this.selectedFromDate ? this.selectedFromDate : this.getTodayInTz('startOfDay'),
      "toDate": this.selectedtoDate ? this.selectedtoDate : this.getTodayInTz('endOfDay'),
      "brandIds":this.selectedBrands,
    }
    this.loadSalesSalesByIndustry(selectedPostData);
  }

  callSalesByLocationDatafunction() {
    const selectedPostData = {
      "granularity": this.selectedGranulity,
      "fromDate": this.selectedFromDate ? this.selectedFromDate : this.getTodayInTz('startOfDay'),
      "toDate": this.selectedtoDate ? this.selectedtoDate : this.getTodayInTz('endOfDay'),
      "brandIds":this.selectedBrands,
    }
    this.loadSalesSalesByLocation(selectedPostData);
  }


  noCompareHandler(e) {
    this.isCompare = false;
    if (this.tabSelected == 'salesGiveback') {
      this.callSalesGivebackDatafunction();
    }
    if (this.tabSelected == 'salesEventDay') {
      this.callSalesEventsWeekDayDatafunction();
    }
  }

  handleChange(event) {
    switch (event.index) {
      case 0:
        this.tabSelected = 'topClients';
        this.showGranularity = false;
        this.showCompare = false;
        break;
      case 1:
        this.tabSelected = 'eventSalesAverage';
        this.showGranularity = false;  
        break;
      case 2:
        this.tabSelected = 'salesByLocation';
        this.showGranularity = false;
        this.showCompare = false;
        break;
      case 3:
        this.tabSelected = 'salesByIndustry';
        this.showCompare = false;
        this.showGranularity = false;
        break;
      case 4:
        this.tabSelected = 'equipmentSummary';
        this.showGranularity = false;
        break;
      case 5:
        this.tabSelected = 'salesGiveback';
        break;
      case 6:
        this.tabSelected = 'staffOverview';  
        break;
      case 7:
        this.tabSelected = 'prepayEventsGraph';
        this.showGranularity = false;
        this.showCompare = false;
        break;
      case 8:
        this.tabSelected = 'salesEventDay';
        this.showGranularity = false;
        break;
      default:
        break;
    }
  }


  onClickFilter(inputTab?, postData?) {
    if (inputTab && inputTab == 'salesGiveback') {
      // this.processAndPrepareData();
      this.callSalesGivebackDatafunction();
    }
    if (inputTab && inputTab == 'salesEventDay') {
      this.callSalesEventsWeekDayDatafunction();
    }
  }

  loadSalesGivback(data?) {
    this.salesGivbackDataPrepared = false;
    const postData = data;
    const eachSeperateData = { 'labels': [], 'currentData': [] };
    this.dashBoardService.loadSalesGivbackChartData(postData).subscribe((result) => {
      if (result) {
        this.salesGivebackWholeResult = result;
        this.processAndPrepareData();
      } else {
        this.prepareAllDatesArray(this.selectedFromDate, this.selectedtoDate);
        eachSeperateData['currentData'] = this.prepareDataWithAllPoints([]);
        const temData = {
          primaryUnit: this.checkUnit(),
          'mode': this.getGraphType(this.selectedSalesGivebackMode),
          'labels': eachSeperateData['labels'],
          'eventSalesDataSet1': eachSeperateData['currentData'],
          'eventSalesDataSet2': [],
        };
        this.eventSalesGivebackObjectToPass = null;
        this.eventSalesGivebackObjectToPass = { ...temData };
        this.salesGivbackDataPrepared = true;
      }
      // console.log(' sales giveback', this.eventSalesGivebackObjectToPass);
    });
  }

  processAndPrepareData() {
    this.salesGivbackDataPrepared = false;
    const eachSeperateData = { 'labels': [], 'currentData': [] };
    if (this.salesGivebackWholeResult) {
      const result = this.salesGivebackWholeResult;
      if (result.currentData.length > 0) {
        this.prepareAllDatesArray(result.currentData[0].dateTime, this.selectedtoDate);
        eachSeperateData['currentData'] = this.prepareDataWithAllPoints(result.currentData);
      } else {
        this.prepareAllDatesArray(this.selectedFromDate, this.selectedtoDate);
        eachSeperateData['currentData'] = this.prepareDataWithAllPoints(result?.currentData);
      }
      if (result.compareData.length > 0) {
        this.prepareAllDatesArray(result.compareData[0].dateTime, this.comparedToDate);
        const allPoints = this.prepareDataWithAllPoints(result?.compareData);
        eachSeperateData['compareData'] = this.prepareCompareDataWithAllPoints(allPoints);
      } else {
        this.prepareAllDatesArray(this.comparedFromDate, this.comparedToDate);
        eachSeperateData['compareData'] = this.prepareCompareDataWithAllPoints(result?.compareData);
      }
      const temData = {
        primaryUnit: this.checkUnit(),
        'mode': this.getGraphType(this.selectedSalesGivebackMode),
        'labels': eachSeperateData['labels'],
        'eventSalesDataSet1': eachSeperateData['currentData'],
        'eventSalesDataSet2': eachSeperateData['compareData'],
      };
      this.eventSalesGivebackObjectToPass = null;
      this.eventSalesGivebackObjectToPass = { ...temData };
      this.salesGivbackDataPrepared = true;
    }
  }

  prepareAllDatesArray(firstDate, lastDate) {
    this.datesArr = [];
    const datesArr = [];
    let currDate = firstDate;

    while (currDate <= lastDate) {
      datesArr.push(currDate);
      if (this.selectedGranulity === 'day') {
        currDate = (new Date(moment(currDate).add(1, 'days').valueOf()));
      } else if (this.selectedGranulity === 'week') {
        currDate = (new Date(moment(currDate).add(1, 'w').valueOf()));
      } else if (this.selectedGranulity === 'month') {
        currDate = (new Date(moment(currDate).add(1, 'M').valueOf()));
      } else {
        currDate = (new Date(moment(currDate).add(1, 'd').valueOf()));
      }
    }
    this.datesArr = datesArr;
  }

  prepareDataWithAllPoints(data) {
    const allData = []
    this.datesArr.forEach(element => {
      const eachDate = { x: moment(element).format('DD-MMM-YY'), y: 0 };
      const foundObje = data.find(e => moment(e.dateTime).format('DD-MMM-YY') == moment(element).format('DD-MMM-YY'))
      if (foundObje) {
        eachDate.x = moment(foundObje.dateTime).format('DD-MMM-YY');
        eachDate.y = foundObje[this.selectedSalesGivebackMode];
      }
      allData.push(eachDate);
    });
    return allData;
  }

  prepareCompareDataWithAllPoints(data) {
    const allData = []
    const eachSeperateData = [];
    if (data.length > 0) {
      data.forEach(element => {
        // allData.push(element[this.selectedSalesGivebackMode]);
        allData.push(element.y);
      });
    }

    return allData;
  }

  loadSalesEventsWeekDay(data?) {
    this.salesGivbackDataPrepared = false;
    this.salesEventWeekDayDataPrepared = false;
    const postData = data;
    this.dashBoardService.loadSalesEventDayData(postData).subscribe((result) => {
      if (result) {
        const eachSeperateData = { 'labels': [], 'dataSet1': [], 'dataSet2': [] };
        if (result.currentData.length > 0) {
          result.currentData.forEach(element => {
            eachSeperateData['labels'].push(element.day);
            eachSeperateData['dataSet1'].push(element[this.selectedSalesEventWeekDayMode]);
          });
        }
        if (result.compareData.length > 0) {
          result.compareData.forEach(element => {
            eachSeperateData['dataSet2'].push(element[this.selectedSalesEventWeekDayMode]);
          });
        }
        const temData = {
          primaryUnit: this.checkUnit(),
          'mode': this.getGraphType(this.selectedSalesEventWeekDayMode),
          'labels': eachSeperateData['labels'],
          'eventSalesDataSet1': eachSeperateData['dataSet1'],
          'eventSalesDataSet2': eachSeperateData['dataSet2'],
        };
        this.eventSalesGivebackObjectToPass = null;
        this.eventSalesGivebackObjectToPass = { ...temData };
        this.salesEventWeekDayDataPrepared = true;
      } else {
        // console.log('no data found');
      }
    });
  }

  loadPrepayEvents(data?) {
    this.salesGivbackDataPrepared = false;
    this.salesEventWeekDayDataPrepared = false;
    this.prepayEventDataPrepared = false;
    const postData = data;
    this.dashBoardService.loadPrepayEventsData(postData).subscribe((result) => {
      const eachSeperateData = { 'labels': [], 'dataSet1': [], 'dataSet2': [], 'dataSet3': [] };
      if (result) {
        if (result.data.length > 0) {
          result.data.forEach(element => {
            eachSeperateData['labels'].push(element.name);
            eachSeperateData['dataSet1'].push(element.orderCount);
            eachSeperateData['dataSet2'].push(element.onlineSales);
            eachSeperateData['dataSet3'].push(element.eventSalesCollected);
          });
        }
        const temData = {
          primaryUnit: this.checkUnit(),
          'mode': this.getGraphType(this.selectedSalesEventWeekDayMode),
          'labels': eachSeperateData['labels'],
          'orders': eachSeperateData['dataSet1'],
          'onlineSales': eachSeperateData['dataSet2'],
          'eventSalesCollected': eachSeperateData['dataSet3'],
        };
        this.prepayEventsObjectToPass = null;
        this.prepayEventsObjectToPass = { ...temData };
        this.prepayEventDataPrepared = true;
      } else {
        const temData = {
          primaryUnit: this.checkUnit(),
          'mode': this.getGraphType(this.selectedSalesEventWeekDayMode),
          'labels': eachSeperateData['labels'],
          'orders': eachSeperateData['dataSet1'],
          'onlineSales': eachSeperateData['dataSet2'],
          'eventSalesCollected': eachSeperateData['dataSet3'],
        };
        this.prepayEventsObjectToPass = null;
        this.prepayEventsObjectToPass = { ...temData };
        this.prepayEventDataPrepared = true;
      }
    });
  }


  loadEventSalesAverageData(postData?, compared?) {
    
    this.dashBoardService.loadEventSalesAverageData(postData).subscribe((result) => {
      //  this.showOnCard = (result && result['data'] && result['data'].length > 0 );
      if (result && result['data'] && result['data'].length > 0) {
        if (compared) {
          this.eveCompSale = result['data'][0].average
          this.compTotalSales = result['data'][0].eventSalesCollected
          this.compTotalEvent = result['data'][0].events
        } else {
          this.eveSaleAvg = result['data'][0].average
          this.totalSales = result['data'][0].eventSalesCollected
          this.totalEvent = result['data'][0].events
        }
        this.averageDataPrepared = true
      }else{
        this.averageDataPrepared = false;
      }
    });
  }

  loadEquipmentSummaryData(data?) {
    this.salesGivbackDataPrepared = false;
    this.salesEventWeekDayDataPrepared = false;
    this.prepayEventDataPrepared = false;
    this.salesByIndustrydataPrepared = false;
    this.salesByLocationDataPrepared = false;
    this.staffOverviewDataPrepared = false;
    this.topClientsDataPrepared = false;
    this.equipmentSummaryDataPrepared = false;
    const postData = data;
    this.dashBoardService.loadEquipmentSummaryData(postData).subscribe((result) => {
      const eachSeperateData = {
        'labels': [], 'colorsList': [], 'comparedColorsList': [], 'dataSet1': [], 'dataSet2': [], 'dataSet3': [], 'dataSet4': [],
        'dataSet5': [], 'dataSet6': []
      };
      if (result) {
        if (result.currentData.length > 0) {
          result.currentData.forEach(element => {
            eachSeperateData['labels'].push(element.assetName);
            eachSeperateData['colorsList'].push(element.colorPicker);
            eachSeperateData['dataSet1'].push(element.grandTotal);
            eachSeperateData['dataSet2'].push(element.hours);
            eachSeperateData['dataSet3'].push(element.eventsCount);
          });
        }
        if (result.compareData.length > 0) {
          result.compareData.forEach(element => {
            eachSeperateData['comparedColorsList'].push(this.convertToRGB(element.colorPicker));
            eachSeperateData['dataSet4'].push(element.grandTotal);
            eachSeperateData['dataSet5'].push(+element.hours);
            eachSeperateData['dataSet6'].push(+element.eventsCount);
          });
        }
        const temData = {
          primaryUnit: this.checkUnit(),
          'mode': this.getGraphType(),
          'labels': eachSeperateData['labels'],
          'eventSalesDataSet1': eachSeperateData['dataSet1'],
          'eventSalesDataSet2': eachSeperateData['dataSet1'],
          'colorsList': eachSeperateData['colorsList'],
          'comparedColorsList': eachSeperateData['comparedColorsList'],
          'sales': eachSeperateData['dataSet1'],
          'hours': eachSeperateData['dataSet2'],
          'eventsCount': eachSeperateData['dataSet3'],
          'comparedSales': eachSeperateData['dataSet4'],
          'comparedHours': eachSeperateData['dataSet5'],
          'comparedEventsCount': eachSeperateData['dataSet6'],
        };
        this.eventSalesGivebackObjectToPass = null;
        this.eventSalesGivebackObjectToPass = { ...temData };
        this.equipmentSummaryDataPrepared = true;
      } else {
        const temData = {
          primaryUnit: this.checkUnit(),
          'mode': this.getGraphType(),
          'labels': eachSeperateData['labels'],
          'eventSalesDataSet1': eachSeperateData['dataSet1'],
          'eventSalesDataSet2': eachSeperateData['dataSet2'],
        };
        this.eventSalesGivebackObjectToPass = null;
        this.eventSalesGivebackObjectToPass = { ...temData };
        this.equipmentSummaryDataPrepared = true;
      }
      // console.log('equipt summary',this.eventSalesGivebackObjectToPass)
    });
  }

  convertToRGB(hex) {
    let reqFrmt = 'white';
    if(hex){
      const reqHex = hex.substr(1);
      const aRgbHex = reqHex.match(/.{1,2}/g);
      // console.log(reqHex);
      // console.log('aRgbHex',aRgbHex);
      const aRgb = [
        parseInt(aRgbHex[0], 16),
        parseInt(aRgbHex[1], 16),
        parseInt(aRgbHex[2], 16),
        0.3
      ];
      // aRgb.push(0.2);
      // console.log('rgb', aRgb);
      reqFrmt = `rgb(${aRgb.toString()})`;
      // console.log('()', reqFrmt);
    }
      return reqFrmt;
  }

  loadStaffOverview(data?) {
    this.salesGivbackDataPrepared = false;
    this.salesEventWeekDayDataPrepared = false;
    this.prepayEventDataPrepared = false;
    this.salesByIndustrydataPrepared = false;
    this.salesByLocationDataPrepared = false;
    this.staffOverviewDataPrepared = false;
    const postData = data;
    this.dashBoardService.loadStaffOverViewData(postData).subscribe((result) => {
      if (result) {
        const eachSeperateData = {
          'labels': [], 'dataSet1': [], 'dataSet2': [], 'dataSet3': [], 'dataSet4': [],
          'currentSalesPerHrs': [], 'comparedSalesPerHrs': []
        };
        if (result.currentData.length > 0) {
          result.currentData.forEach(element => {
            eachSeperateData['labels'].push(moment(element.dateTime).format('DD-MMM-YY'));
            eachSeperateData['dataSet1'].push(element.eventSalesCollected);
            eachSeperateData['dataSet2'].push(+element.hours);
            eachSeperateData['currentSalesPerHrs'].push(+element.perHours);
          });
        }
        if (result.compareData.length > 1) {
          result.compareData.forEach(element => {
            eachSeperateData['dataSet3'].push(element.eventSalesCollected);
            eachSeperateData['dataSet4'].push(+element.hours);
            eachSeperateData['comparedSalesPerHrs'].push(+element.perHours);
          });
        }
        const temData = {
          primaryUnit: this.checkUnit(),
          'mode': 'Event Count',
          'labels': eachSeperateData['labels'],
          'eventSalesDataSet1': eachSeperateData['dataSet1'],
          'eventSalesDataSet2': eachSeperateData['dataSet2'],
          'eventSalesDataSet3': eachSeperateData['dataSet3'],
          'eventSalesDataSet4': eachSeperateData['dataSet4'],
          'currentSalesPerHrs': eachSeperateData['currentSalesPerHrs'],
          'comparedSalesPerHrs': eachSeperateData['comparedSalesPerHrs'],
        };
        this.eventSalesGivebackObjectToPass = null;
        this.eventSalesGivebackObjectToPass = { ...temData };
        this.staffOverviewDataPrepared = true;
      } else {
        console.log('no data found');
      }
    });
  }

  loadTopClientsData(data?) {
    this.salesGivbackDataPrepared = false;
    this.salesEventWeekDayDataPrepared = false;
    this.prepayEventDataPrepared = false;
    this.salesByIndustrydataPrepared = false;
    this.salesByLocationDataPrepared = false;
    this.staffOverviewDataPrepared = false;
    this.topClientsDataPrepared = false;
    const postData = data;
    this.dashBoardService.loadtopClientsData(postData).subscribe((result) => {
      if (screen.width < 740) {
        if (!document.getElementsByTagName('body')[0].classList.contains('closed')) {
          document.getElementsByTagName('body')[0].classList.toggle('closed');
        }
      }
      const eachSeperateData = { 'labels': [], 'dataSet1': [], 'dataSet2': [], 'dataSet3': [], 'dataSet4': [] };
      if (result) {
        if (result.data && result.data.length > 0) {
          result.data.forEach(element => {
            eachSeperateData['labels'].push(element.businessName);
            eachSeperateData['dataSet1'].push(element.gross?element.gross:0);
            eachSeperateData['dataSet2'].push(element.grandTotal);
            eachSeperateData['dataSet3'].push(element.eventsCount);
            eachSeperateData['dataSet4'].push(element.perEvent);
          });
        }
        const temData = {
          primaryUnit: this.checkUnit(),
          'mode': this.getGraphType(),
          'labels': eachSeperateData['labels'],
          'eventSalesDataSet1': eachSeperateData['dataSet1'].every(item => item === 0)?[]:eachSeperateData['dataSet1'],
          'eventSalesDataSet2': [],
          'sales': eachSeperateData['dataSet2'],
          'eventsCount': eachSeperateData['dataSet3'],
          'perEvent': eachSeperateData['dataSet4'],
        };
        this.eventSalesGivebackObjectToPass = null;
        this.eventSalesGivebackObjectToPass = { ...temData };
        this.topClientsDataPrepared = true;
      } else {
        const temData = {
          primaryUnit: this.checkUnit(),
          'mode': this.getGraphType(),
          'labels': eachSeperateData['labels'],
          'eventSalesDataSet1': eachSeperateData['dataSet1'],
          'eventSalesDataSet2': eachSeperateData['dataSet2'],
        };
        this.eventSalesGivebackObjectToPass = null;
        this.eventSalesGivebackObjectToPass = { ...temData };
        this.topClientsDataPrepared = true;
      }
      // console.log('top clients',this.eventSalesGivebackObjectToPass);
    });
  }

  loadSalesSalesByIndustry(data?) {
    this.salesGivbackDataPrepared = false;
    this.salesEventWeekDayDataPrepared = false;
    this.prepayEventDataPrepared = false;
    this.salesByIndustrydataPrepared = false;
    this.salesByLocationDataPrepared = false;
    const postData = data;
    this.dashBoardService.loadSalesByIndustry(postData).subscribe((result) => {
      const eachSeperateData = { 'labels': [], 'dataSet1': [], 'dataSet2': [] };
      if (result) {
        if (result.data.length > 0) {
          result.data.forEach(element => {
            eachSeperateData['labels'].push(element.type);
            eachSeperateData['dataSet1'].push(element.eventSalesCollected?element.eventSalesCollected:0);
          });
        }
        const temData = {
          primaryUnit: this.checkUnit(),
          'mode': this.getGraphType(),
          'labels': eachSeperateData['labels'],
          'eventSalesDataSet1': eachSeperateData['dataSet1'].every(item => item === 0)?[]:eachSeperateData['dataSet1'],
          'eventSalesDataSet2': eachSeperateData['dataSet2'],
        };
        this.eventSalesGivebackObjectToPass = null;
        this.eventSalesGivebackObjectToPass = { ...temData };
        this.salesByIndustrydataPrepared = true;
      } else {
        const temData = {
          primaryUnit: this.checkUnit(),
          'mode': this.getGraphType(),
          'labels': eachSeperateData['labels'],
          'eventSalesDataSet1': eachSeperateData['dataSet1'],
          'eventSalesDataSet2': eachSeperateData['dataSet2'],
        };
        this.eventSalesGivebackObjectToPass = null;
        this.eventSalesGivebackObjectToPass = { ...temData };
        this.salesByIndustrydataPrepared = true;
      }
    });
  }


  loadSalesSalesByLocation(data?) {
    this.salesGivbackDataPrepared = false;
    this.salesEventWeekDayDataPrepared = false;
    this.prepayEventDataPrepared = false;
    this.salesByIndustrydataPrepared = false;
    this.salesByLocationDataPrepared = false;
    const postData = data;
    this.dashBoardService.loadSalesByLocation(postData).subscribe((result) => {
      const eachSeperateData = { 'labels': [], 'dataSet1': [], 'dataSet2': [] };
      if (result) {
        if (result.data.length > 0) {
          result.data.forEach(element => {
            eachSeperateData['labels'].push(element.zipCode);
            eachSeperateData['dataSet1'].push(element.eventSalesCollected);
            eachSeperateData['dataSet2'].push(element.events);
          });
        }
        const temData = {
          primaryUnit: this.checkUnit(),
          'mode': this.getGraphType(),
          'labels': eachSeperateData['labels'],
          'eventSalesDataSet1': eachSeperateData['dataSet1'],
          'eventSalesDataSet2': eachSeperateData['dataSet2'],
        };
        this.eventSalesGivebackObjectToPass = null;
        this.eventSalesGivebackObjectToPass = { ...temData };
        this.salesByLocationDataPrepared = true;
      } else {
        const temData = {
          primaryUnit: this.checkUnit(),
          'mode': this.getGraphType(),
          'labels': eachSeperateData['labels'],
          'eventSalesDataSet1': eachSeperateData['dataSet1'],
          'eventSalesDataSet2': eachSeperateData['dataSet2'],
        };
        this.eventSalesGivebackObjectToPass = null;
        this.eventSalesGivebackObjectToPass = { ...temData };
        this.salesByLocationDataPrepared = true;
      }
    });
  }

  checkUnit() {
    if (this.tabSelected == 'salesGiveback') {
      if (this.selectedSalesGivebackMode == 'events') {
        return 'Events';
      } else {
        return '$';
      }
    }
    if (this.tabSelected == 'salesEventDay') {
      if (this.selectedSalesEventWeekDayMode == 'events') {
        return 'Events';
      } else {
        return '$';
      }
    }
  }

  loadPrepayEventData() {
  }


  getGraphType(type?) {
    // console.log(type);
    if (!type) {
      return '';
    }
    if (this.tabSelected == 'salesGiveback') {
      if (type === "events") {
        return "Event Count"
      } else if (type === 'eventSalesCollected') {
        return "Total Sales"
      } else if (type === 'salesTax') {
        return "Sales Tax"
      } else if (type === 'giveback') {
        return "Giveback"
      }
    }
    if (this.tabSelected == 'salesEventDay') {
      if (type == "events") {
        return "Event Count"
      } else if (type == 'eventSalesCollected') {
        return "Total Sales"
      }
    }
  }

  getTodayInTz(startEnd?) {
    let requiredTzTimeStamp = '';
    if(startEnd == 'endOfDay') {
      requiredTzTimeStamp = this.commonService.convertedUTCTime(new Date(new Date().setHours(23, 59, 59, 999)))
    }
    if(startEnd == 'startOfDay') {
      requiredTzTimeStamp = this.commonService.convertedUTCTime(new Date(new Date().setHours(0,0,0,1)))
    }
    return requiredTzTimeStamp;
  }

  ngOnDestroy() {
    this.subscribeBrand.unsubscribe();
    this.franchiseService.dashboardFilters.timeframe = '';
    this.franchiseService.dashboardFilters.fromDate = '';
    this.franchiseService.dashboardFilters.toDate = '';
    this.franchiseService.dashboardFilters.comparetimeframe = '';
    this.franchiseService.dashboardFilters.comparedfromDate = '';
    this.franchiseService.dashboardFilters.comparedtoDate = '';
  }

}
