<div class="container">
    <!-- <div class="graph-legend">
        <div class="category">{{ this.eventSalesGivebackObjectToPass.mode }}</div>
        <div class="data-value">
            <div id="current-value"class="current">{{actualValue}}</div>
            <div id="compared-value"class="compared">{{comparedValue}}</div>
        </div>
    </div> -->
    <div class="graph-container">
        <canvas #barCanvas style="height:9vh; width:10vw"></canvas>
    </div>
</div>

