import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { LayoutComponent } from './modules/shared/components/layout/layout.component';
import { CustomerLayoutComponent } from './modules/shared/components/customer-layout/customer-layout.component';
import { AdminLayoutComponent } from './modules/shared/components/admin-layout/admin-layout.component';
import { StripeComponent } from './pages/stripe/stripe.component';
import { DashboardContentComponent } from './modules/shared/components/dashboard/dashboard-content/dashboard-content.component';
import { StaffLayoutComponent } from './modules/shared/components/staff-layout/staff-layout.component';
import { AuthGuard }
  from './guard/auth.guard';
import { ExportReportComponent } from './modules/shared/components/export-report/export-report.component';
import { CustomerListReportComponent } from './modules/admin/pages/customer-list-report/customer-list-report.component';
import { CustomerListComponent } from './modules/franchise/pages/customer-list/customer-list.component';
import { AdminSettingListComponent } from './modules/shared/components/admin-setting-list/admin-setting-list.component';
import { PrintWeekComponent } from './pages/print-week/print-week.component';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    // component: SignInComponent
    redirectTo: '/signin',
  },
  {
    path: 'weekPrint',
    pathMatch: 'full',
    component: PrintWeekComponent,
  },
  {
    path: 'signin',
    pathMatch: 'full',
    component: SignInComponent
  },
  {
    path: 'staff-signin',
    pathMatch: 'full',
    component: SignInComponent
  },
  {
    path: 'signup',
    pathMatch: 'full',
    component: SignUpComponent,
    data: { title: '', breadCrumbs: '' }
  },
  {
    path: 'stripe',
    pathMatch: 'full',
    component: StripeComponent
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        canLoad: [AuthGuard],
        data: { roles: ['Admin'] },
        loadChildren: () => import('./modules/admin/admin.module').then(mod => mod.AdminModule)
      },
      {
        path: 'dashboard-info',
        component: DashboardContentComponent
      },
      {
        path: 'customers',
        component: CustomerListReportComponent
      },
      {
        path: 'manage-waffle',
        component: AdminSettingListComponent
      },
    ]
  },
  {
    path: 'franchise',
    component: LayoutComponent,
    children: [
      {
        path: '',
        canLoad: [AuthGuard],
        data: { roles: ['Franchise'] },
        loadChildren: () => import('./modules/franchise/franchise.module').then(mod => mod.FranchiseModule)
      },
      {
        path: 'dashboard-info',
        component: DashboardContentComponent
      },

      {
        path: 'customer',
        component: CustomerListComponent
      },
    ]
  },

  {
    path: 'staff',
    component: StaffLayoutComponent,
    children: [
      {
        path: '',
        canLoad: [AuthGuard],
        data: { roles: ['Driver', 'Manager', 'Server', 'Worker'] },
        loadChildren: () => import('./modules/staff/staff.module').then(mod => mod.StaffModule)
      },
    ]
  },
  {
    path: 'file',
    children: [
      {
        path: 'export/:id',
        canLoad: [AuthGuard],
        component: ExportReportComponent,
      },
    ]
  },

  // {
  //   path: '',
  //   component: CustomerLayoutComponent,
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: () => import('./modules/customer/customer.module').then(mod => mod.CustomerModule)
  //     },
  //   ]
  // },
  {
    path: ':id',
    component: CustomerLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/customer/customer.module').then(mod => mod.CustomerModule)
      },
    ]
  },
  // {
  //   path: 'marketing',
  //   component: LayoutComponent,
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: () => import('./modules/marketing/marketing.module').then(mod => mod.MarketingModule)
  //     },
  //   ]
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
