<div class="page-filters graf-filters">
  <div class="filter-left-container grid">

    <div class="filter-item col-12 lg:col-5 md:col-3 mob-p-0">
      <div class="filter-item col-12 lg:col-5 md:col-3">
          <div class="form-control input-container">
              <p-dropdown [(ngModel)]="timeframe" [options]="filterTimeframeOptions" (onChange)="onChangeTimeframe($event)" >
              </p-dropdown>
          </div>
      </div>
      <div class="filter-item col-12 lg:col-7 md:col-3">
        <p-calendar  dateFormat="M dd yy" [(ngModel)]="rangeDates" selectionMode="range" inputId="range" (onClose)="onSelectDates()" [disabled]="enableDateStatus" class="cus-range" [showIcon]="true" [firstDayOfWeek] = "startDayOfWeek"></p-calendar>
      </div>
    </div>

    <div class="filter-item col-12 lg:col-1 md:col-3" *ngIf = "showCompare">
      Compared to:
    </div>

    <div class="filter-item col-12 lg:col-5 md:col-3 mob-p-0" *ngIf = "showCompare">
      <div class="filter-item col-12 lg:col-5 md:col-3">
        <div class="form-control input-container">
            <p-dropdown [(ngModel)]="comparetimeframe" [options]="CompareTimeframeOptions" (onChange)="onCompareChangeTimeframe($event)" >
            </p-dropdown>
        </div>
      </div>
      <div class="filter-item col-12 lg:col-7 md:col-3">
        <p-calendar  dateFormat="M dd yy" [(ngModel)]="compareRangeDates" selectionMode="range" inputId="range" [disabled]="true" class="cus-range" [showIcon]="true" [firstDayOfWeek] = "startDayOfWeek"></p-calendar>
      </div>
    </div>

      <div class="filter-item col-12 lg:col-1 md:col-3" *ngIf = "showGranularity">
        <div class="form-control input-container">
            <p-dropdown [(ngModel)]="timeframeview" (onChange)="handleGranularityChange($event)" [options]="ViewOptions" >
            </p-dropdown>
        </div>
      </div>

      <!-- <div class="filter-item col-12 lg:col-1 md:col-9 right-end right-allign">
          <div class="filter-item p-mx-1 ">
              <p-button [title]="'btn_reset'|translate" (click)="onResetFilter()" icon="fa fa-refresh" class="extra-small-btn"></p-button>
          </div>
          <div class="filter-item p-mx-1 ">
              <p-button [title]="'btn_export'|translate" (click)="exportExcel()" icon="pi pi-sign-in" iconPos="left" class="extra-small-btn"></p-button>
          </div>
      </div> -->
  </div>
</div>

      <!-- <div class="filter-item col-12 lg:col-2 md:col-3">
        <label for="range">Range</label>
        <p-calendar [(ngModel)]="rangeDates" selectionMode="range" [readonlyInput]="true" inputId="range" (onSelect)="onSelectDates($event,'rangeDates')" [showIcon]="true"></p-calendar>
      </div> -->