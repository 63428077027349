import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RestApiService } from './rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class DashBoardService {

  constructor(private restApiService: RestApiService) { }

  loadSalesGivbackChartData(data?): Observable<any> {
    return this.restApiService.post('generateReport', `/secure/franchises/dashboard/sales-giveback`, data, 'page-center');
  }

  loadSalesEventDayData(data?): Observable<any> {
    return this.restApiService.post('generateReport', `/secure/franchises/dashboard/week/sales-events`, data, 'page-center');
  }

  loadPrepayEventsData(data?): Observable<any> {
    return this.restApiService.post('prepayEventsGraph', `/secure/franchises/dashboard/prepay/events`, data, 'page-center');
  }

  loadEventSalesAverageData(data?): Observable<any> {
    return this.restApiService.post('generateReport', `/secure/franchises/dashboard/average/event-sales`, data, 'page-center');
  }

  loadEquipmentSummaryData(data?): Observable<any> {
    return this.restApiService.post('topClients', `/secure/franchises/dashboard/equipment/summary`, data, 'page-center');
  }

  loadStaffOverViewData(data?): Observable<any> {
    return this.restApiService.post('loadStaffOverViewData', `/secure/franchises/dashboard/staffs/overview`, data, 'page-center');
  }

  loadtopClientsData(data?): Observable<any> {
    return this.restApiService.post('topClients', `/secure/franchises/dashboard/clients/ranking`, data, 'page-center');
  }

  loadSalesByIndustry(data?): Observable<any> {
    return this.restApiService.post('salesByIndustry', `/secure/franchises/dashboard/events/sales/byindustry`, data, 'page-center');
  }

  loadSalesByLocation(data?): Observable<any> {
    return this.restApiService.post('salesByLocation', `/secure/franchises/dashboard/event-sales/bylocation`, data, 'page-center');
  }

}
