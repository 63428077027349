import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-chat-equipment-summary',
  templateUrl: './chat-equipment-summary.component.html',
  styleUrls: ['./chat-equipment-summary.component.scss']
})
export class ChatEquipmentSummaryComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild('barCanvas') barCanvas: ElementRef | undefined;
  barChart: any;
  @Input() inputDataObject;
  constructor() { }

  ngOnInit(): void {
    if (document.getElementsByTagName('body')[0].classList.contains('removeAllPadding')) {
      document.getElementsByTagName('body')[0].classList.remove('removeAllPadding');
    }
  }

  ngAfterViewInit(): void {
    this.barChartMethod();
  }

  ngOnChanges() {
    this.barChartMethod();
    console.log('inputDataObject', this.inputDataObject);
  }

  barChartMethod() {
    this.barChart = new Chart(this.barCanvas?.nativeElement, {
      type: 'bar',
      data: {
        labels: this.inputDataObject.labels,
        datasets: this.repareDataSet(),
      },
      options: {
        indexAxis: 'y',
        scales: {
          y: {
            min: 0,
            max: 4,
            title: {
              color: 'red',
              display: true,
              text: 'Equipment',
              font: {
                family: 'Montserrat',
                size: 15,
                weight: '500',
              },
              padding: 15,
            },
            grid: {
              display: false,
              offset: false,
              borderColor: 'black',
              borderWidth: 2
            },
            ticks: {
              display: true,

              font: {
                family: 'Montserrat',
              },
              source: 'auto',
            }
          },
          A: {
            beginAtZero: true,
            type: 'linear',
            position: 'top',
            max: this.getHighestValue(),
            title: {
              color: 'red',
              display: true,
              text: 'Sales (in USD)',
              font: {
                family: 'Montserrat',
                size: 15,
                weight: '500',
              },
              padding: 5
            },
            grid: {
              borderColor: 'black',
              borderWidth: 2,
            },
            ticks: {
              display: true,
              font: {
                family: 'Montserrat',
              },
              source: 'auto',
            }
          },
          B: {
            beginAtZero: true,
            type: 'linear',
            position: 'bottom',
            max: this.getHighestValue(),
            title: {
              color: 'red',
              display: true,
              text: 'Sales (in USD)',
              font: {
                family: 'Montserrat',
                size: 15,
                weight: '500',
              },
              padding: 5
            },
            grid: {
              borderColor: 'black',
              borderWidth: 2,
            },
            ticks: {
              display: true,
              font: {
                family: 'Montserrat',
              },
              source: 'auto',
            }
          },
          
        },
        plugins: {
          legend: {
            position: 'bottom',
            align: 'center',
            labels: {
              usePointStyle: true
            },
          },
          tooltip: {
            yAlign: 'bottom',
            displayColors: false,
            titleFont: {
              font: {
                family: 'Montserrat',
                // size: 16,
                weight: 'bold',
              },
            },
            titleMarginBottom: -6,
            bodySpacing: 6,
            bodyFont: {
              font: {
                family: 'Montserrat',
              },
            },
            padding: 12,
            // boxPadding: 4,
            callbacks: {
              title: (context) => {
                return context[0].label.replaceAll(',',' ');
              },
              beforeBody: (context) => {
                const length = context[0].label.length;
                return '_'.repeat(length + 6);
              },
              label: (context) => {
                // console.log(context);
                const label = [];
                if(context.dataset.label === 'Sales') {
                  label.push(`Sales: $${context.formattedValue}`);
                  label.push(`Hours: ${this.inputDataObject.hours[context.dataIndex]}`);
                  label.push(`Events: ${this.inputDataObject.eventsCount[context.dataIndex]}`);
                }else{
                  label.push(`Sales: $${context.formattedValue}`);
                  label.push(`Hours: ${this.inputDataObject.comparedHours[context.dataIndex]}`);
                  label.push(`Events: ${this.inputDataObject.comparedEventsCount[context.dataIndex]}`);
                }
                return label;
              },
            },
          },
        },
        layout: {
          autoPadding: true
        },
        events: ['mousemove', 'mouseout', 'click'],
        interaction: {
          mode: 'nearest',
          axis: 'y'
        },
      },
      plugins: [{
        id: 'barChart',
        beforeInit: function (chart: any) {
          chart.data.labels.forEach(function (value: any, index: number, array: [any]) {
            if (/\s/.test(value)) {
              array[index] = value.split(/\s/)
            }
          })
        }
      }]
    });

    const scroller = (scroll: { deltaY: number; }, chart: any) => {
      // console.log(scroll);
      // console.log(this.barChart.data.labels);

      const dataLength = this.barChart.data.labels.length
      if (scroll.deltaY > 0) {
        if (this.barChart.config.options.scales.y.max >= dataLength) {
          this.barChart.config.options.scales.y.min = dataLength - 4;
          this.barChart.config.options.scales.y.max = dataLength;
        } else {
          this.barChart.config.options.scales.y.min += 1;
          this.barChart.config.options.scales.y.max += 1;
        }
      } else if (scroll.deltaY < 0) {
        // console.log('lesser > 0')
        // console.log(this.barChart.config.options.scales.y.min);
        if (this.barChart.config.options.scales.y.min <= 0) {
          this.barChart.config.options.scales.y.min = 0;
          this.barChart.config.options.scales.y.max = 4
        } else {
          this.barChart.config.options.scales.y.min -= 1;
          this.barChart.config.options.scales.y.max -= 1;
        }
      } else {
        // do nothing
      }
      this.barChart.update();
    }

    this.barChart.canvas.addEventListener('wheel', (e: { deltaY: number; }) => {
      // console.log('wheel');
      scroller(e, this.barChart);
    });
  }

  repareDataSet() {
    const returnData = [];
    const data1 = {
      xAxisID: 'B',
      label:  'Sales',
      data: this.inputDataObject.sales,
      backgroundColor: this.inputDataObject.colorsList,
      borderColor: '#cccccc',
      borderWidth: 1,
      barThickness: 25,
      maxBarThickness: 40,
    }
  
    const data2 = {
        xAxisID: 'B',
        label: 'Compared Sales',
        data: this.inputDataObject.comparedSales,
        backgroundColor: this.inputDataObject.comparedColorsList,
        borderColor: '#cccccc',
        borderWidth: 1,
        barThickness: 25,
        maxBarThickness: 40,
      }
      returnData.push(data1);
      if(this.inputDataObject.comparedSales && this.inputDataObject.comparedSales.length > 0){
        returnData.push(data2);
      }
       return returnData;
  }

  generateXAxisLabel(mode) {
    if (mode === "Event Count") {
      return mode;
    } else {
      return mode + " ( in USD )"
    }
  }

  generateUnit(mode?) {
    return 'US$';
    // let selectedMode = mode?mode:this.inputDataObject.mode;
    // if (selectedMode === "Event Count") {
    //   return '';
    // } else {
    //   return  'US$';
    // }
  }

  loadMore(type) {
    if (type === 'up') {
      this.barChart.options.scales.y.min = this.barChart.options.scales.y.min - 4;
      this.barChart.options.scales.y.max = this.barChart.options.scales.y.max - 4;
      // console.log('left')

      if (this.barChart.options.scales.y.min <= 0) {
        this.barChart.options.scales.y.min = 0;
        this.barChart.options.scales.y.max = 4;            
      }
    }

    if (type === 'down') {
      const dataLength = this.barChart.data.labels.length

      this.barChart.options.scales.y.min = this.barChart.options.scales.y.min + 4;
      this.barChart.options.scales.y.max = this.barChart.options.scales.y.max + 4;
      // console.log('right')

      if (this.barChart.options.scales.y.max >= dataLength) {
        this.barChart.options.scales.y.min = dataLength - 4;
        this.barChart.options.scales.y.max = dataLength;            
      }
    }
    this.barChart.update();
  }

  // getHighestValue() {
  //   let max = Math.max(...this.inputDataObject.sales, ...this.inputDataObject.comparedSales);
  //   return Math.ceil(max) + 1;
  // }

  getHighestValue() {
    // replace this with actual array
    const max = Math.max(...this.inputDataObject.sales, ...this.inputDataObject.comparedSales);
    const digit = Math.floor(Math.log10(max)) + 1;
    let multiplier;
    if (digit === 1) {
      return Math.ceil(max) + 1;
    } else if (digit === 2) {
      multiplier = 1;
    } else if (digit > 2) {
      const str = "1" + "0".repeat(digit - 2);
      multiplier = Number(str);
    }
    multiplier = multiplier * 5;
    let roundedMax = Math.round((max) / multiplier) * multiplier;
    if (roundedMax <= max) {
      roundedMax = roundedMax + multiplier;
    }
    return roundedMax;
  }

  ngOnDestroy() {
    this.barChart.destroy();
  }
}

export const FILL_COLORS = {
  blue: 'rgb(25, 138, 215, 0.7)',
  lightBlue: 'rgb(25, 138, 215, 0.2)',
  yellow: 'rgb(247, 212, 32, 0.7)',
  green: 'rgb(77, 179, 96, 0.7)',
};

export const BORDER_COLORS = {
  blue: 'rgb(25, 138, 215, 1)',
  yellow: 'rgb(247, 212, 32, 1)',
  green: 'rgb(77, 179, 96, 1)',
};
