import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { FranchiseService } from '../../../franchise/services/franchise-service.service';
import { AppSettings } from '../../../shared/app.settings';
import { StorageService } from '../../../shared/services/storage.service';
import { UiService } from '../../../shared/services/ui.service';
import { V2Service } from '../../../shared/services/v2-service.service';

@Component({
  selector: 'app-generate-reports-data',
  templateUrl: './generate-reports-data.component.html',
  styleUrls: ['./generate-reports-data.component.scss']
})
export class GenerateReportsDataComponent implements OnInit {

  @Input() showGenerateReport: boolean;
  reportGenerateForm: UntypedFormGroup;
  userEmail: any;
  submitted: boolean;
  @Input() exportCompleteUrl = '';
  @Output() outputToggle: EventEmitter<any> = new EventEmitter();
  @Input() isPostData: any;
  @Input() isPost = false;
  @Input() isPostDataObj: any;
  @Input() useV2: boolean;
  @Input() fromDate;
  @Input() toDate;


  constructor(private router: Router,
    private adminService: V2Service,
    private franchiseService: FranchiseService,
    private uiService: UiService,
    private formBuilder: UntypedFormBuilder,
    private storageService: StorageService) { }

  ngOnInit(): void {
    this.userEmail = this.storageService.getItem(AppSettings.USER_DETAILS_EMAIL);
    this.initGenerateForm();
  }

  initGenerateForm() {
    this.reportGenerateForm = this.formBuilder.group({
      email: [JSON.parse(this.userEmail) ? JSON.parse(this.userEmail) : '',
      [Validators.required, Validators.minLength(1), Validators.maxLength(120), Validators.pattern(AppSettings.EMAIL_PATTERN)]],
      emailNote: ''
    });
  }

  onCancelClick() {
    this.outputToggle.emit(false);
  }

  onConfirmClick() {
    this.submitted = false;
    if (this.reportGenerateForm.valid) {
      const email = this.reportGenerateForm.controls.email.value.trim();
      // const reportExportUrl = this.exportCompleteUrl + `&email=${email}`;
      //const email = this.reportGenerateForm.controls.email.value.trim();

      const emailNotes = this.reportGenerateForm.controls.emailNote.value.trim();
      const isPostData: { [key: string]: any } = {}
      isPostData.email = email;
      isPostData.emailNote = emailNotes;
      this.isPostDataObj.email = email;
      this.isPostDataObj.emailNote = emailNotes;

      if (this.fromDate && this.toDate) {
        isPostData.fromDate = this.fromDate?.getTime();
        isPostData.toDate = this.toDate?.getTime();
      }

      if (this.useV2 === true) {
        this.adminService.generateReport(this.exportCompleteUrl, this.isPostDataObj).subscribe((result) => {
          if (result) {
            this.uiService.showMessage('Report generated successfully', AppSettings.MESSAGE_TYPE.SUCCESS);
          }
          this.onCancelClick();
          this.reportGenerateForm.reset();
        }, error => {
          this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPE.ERROR);
        });
      } else {
        this.franchiseService.generateReport(this.exportCompleteUrl, this.isPostDataObj).subscribe((result) => {
          if (result) {
            this.uiService.showMessage('Report generated successfully', AppSettings.MESSAGE_TYPE.SUCCESS);
          }
          this.onCancelClick();
          this.reportGenerateForm.reset();
        }, error => {
          this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPE.ERROR);
        });
      }
    } else {
      this.reportGenerateForm.markAllAsTouched();
    }
  }

  onConfirmPostClick() {
    this.submitted = false;
    if (this.reportGenerateForm.valid) {
      const email = this.reportGenerateForm.controls.email.value.trim();
      this.isPostData.email = email;

      this.franchiseService.generatePostBodyReport(this.exportCompleteUrl, this.isPostData).subscribe((result) => {
        if (result) {
          this.uiService.showMessage('Report generated successfully', AppSettings.MESSAGE_TYPE.SUCCESS);
        }
        this.onCancelClick();
        this.reportGenerateForm.reset();
      }, error => {
        this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPE.ERROR);
      });
    } else {
      this.reportGenerateForm.markAllAsTouched();
    }
  }

}
