import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import  Chart from 'chart.js/auto'; 
import { AppSettings } from '../../app.settings';

@Component({
  selector: 'app-bar-chart-sales-givback',
  templateUrl: './bar-chart-sales-givback.component.html',
  styleUrls: ['./bar-chart-sales-givback.component.scss']
})
export class BarChartSalesGivbackComponent implements OnInit ,AfterViewInit, OnChanges {
  
    @ViewChild('barCanvas') barCanvas: ElementRef | undefined;
    barChart: any;
    // actualValue = '';
    // comparedValue = '';
    
    @Input() eventSalesGivebackObjectToPass;    
    constructor() { }
  
    ngOnInit(): void {
      // this.getSetLastValues();
      if (document.getElementsByTagName('body')[0].classList.contains('removeAllPadding')) {
        document.getElementsByTagName('body')[0].classList.remove('removeAllPadding');
      }
    }

    ngAfterViewInit(): void {
      this.barChartMethod();
    }

    ngOnChanges() {
      // this.removeData(this.barChart);
      // this.barChart.destroy();   
      setTimeout(() => {
        this.barChartMethod();
      }, 100);
    }

    // removeData(chart) {
    //   this.barChart.data.datasets.forEach((dataset) => {
    //     dataset.data = [];
    //   });
    //   this.barChart.update();
    //   setTimeout(() => {
    //     // this.addData();
    //     this.barChartMethod();
    //   }, 100);
    // }

    removeChart() {
      this.barChart.destroy();
    }

    // addData() {
    //   this.barChart.data.datasets.forEach((dataset) => {
    //       if(dataset.label == 'Selected Set'){
    //         dataset.data = this.eventSalesGivebackObjectToPass.eventSalesDataSet1;
    //       }
    //       if(dataset.label == 'Compared Set'){
    //         dataset.data = this.eventSalesGivebackObjectToPass.eventSalesDataSet2;
    //       }
    //   });
    //   let set1 = this.eventSalesGivebackObjectToPass.eventSalesDataSet1; 
    //   this.barChart.options.scales.x.ticks.display = (set1 && set1.length > 0)? true:false,
    //   this.barChart.options.scales.x.ticks.maxTicksLimit = this.getLabelsCount();
    //   setTimeout(() => {
    //     this.barChart.update();
    //   }, 500);
    // }
  
    barChartMethod() {
      // console.log(this.eventSalesGivebackObjectToPass)
      this.barChart = new Chart(this.barCanvas?.nativeElement, {
        type: 'line',
        data: {
          datasets: [{
            label: 'Primary Data',
            yAxisID: 'A',
            data: this.eventSalesGivebackObjectToPass.eventSalesDataSet1,
            backgroundColor: FILL_COLORS.blue,
            borderColor: BORDER_COLORS.blue,
            borderWidth: 1,
          }, {
            label: 'Compared Data',
            yAxisID: 'A',
            data: this.eventSalesGivebackObjectToPass.eventSalesDataSet2,
            backgroundColor: AppSettings.GRAPH_SECONDARY_COMPARED_DEFAULT_COLOR,
            borderColor: AppSettings.GRAPH_SECONDARY_COMPARED_DEFAULT_COLOR,
            borderWidth: 1,
          }]
        },
        options: {
          // maintainAspectRatio: true,
          elements:{
            point: {
              radius: 1,
              pointStyle: 'circle',
              borderWidth:10,
              hoverRadius:5,
              hitRadius:2
            },
          },
          scales: {
            x: {
              title: {
                color: 'red',
                display: true,
                text: 'Duration',
                font: {
                  family: 'Montserrat',
                  size: 15,
                  weight: '500',
                },
                padding: 15,
              },
              grid: {
                display: false,
                offset: false,
                borderColor: 'black',
                borderWidth: 2
              },
              ticks: {
                display:this.eventSalesGivebackObjectToPass.eventSalesDataSet1.length > 0? true:false,
                font: {
                  family: 'Montserrat',
                },
                source: 'auto',
                maxTicksLimit: this.getLabelsCount(),
                minRotation:0,
                maxRotation:screen.width < 740?50:0,
                backdropColor: 'red',
              }
            },
            A: {
              beginAtZero: false,
              type: 'linear',
              position: 'left',
              min: 0,
              max: this.getHighestValue(),
              // max: this.eventSalesGivebackObjectToPass.mode === 'Event Count' ? this.getHighestValue() : null,
              title: {
                color: 'red',
                display: true,
                text: this.generateYAxisLabel(this.eventSalesGivebackObjectToPass.mode),
                font: {
                  family: 'Montserrat',
                  size: 15,
                  weight: '500',
                },
                padding: 15
              },
              grid: {
                borderColor: 'black',
                borderWidth: 2,
              },
              ticks: {
                font: {
                  family: 'Montserrat',
                },
                backdropColor: 'green',
                stepSize: this.eventSalesGivebackObjectToPass.mode === 'Event Count' ? 1 : 0,
              }
            },
          },
          plugins: {
            legend: {
              position: 'bottom',
              align: 'center',
              labels: {
                usePointStyle: true
              }
            },
            tooltip: {
              enabled: true,
              callbacks: {
                title: (context) => {
                  return `${context[0].label}`;
                },
                label: (context) => {
                  if (this.eventSalesGivebackObjectToPass.mode === 'Event Count') {
                    return `Event Count: ${context.formattedValue}`;
                  } else if (this.eventSalesGivebackObjectToPass.mode === 'Total Sales') {
                    return `Total Sales: $${context.formattedValue}`;
                  } else if (this.eventSalesGivebackObjectToPass.mode === 'Sales Tax') {
                    return `Sales Tax: $${context.formattedValue}`;
                  } else {
                    return `Giveback Amount: $${context.formattedValue}`;
                  }
                }
              }
            }
          },
          
          layout: {
            autoPadding: true
          },
          events: ['mousemove', 'mouseout', 'click'],
          interaction: {
            mode: 'x',
            axis: 'x'
          },
          onClick : function(e, dataset, chart) {
            if (e.type === 'click' && dataset[0] != undefined) {
              const index = dataset[0].index;
              document.getElementById('current-value')!.textContent = 'US$' + chart.data.datasets[0].data[index].y!.toString();
              // document.getElementById('compared-value')!.textContent = 'US$' + chart.data.datasets[1].data[index]!.toString();
            }
          },
          onHover : (e, dataset, chart) => {
            const that = this;
            if (e.type === 'mousemove' && dataset[0] != undefined) {
              const index = dataset[0].index;
              // this.actualValue =
              //    this.generateUnit()+chart.data.datasets[0].data[index].y!.toString();
              // this.comparedValue =
              // chart.data.datasets[1].data[index] ? (this.generateUnit()+chart.data.datasets[1].data[index]!.toString()):'--';
            }
          }
        },
      });
    }

    getHighestValue() {
      // replace this with actual array
      let roundedMax= 10;
      let derivedDataSet = [10,20];
      // console.log(this.eventSalesGivebackObjectToPass.eventSalesDataSet1)
      if(this.eventSalesGivebackObjectToPass && this.eventSalesGivebackObjectToPass.eventSalesDataSet1.length > 0){
        derivedDataSet = this.eventSalesGivebackObjectToPass.eventSalesDataSet1.map(element => element.y);
        // console.log(derivedDataSet);
        const max = Math.max(...derivedDataSet, ...this.eventSalesGivebackObjectToPass.eventSalesDataSet2);
        const digit = Math.floor(Math.log10(max)) + 1;
        let multiplier;
        if (digit === 1) {
          return Math.ceil(max) + 1;
        } else if (digit === 2) {
        multiplier = 1;
      } else if (digit > 2) {
        const str = "1" + "0".repeat(digit - 2);
        multiplier = Number(str);
      }
      multiplier = multiplier * 5;
      // console.log(`Multiplier: ${multiplier}`);
      roundedMax = Math.round((max) / multiplier) * multiplier;
      if (roundedMax <= max) {
        roundedMax = roundedMax + multiplier;
      }
      // console.log('Rounded: Max' + roundedMax);
    }
    return roundedMax;
  }

    getLabelsCount() {
      return (this.eventSalesGivebackObjectToPass.eventSalesDataSet1.length > 30)? 10: '';
    }

    ngOnDestroy() {
      this.barChart.destroy();
    }

    generateYAxisLabel(mode) {
      if (mode === "Event Count") {
        return mode;
      } else {
        return mode + " ( in USD )"
      }
    }

    generateUnit(mode?) {
      const selectedMode = mode?mode:this.eventSalesGivebackObjectToPass.mode;
      if (selectedMode === "Event Count") {
        return '';
      } else {
        return  'US$';
      }
    }

    // getSetLastValues() {
    //   let set1 = this.eventSalesGivebackObjectToPass.eventSalesDataSet1;
    //   let set2 = this.eventSalesGivebackObjectToPass.eventSalesDataSet2;
    //   // this.actualValue = set1[set1.length -1].y;
    //   // this.comparedValue = set2[set2.length -1];
    // }
  }

  export const FILL_COLORS = {
    blue: 'rgb(25, 138, 215, 0.7)',
    yellow: 'rgb(247, 212, 32, 0.7)',
    green: 'rgb(77, 179, 96, 0.7)',
  };
  
  export const BORDER_COLORS = {
    blue: 'rgb(25, 138, 215, 1)',
    yellow: 'rgb(247, 212, 32, 1)',
    green: 'rgb(77, 179, 96, 1)',
  };
  
