import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RestApiService } from './rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class V2Service {

  constructor(private restApiService: RestApiService) { }

  getCustomerList(data): Observable<any> {
    return this.restApiService.put('getCustomers', '/secure/customers/admin/grid-data',
      data, 'page-center', 'useV2');
  }

  getDashboardInfo(url): Observable<any> {
    return this.restApiService.get('getDashboard', `${url}`, 'page-center', 'useV2');
  }

  generateReport(exportCompleteUrl, data?): Observable<any> {
    return this.restApiService.post('generateReport', `${exportCompleteUrl}`, data, 'page-center', 'useV2');
  }

}
