<div class="footer">
    <div class="footer-data" *ngIf="isDisplayFooter">
        <li class="footer-menu">
            <a href="javascript:void(0)" class="footer-link" (click)="showTermsDialog()"
                translate>label_terms_agreement</a>
        </li>
        <li class="footer-menu">
            <a href="javascript:void(0)" class="footer-link" (click)="showPrivacyDialog()"
                translate>label_privacy_policy</a>
        </li>
        <!-- <li class="footer-menu">
            <a href="javascript:void(0)" class="footer-link" (click)="showCookiesDialog()" translate>label_cookies</a>
        </li> -->
    </div>
</div>

<!--[header]="'label_terms_agreement' | translate"-->
<p-dialog   [dismissableMask]="false"
    [modal] = "true"[(visible)]="termsDialog" styleClass="footer-dialog" dismissableMask="true" [draggable]="false"
    blockScroll="true" [resizable]="false" [focusOnShow]="false">
    <app-footer-terms-content (openPrivacy)="showPrivacyDialog()"></app-footer-terms-content>
</p-dialog>



<!-- Privacy policy dialog start
    [header]="'label_privacy_policy' | translate" -->
<p-dialog   [dismissableMask]="false"
    [modal] = "true"[(visible)]="privacyDialog" styleClass="footer-dialog" dismissableMask="true" [draggable]="false"
    blockScroll="true" [resizable]="false" [focusOnShow]="false">
    <app-footer-privacy-content (openTerms)="showTermsDialog()" (openCcpa)="showCcpaDialog()">
    </app-footer-privacy-content>
</p-dialog>

<!-- CCPA Privacy policy -->
<p-dialog   [dismissableMask]="false"
    [modal] = "true" [(visible)]="ccpaDialog" styleClass="footer-dialog ccpa-dialog"
    dismissableMask="true" [draggable]="false" blockScroll="true" [resizable]="false" [focusOnShow]="false">
    <app-footer-ccpa-content></app-footer-ccpa-content>
</p-dialog>

<!-- <p-dialog   [dismissableMask]="false"
    [modal] = "true"[header]="'label_cookies' | translate" [(visible)]="cookiesDialog" styleClass="footer-dialog"
    dismissableMask="true" [draggable]="false" blockScroll="true" [resizable]="false">
    <app-footer-cookies-content (openCookies)="showCookiesDialog()"></app-footer-cookies-content>
</p-dialog> -->
