import { NavigationEnd, Router } from '@angular/router';

import { Component } from '@angular/core';
import { UserCommonService } from './modules/shared/services/user-common.service';
declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  constructor(
    public userCommonService: UserCommonService,
    public router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'xx-xxxxx-xx',
          {
            'page_path': event.urlAfterRedirects
          }
        );
      }
    }
    )
  }
}
