import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { AppSettings } from './../../modules/shared/app.settings';
import { UserCommonService } from './../../modules/shared/services/user-common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { StorageService } from './../../modules/shared/services/storage.service';
import { CommonMessageTransferService } from '../../modules/shared/services/common-message-transfer.service';

import { Message } from 'primeng/api';
import { ErrorResponseDto } from '../../modules/shared/models/error-response-dto';
import { ErrorDto } from '../../modules/shared/models/error-dto';
import { LocationStrategy } from '@angular/common';
import { CommonBindingDataService } from '../../modules/shared/services/common-binding-data.service';
import { UiService } from '../../modules/shared/services/ui.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
})
export class SignInComponent implements OnInit, OnDestroy {
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  label_password_inline = 'Password';
  label_Email_inline = 'Email';
  signInResponse: any;
  message: Message[] = [];
  otpId;
  loginData;
  forgotPopup = false;
  franchisePopup = false;
  loginProfilePopup = false;
  loginErrors = {
    username: '',
    password: {
      hasError: false,
      error: [],
    },
    general: {
      hasError: false,
      error: [],
    },
  };
  subscription: Subscription;
  isSubmitted = false;
  otpPopup = false;
  setPasswordPopup = false;
  termsPopup = false;
  termsConditionAccepted = false;

  otpEmail;
  otpPhone;
  passwordType = 'password';
  signInObject = {
    email: '',
    password: '',
    deviceId: '',
    deviceType: '',
    deviceModel: '',
    os: '',
    osVersion: '',
    appVersion: '',
    deviceName: '',
    termsConditionAccepted: false,
  };
  staffLogin = false;
  franchiseOptions = [];
  loginAs;
  selectedFranchise;


  signInForm = new UntypedFormGroup({
    username: new UntypedFormControl(),
    password: new UntypedFormControl(),
  });

  restAPIFieldSubscription: any;
  restAPIGeneralSubscription: any;
  updatedVersion: any;

  accessPermissionArray = []
  loginAsFranchiseAdmin: boolean;


  constructor(
    private formBuilder: UntypedFormBuilder,
    private userCommonService: UserCommonService,
    private router: Router,
    private storageService: StorageService,
    private commonMessageTransferService: CommonMessageTransferService,
    private location: LocationStrategy,
    private commonService: CommonBindingDataService,
    private route: ActivatedRoute,
    private uiService: UiService
  ) {
    this.clearStorageAndSession();
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  ngOnInit() {
    this.termsConditionAccepted = false;
    this.loginData = this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA);
    const apiToken = this.storageService.getItem(AppSettings.TOKEN_KEY);
    // if (apiToken !== undefined && apiToken !== null){
    //   const data = JSON.parse(this.loginData);
    //   this.redirectToDashboard(data);
    // }
    if (this.router.url.includes('staff-signin')) {
      this.staffLogin = true;
    }
    this.signInForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      username: ['', [Validators.required, Validators.pattern(AppSettings.EMAIL_PATTERN)]],
    });
    this.restAPIFieldSubscription = this.commonMessageTransferService.restAPIFieldErrorEvent.subscribe(
      (error: ErrorResponseDto) => {
        console.log(error);
      }
    );

    this.restAPIGeneralSubscription = this.commonMessageTransferService.restAPIGeneralErrorEvent.subscribe(
      (error: ErrorDto) => {
        this.onAPIValidation(error);
      }
    );

    setTimeout(() => {
      this.userCommonService.checkVersion().subscribe((result) => {
        if (result !== undefined) {
          this.updatedVersion = result.versionsDto.version;
          // console.log('updated version', this.updatedVersion);
          // this.updatedVersion = "1.1.9";
          this.storageService.setItem('latestVersion', this.updatedVersion);
        }
      }, (error) => {
        console.log(error);
      });
    }, 2000);
  }

  onAPIValidation(error) {
    if (error.general) {
      this.loginErrors.general.hasError = true;
      this.loginErrors.general.error = error.general;
    } else if (error.password) {
      this.loginErrors.password.hasError = true;
      this.loginErrors.password.error = error.required.password;
    } else {
      this.loginErrors.general.hasError = true;
      this.loginErrors.general.error = error.message;
    }
    setTimeout(() => {
      this.loginErrors.general.hasError = false;
      this.loginErrors.general.error = [];
    }, 4000);
  }

  doSignIn() {
    if (this.signInForm.valid) {
      // this.clearStorageAndSession();
      this.signInObject.email = this.signInForm.controls.username.value.toLowerCase().trim();
      this.signInObject.password = this.userCommonService.getEncryptPass(this.signInForm.controls.password.value);
      this.signInObject.termsConditionAccepted = this.termsConditionAccepted;

      const signInData = this.signInForm.value;
      this.signInApiCall(signInData);
    } else {
      this.signInForm.markAllAsTouched();
    }
  }

  

  clearStorageAndSession() {
    this.storageService.removeItem(AppSettings.USER_DETAILS_EMAIL);
    this.storageService.removeItem(AppSettings.USER_DETAILS_NAME);
    this.storageService.removeItem(AppSettings.USER_DETAILS);
    this.storageService.removeItem(AppSettings.TOKEN_KEY);
    this.storageService.removeItem('permissionArray')
    this.storageService.removeAll();
    this.storageService.removeAllCookies();
    this.storageService.removeItem('latestVersion');
    sessionStorage.clear();
  }

  onClickOptions(event) {
    // console.log('event', event, 'login as', this.loginAs);
  }

  signInApiCall(signInData) {
    this.userCommonService
      .signIn('ddd', this.signInObject)
      .subscribe((signInResult) => {
        if (signInResult !== undefined) {
          // this.termsConditionAccepted = signInResult.termsConditionAccepted;
          this.signInResponse = signInResult;
          // console.log('length', signInResult.length)
          if (signInResult?.length > 1) {
            this.franchiseOptions = signInResult.map(element => ({ label: element.franchiseName, value: element.id }));
            this.franchiseOptions.unshift({ label: this.commonService.getLabel('Select'), value: '', inactive: false });
            // console.log(signInResult);
            this.franchisePopup = true;
          } else if (signInResult.roles
            && signInResult.roles[0].roleCode == AppSettings.USER_ROLE_ADMIN) {
            this.setSessionAndUserDetails(signInResult);
          } else if (signInResult.roles
            && signInResult.roles[0].roleCode == AppSettings.STAFF_ROLES.Manager) {
            this.loginProfilePopup = true;
          } else {
            if (signInResult.roles
              && signInResult.roles[0].roleCode == AppSettings.USER_ROLE_FRANCHISE) {
              if ((this.termsConditionAccepted || signInResult.termsConditionAccepted)) {
                this.setSessionAndUserDetails(signInResult);
              } else {
                this.termsPopup = true;
              }
            } else if (signInResult.roles
              && (signInResult.roles[0].roleCode == AppSettings.STAFF_ROLES.Driver
                || signInResult.roles[0].roleCode == AppSettings.STAFF_ROLES.Server
                || signInResult.roles[0].roleCode == AppSettings.STAFF_ROLES.Worker)) {
              this.setSessionAndUserDetails(signInResult);
            } else if (!signInResult.roles || !this.termsConditionAccepted) {
              this.termsPopup = true;
            } else { // for all other than franchise and staff
              this.setSessionAndUserDetails(signInResult);
            }
          }
        }
      }, (error) => {
        console.log(error);
      });
  }

  setSessionDetails(results) {
    // console.log(results.sessionKey)
    const userFullName = `${results.firstName} ${results.lastName}`;
    this.storageService.setItem(AppSettings.TOKEN_KEY, results.sessionKey);
    this.storageService.setItem(AppSettings.USER_DETAILS, results.user);
    this.storageService.setItem(AppSettings.USER_DETAILS_NAME, userFullName);
    this.storageService.setItem(AppSettings.USER_DETAILS_EMAIL, results.email);
    this.storageService.setItem(AppSettings.USER_DETAILS_USER_PROFILE, ((results && results.files) ? results.files.signUrl : ''));
    this.storageService.setItem(AppSettings.USER_DETAILS_LOGIN_DATA, results);
    this.storageService.setItem(AppSettings.USER_DETAILS_LOGIN_USER_ID, results.id);

    this.storageService.setItem('FRANCHISE_ID', results.franchiseId);
    this.storageService.removeItem(AppSettings.USER_DETAILS_PROFILE_TIMEZONE);
    
    this.storageService.setItem(AppSettings.USER_DETAILS_PROFILE_TIMEZONE, results.franchiseDefaultTimezone?results.franchiseDefaultTimezone:this.timeZone);
    // this.storageService.setItem(AppSettings.USER_DETAILS_PROFILE_TIMEZONE, 'Asia/Calcutta');
    // this.storageService.setItem(AppSettings.USER_DETAILS_PROFILE_TIMEZONE, 'Pacific/Honolulu');
    
    // AppSettings.USER = results.user;
  }

  setSessionAndUserDetails(results) {
    this.setSessionDetails(results);
    this.createPermissionArray(results)
    this.redirectToDashboard(results);
  }

  createPermissionArray(data) {
    if (data.accessGroupPermissionsInput) { // also add manager role check
      const accessArray = data.accessGroupPermissionsInput.accessGroupPermissionsDtoList
        .map(eachAccess => ({
          accessCode: eachAccess.accessGroupCode,
          readonly: eachAccess.read,
          readWrite: eachAccess.readWrite
        }));
      this.storageService.setItem('permissionArray', accessArray);
    }
  }

  redirectToDashboard(data) {
    if (data.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE) {
      if (JSON.parse(this.storageService.getItem(AppSettings.DOWNLOAD_ID))) {
        this.router.navigate(['/file/export/' + JSON.parse(this.storageService.getItem(AppSettings.DOWNLOAD_ID))]);
      } else {
        let displayType = AppSettings.EVENT_DISPLAY_TYPE_VALUES.MONTH;
        if (screen.width < 740) {
          displayType = AppSettings.EVENT_DISPLAY_TYPE_VALUES.LIST;
        }
        this.router.navigate(['/franchise/events/list']);
      }
    } else if (data.roles[0].roleCode === AppSettings.USER_ROLE_ADMIN) {
      if (JSON.parse(this.storageService.getItem(AppSettings.DOWNLOAD_ID))) {
        this.router.navigate(['/file/export/' + JSON.parse(this.storageService.getItem(AppSettings.DOWNLOAD_ID))]);
      } else {
        this.router.navigate(['/admin/franchise']);
      }
    } else if (data.roles[0].roleCode == AppSettings.STAFF_ROLES['Manager'] && this.loginAs === 'franchise') {
      this.loginProfilePopup = false;
      let displayType = AppSettings.EVENT_DISPLAY_TYPE_VALUES.MONTH;
      if (screen.width < 740) {
        displayType = AppSettings.EVENT_DISPLAY_TYPE_VALUES.LIST;
      }
      this.storageService.setItem('staffAsManager', 'true');
      // if (this.commonService.sidebarItems) { //** for manager loads firs */
      //   for (let index = 0; index < this.commonService.sidebarItems.length; index++) {
      //     const element = this.commonService.sidebarItems[index];
      //     if (this.commonService.checkIfHavePermission(element.title, true)) {
      //       this.router.navigate(element.routerLink);
      //       break;
      //     }
      //   }
      // } else {
      //   this.router.navigate(['/franchise/events/list']);
      // }
      this.router.navigate(['/franchise/events/list']);

    } else if (data.roles[0].roleCode == AppSettings.STAFF_ROLES['Driver']
      || data.roles[0].roleCode == AppSettings.STAFF_ROLES['Manager']
      || data.roles[0].roleCode == AppSettings.STAFF_ROLES['Server']
      || data.roles[0].roleCode == AppSettings.STAFF_ROLES['Worker']) {
      // this.router.navigate(['/staff/dashboard']);
      this.router.navigate(['/staff/event-schedules']);
    }
    // console.log(data.roles[0].roleCode);
    // console.log(AppSettings.STAFF_ROLES['Manager']);
  }

  uniqArray(arrArg) {
    return arrArg.filter((elem, pos, arr) => {
      return arr.indexOf(elem) === pos;
    });
  }

  // commented doSignIn() function from login button  for static UI and used this function for popup open
  selectFranchise() {
    this.loginProfilePopup = false;
    this.franchisePopup = true;
  }

  onCancelClick(e) {
    // close popup
    this.selectedFranchise = ''; // after cancle preselected set as default select
    this.loginProfilePopup = false;
    this.franchisePopup = false;

  }

  onFranchiseSelect() {
    this.storageService.setItem('staffAsManager', 'false');
    // write code for selected franchise 
    // this.franchisePopup = false;
    if (this.selectedFranchise && this.franchisePopup) {  /** check for franchise selection popup */
      // console.log('franchise pop')
      const signInData = {
        'email': this.signInObject.email,
        'password': this.signInObject.password,
        'userId': this.selectedFranchise,
      }

      this.userCommonService
        .signIn('signin', signInData)
        .subscribe((signInResult) => {
          if (signInResult !== undefined) {
            this.signInResponse = signInResult;
            this.franchisePopup = false;
            if (signInResult.roles
              && signInResult.roles[0].roleCode == AppSettings.STAFF_ROLES['Manager']) {
              // console.log('1st');
              this.termsConditionAccepted = signInResult.termsConditionAccepted;
              if (signInResult.termsConditionAccepted) {
                if (!this.loginAs) {
                  this.loginProfilePopup = true;
                } else {
                  this.setSessionAndUserDetails(this.signInResponse);
                  this.checkSessionRole(this.signInResponse);
                }
              } else {
                this.loginProfilePopup = true;
              }
            } else {
              // console.log('2nd');
              // if (!signInResult.termsConditionAccepted) {
              //   this.loginProfilePopup = true;
              // } else {
              //   this.setSessionDetails(signInResult);
              //   this.router.navigate(['/staff/event-schedules']);
              // }
              this.setSessionDetails(signInResult);
              this.router.navigate(['/staff/event-schedules']);
            }
          }
        }, (error) => {
          console.log(error);
        });
    } else {
      this.uiService.showMessage('Please Select Franchise.', AppSettings.MESSAGE_TYPE.ERROR)
    }
  }

  onConfirmClick(as?) {
    // console.log('login as1', this.loginAs);
    this.storageService.setItem('staffAsManager', 'false');
    // write code for selected franchise 
    // this.franchisePopup = false;
    if (as == 'loginAs' || this.loginAs == 'staff' || this.loginAs == 'franchise') {  /** check for franchise selection popup */
      const signInData = {
        'email': this.signInObject.email,
        'password': this.signInObject.password,
        'userId': this.selectedFranchise,
      }
      if (this.loginAs == 'franchise') {
        signInData['termsConditionAccepted'] = this.termsConditionAccepted;
        signInData['loginAsStaff'] = false;
      } else if (this.loginAs == 'staff') {
        signInData['loginAsStaff'] = true;
      }

      this.userCommonService
        .signIn('ddd', signInData)
        .subscribe((signInResult) => {
          if (signInResult !== undefined) {
            this.signInResponse = signInResult;
            if (signInResult.roles
              && signInResult.roles[0].roleCode == AppSettings.STAFF_ROLES['Manager']) {
              this.termsConditionAccepted = signInResult.termsConditionAccepted;
              if (as && as == 'loginAs' || this.termsConditionAccepted) {
                this.setSessionAndUserDetails(this.signInResponse);
                this.checkSessionRole(this.signInResponse);
              } else {
                if (this.loginAs == 'staff' && !signInResult.termsConditionAccepted) {
                  this.setSessionAndUserDetails(this.signInResponse);
                  this.checkSessionRole(this.signInResponse);
                } else {
                  this.termsPopup = true;
                }
              }
            } else {
              if (this.loginAs == 'franchise' && !signInResult.termsConditionAccepted) {
                this.termsPopup = true;
              } else { //** login as staff */
                this.setSessionDetails(signInResult);
                this.router.navigate(['/staff/event-schedules']);
              }
            }
          }
        }, (error) => {
          console.log(error);
        });
    } else {
      if (!this.franchisePopup) {
        this.uiService.showMessage('Please Select Login as Franchise Admin or Staff.', AppSettings.MESSAGE_TYPE.ERROR)
      } else {
        this.uiService.showMessage('Please Select Franchise.', AppSettings.MESSAGE_TYPE.ERROR)
      }
    }
  }

  checkSessionRole(signInResponse,) {
    if (this.loginAs == 'franchise') {
      this.loginAsFranchiseAdmin = true;
    } else {
      this.loginAsFranchiseAdmin = false;
    }
    const obj = {
      'id': signInResponse.sessionKey,
      'loginAsFranchiseAdmin': this.loginAsFranchiseAdmin,
    }
    this.userCommonService.sessionRole(obj).subscribe((result) => {
      if (result !== undefined) {
        // console.log(result);
      }
    }, (error) => {
      console.log(error);
    });
  }

  acceptTerms() {
    this.termsPopup = false;
    this.termsConditionAccepted = true;
    if (this.loginAs) {
      this.onConfirmClick('loginAs');
    } else {
      this.doSignIn();
    }
  }

  rejectTerms() {
    this.termsPopup = false;
  }

  forgotPassword() {
    this.forgotPopup = true;
  }

  onPopSave(event) {
    this.forgotPopup = false;
  }

  onPopClose(event) {
    this.forgotPopup = false;
    // this.setPasswordPopup = true;
  }

  onOtpPopClose(event) {
    this.otpPopup = false;
    this.setPasswordPopup = true;
  }

  onSetPasswordPopClose(event) {
    this.setPasswordPopup = false;
  }

  eventHandler(event) {
    if (event.keyCode === 13) {
      this.doSignIn();
    }
  }

  tooglePasswordType() {
    if (this.passwordType === 'password') {
      this.passwordType = 'text';
    } else {
      this.passwordType = 'password';
    }
  }


  ngOnDestroy() {
    this.restAPIFieldSubscription.unsubscribe();
    this.restAPIGeneralSubscription.unsubscribe();
  }
}
