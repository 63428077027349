import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-chart-staff-overview',
  templateUrl: './chart-staff-overview.component.html',
  styleUrls: ['./chart-staff-overview.component.scss']
})

export class ChartStaffOverviewComponent implements OnInit, AfterViewInit, OnChanges {

    @ViewChild('barCanvas') barCanvas: ElementRef | undefined;
    barChart: any;
    actualValue = '';
    comparedValue = '';
    MAX_POINTS_ON_X_AXIS = 5;

    @Input() inputDataObject;
    
    constructor() { }
  
    ngOnInit(): void {
      this.getSetLastValues();
      if (document.getElementsByTagName('body')[0].classList.contains('removeAllPadding')) {
        document.getElementsByTagName('body')[0].classList.remove('removeAllPadding');
      }
    }
  
    ngAfterViewInit(): void {
      this.barChartMethod();
    }
    ngOnChanges() {
      this.barChartMethod();
    }
  
    barChartMethod() {
      this.barChart = new Chart(this.barCanvas?.nativeElement, {
        type: 'bar',
        data: {
          labels: this.inputDataObject.labels,
          datasets: [{
            label: 'Sales',
            yAxisID: 'A',
            data: this.inputDataObject.eventSalesDataSet1,
            backgroundColor: FILL_COLORS.blue,
            borderColor: BORDER_COLORS.blue,
            borderWidth: 1,
            // barThickness: 30,
            maxBarThickness: 40,
          }, {
            label: 'Hours',
            yAxisID: 'B',
            data: this.inputDataObject.eventSalesDataSet2,
            backgroundColor: FILL_COLORS.red,
            borderColor: BORDER_COLORS.red,
            borderWidth: 1,
            // barThickness: 30,
            maxBarThickness: 40,
          },
          {
            label: 'Compared Sales',
            yAxisID: 'A',
            data: this.inputDataObject.eventSalesDataSet3,
            backgroundColor: FILL_COLORS.blueLight,
            borderColor: BORDER_COLORS.blue,
            borderWidth: 1,
            barThickness: 30,
            maxBarThickness: 40,
          }, {
            label: 'Compared Hours',
            yAxisID: 'B',
            data: this.inputDataObject.eventSalesDataSet4,
            backgroundColor: FILL_COLORS.redLight,
            borderColor: BORDER_COLORS.red,
            borderWidth: 1,
            barThickness: 30,
            maxBarThickness: 40,
          },
        ]},
        options: {
          scales: {
            x: {
              min: 0,
              max: this.MAX_POINTS_ON_X_AXIS,
              title: {
                color: 'red',
                display: true,
                text: 'Duration',
                font: {
                  family: 'Montserrat',
                  size: 15,
                  weight: '500',
                },
                padding: 15,
              },
              grid: {
                display: false,
                offset: false,
                borderColor: 'black',
                borderWidth: 2
              },
              ticks: {
                font: {
                  family: 'Montserrat',
                },
              }
            },
            A: {
              beginAtZero: true,
              type: 'linear',
              position: 'left',
              title: {
                color: 'red',
                display: true,
                text: 'Sales ( in USD )',
                font: {
                  family: 'Montserrat',
                  size: 15,
                  weight: '500',
                },
                padding: 15
              },
              grid: {
                borderColor: 'black',
                borderWidth: 2,
              },
              ticks: {
                font: {
                  family: 'Montserrat',
                },
                padding: 10,
              }
            },
            B: {
              beginAtZero: true,
              type: 'linear',
              position: 'right',
              max: this.getHighestValue(),
              title: {
                color: 'red',
                display: true,
                text: 'Hours',
                font: {
                  family: 'Montserrat',
                  size: 15,
                  weight: '500',
                },
                padding: 15
              },
              grid: {
                borderColor: 'black',
                borderWidth: 2,
              },
              ticks: {
                font: {
                  family: 'Montserrat',
                },
                // stepSize: 1
              }
            },
          },
          plugins: {
            legend: {
              position: 'bottom',
              align: 'center',
              labels: {
                usePointStyle: true
              }
            },
            tooltip: {
              yAlign: 'bottom',
              titleFont: {
                font: {
                  family: 'Montserrat',
                  // size: 16,
                  weight: 'bold',
                },
              },
              titleMarginBottom: -6,
              bodySpacing: 6,
              bodyFont: {
                font: {
                  family: 'Montserrat',
                },
              },
              padding: 12,
              callbacks: {
                title: (context) => {
                  return 'Date: ' + context[0].label;
                },
                beforeBody: (context) => {
                  return '_'.repeat(20);
                },
                label: (context) => {
                  // console.log(context);
                  const label = [];
                  const perHrs = this.findPerHrs(context.dataIndex, context.datasetIndex);
                  const currentCompareStr = (context.datasetIndex == 0 || context.datasetIndex == 1) ? '' : 'Compared';
                  const firstLabel = context.dataset.yAxisID === 'A' ? 'Sales':'Shift Hours';
                  const _$ = context.dataset.yAxisID === 'A' ? '$':'';
                  const _hrs = context.dataset.yAxisID === 'A' ? '':'hrs';
                  label.push( `${firstLabel}: ${_$}${context.formattedValue} ${_hrs}`);
                  label.push(`Sales per hour: $${perHrs}`);
                  return label;
                }
              }
            }
          },
          layout: {
            autoPadding: true
          },
          events: ['mousemove', 'mouseout', 'click'],
          interaction: {
            mode: 'x',
            axis: 'x'
          },
          onClick: function (e, dataset, chart) {
            if (e.type === 'click' && dataset[0] != undefined) {
              const index = dataset[0].index;
              document.getElementById('current-value')!.textContent = 'US$' + chart.data.datasets[0].data[index]!.toString();
              document.getElementById('compared-value')!.textContent = 'US$' + chart.data.datasets[1].data[index]!.toString();
            }
          },
          onHover: (e, dataset, chart) => {
            if (e.type === 'mousemove' && dataset[0] != undefined) {
              const index = dataset[0].index;
              // document.getElementById('current-value')!.textContent = 'US$' + chart.data.datasets[0].data[index]!.toString();
              // document.getElementById('compared-value')!.textContent = 'US$' + chart.data.datasets[1].data[index]!.toString();
              this.actualValue =
                this.generateUnit() + chart.data.datasets[0].data[index]!.toString();
              this.comparedValue =
                chart.data.datasets[1].data[index] ? (this.generateUnit() + chart.data.datasets[1].data[index]!.toString()) : '--';
            }
          }
        },
        plugins: [this.scrollChart]
      });
  
      // Arrow Click
      // This method scrolls the graph on clicking the left or right arrow buttons.
      const moveScroll = () => {
        const { ctx, canvas, chartArea: { left, right, top, bottom, width, height } } = this.barChart;
        canvas.addEventListener('click', (event: any) => {
          const rect = canvas.getBoundingClientRect();
          const x = event.clientX - rect.left;
          const y = event.clientY - rect.top;
  
          if (x >= left - 15 && x <= left + 15 && y >= height / 2 + top - 15 && y <= height / 2 + top + 15) {
            this.barChart.options.scales.x.min = this.barChart.options.scales.x.min - this.MAX_POINTS_ON_X_AXIS;
            this.barChart.options.scales.x.max = this.barChart.options.scales.x.max - this.MAX_POINTS_ON_X_AXIS;
  
            if (this.barChart.options.scales.x.min <= 0) {
              this.barChart.options.scales.x.min = 0;
              this.barChart.options.scales.x.max = this.MAX_POINTS_ON_X_AXIS;            
            }
          }
  
          if (x >= right - 15 && x <= right + 15 && y >= height / 2 + top - 15 && y <= height / 2 + top + 15) {
            this.barChart.options.scales.x.min = this.barChart.options.scales.x.min + this.MAX_POINTS_ON_X_AXIS;
            this.barChart.options.scales.x.max = this.barChart.options.scales.x.max + this.MAX_POINTS_ON_X_AXIS;
  
            if (this.barChart.options.scales.x.max >= this.barChart.data.datasets[0].data.length) {
              this.barChart.options.scales.x.min = this.barChart.data.datasets[0].data.length - this.MAX_POINTS_ON_X_AXIS;
              this.barChart.options.scales.x.max = this.barChart.data.datasets[0].data.length;            
            }
          }
          this.barChart.update();
        })
      }
  
      // Adding click event handler to the two arrow buttons.
      this.barChart.onClick = moveScroll();
  
      // Mouse Wheel
      // This method scrolls the graph on moving the mouse wheel.
      const scroller = (scroll: { deltaY: number; }, chart: any) => {
        // console.log(scroll);
  
        const dataLength = this.barChart.data.labels.length
        if (scroll.deltaY > 0) {
          if (this.barChart.config.options.scales.x.max >= dataLength) {
            this.barChart.config.options.scales.x.min = dataLength - this.MAX_POINTS_ON_X_AXIS;
            this.barChart.config.options.scales.x.max = dataLength;
          } else {
            this.barChart.config.options.scales.x.min += 1;
            this.barChart.config.options.scales.x.max += 1;
          }
        } else if (scroll.deltaY < 0) {
          if (this.barChart.config.options.scales.x.min <= 0) {
            this.barChart.config.options.scales.x.min = 0;
            this.barChart.config.options.scales.x.max = this.MAX_POINTS_ON_X_AXIS
          } else {
            this.barChart.config.options.scales.x.min -= 1;
            this.barChart.config.options.scales.x.max -= 1;
          }
        } else {
  
        }
        this.barChart.update();
      }
  
      // Adding wheel move event handler to the mouse wheel.
      this.barChart.canvas.addEventListener('wheel', (e: { deltaY: number; }) => {
        // console.log('wheel');
        scroller(e, this.barChart);
      })
    }
  
    scrollChart = {
      id: 'scrollChart',
      afterEvent(chart: { ctx: any; canvas: any; chartArea: { left: any; right: any; top: any; bottom: any; width: any; height: any; }; }, args: { event: { x: any; y: any; }; }) {
        const { ctx, canvas, chartArea: { left, right, top, bottom, width, height } } = chart;
        canvas.addEventListener('mousemove', (event: any) => {
          const x = args.event.x;
          const y = args.event.y;
  
          if (x >= left - 15 && x <= left + 15 && y >= height / 2 + top - 15 && y <= height / 2 + top + 15) {
            canvas.style.cursor = 'pointer';
          } else if (x >= right - 15 && x <= right + 15 && y >= height / 2 + top - 15 && y <= height / 2 + top + 15) {
            canvas.style.cursor = 'pointer';
          } else {
            canvas.style.cursor = 'default';
          }
        })
      },
      afterDraw(chart: { ctx: any; chartArea: { left: any; right: any; top: any; bottom: any; width: any; height: any; }; }, _args: any, options: any) {
        const { ctx, chartArea: { left, right, top, bottom, width, height } } = chart;
  
        class CircleChevron {
          draw(ctx: { beginPath: () => void; lineWidth: number; strokeStyle: string; fillStyle: string; arc: (arg0: any, arg1: any, arg2: number, arg3: number, arg4: number, arg5: boolean) => void; stroke: () => void; fill: () => void; closePath: () => void; moveTo: (arg0: any, arg1: number) => void; lineTo: (arg0: number, arg1: any) => void; }, x1: number, pixel: number) {
            const angle = Math.PI / 180;
  
            ctx.beginPath();
            ctx.lineWidth = 3;
            // ctx.strokeStyle = 'rgb(102, 102, 102, 0.5)';
            ctx.fillStyle = '#06A0FF';
            ctx.arc(x1, height / 2 + top, 15, angle * 0, angle * 360, false);
            ctx.stroke();
            ctx.fill();
            ctx.closePath();
      
            // chevron arrow left
            ctx.beginPath();
            ctx.lineWidth = 2;
            ctx.strokeStyle = 'white';
            ctx.moveTo(x1 + pixel, height / 2 + top - 7.5);
            ctx.lineTo(x1 - pixel, height / 2 + top);
            ctx.lineTo(x1 + pixel, height / 2 + top + 7.5);
            ctx.stroke();
            ctx.closePath();
          }
        }
  
        const drawCircleLeft = new CircleChevron();
        drawCircleLeft.draw(ctx, left, 5);
  
        const drawCircleRight = new CircleChevron();
        drawCircleRight.draw(ctx, right, -5);
      }
    }
  
    generateXAxisLabel(mode) {
      if (mode === "Event Count") {
        return mode;
      } else {
        return mode + " ( in USD )"
      }
    }
  
    generateUnit(mode?) {
      const selectedMode = mode ? mode : this.inputDataObject.mode;
      if (selectedMode === "Event Count") {
        return '';
      } else {
        return 'US$';
      }
    }
  
    getSetLastValues() {
      const set1 = this.inputDataObject.eventSalesDataSet1;
      const set2 = this.inputDataObject.eventSalesDataSet2;
      this.actualValue = set1[set1.length - 1];
      this.comparedValue = set2[set2.length - 1];
    }

    // getHighestValue() {
    //   let max = Math.max(...this.inputDataObject.eventSalesDataSet2, ...this.inputDataObject.eventSalesDataSet4);
    //   return Math.ceil(max) + 1;
    // }

    getHighestValue() {
      // replace this with actual array
      const max = Math.max(...this.inputDataObject.eventSalesDataSet2, ...this.inputDataObject.eventSalesDataSet4);
      const digit = Math.floor(Math.log10(max)) + 1;
      let multiplier;
      if (digit === 1) {
        return Math.ceil(max) + 1;
      } else if (digit === 2) {
        multiplier = 1;
      } else if (digit > 2) {
        const str = "1" + "0".repeat(digit - 2);
        multiplier = Number(str);
      }
      multiplier = multiplier * 5;
      console.log(`Multiplier: ${multiplier}`);
      let roundedMax = Math.round((max) / multiplier) * multiplier;
      if (roundedMax <= max) {
        roundedMax = roundedMax + multiplier;
      }
      console.log('Rounded: Max' + roundedMax);
      return roundedMax;
    }

    findPerHrs(index,setIndx) {
      let perHrs = '0';
      if(setIndx == 0 || setIndx == 1){
        if(this.inputDataObject['currentSalesPerHrs']) {
          perHrs = this.inputDataObject['currentSalesPerHrs'][index];
        }
      }
      if(setIndx == 2 || setIndx == 3){
        if(this.inputDataObject['comparedSalesPerHrs'] && this.inputDataObject['comparedSalesPerHrs'].length > 0) {
          perHrs = this.inputDataObject['comparedSalesPerHrs'][index];
        }
      }
      return perHrs;
    }
  
    ngOnDestroy() {
      this.barChart.destroy();
    }
  }
  
  export const FILL_COLORS = {
    red: 'rgb(255, 99, 132, 0.7)',
    white: 'rgb(255, 255, 255, 1)',
    blue: 'rgb(25, 138, 215, 0.7)',
    yellow: 'rgb(247, 212, 32, 0.7)',
    green: 'rgb(77, 179, 96, 0.7)',
    blueLight: 'rgb(25, 138, 215, 0.3)',
    redLight: 'rgb(255, 99, 132, 0.3)',
    yellowLight: 'rgb(247, 212, 32, 0.3)',
  };
  
  export const BORDER_COLORS = {
    blue: 'rgb(25, 138, 215, 1)',
    yellow: 'rgb(247, 212, 32, 1)',
    green: 'rgb(77, 179, 96, 1)',
    red: 'rgb(255, 99, 132, 1)',
  };
