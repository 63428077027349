<p><strong> KonaOS Terms of Service Agreement</strong></p>
<p>Welcome to the KonaOS site (hereinafter referred to as the "Site"). These Terms of Service together with Kona Ice
    Inc.&rsquo;s Privacy Statement (collectively the &ldquo;Terms&rdquo;) govern your use of this Site. Kona Ice Inc.
    (hereinafter referred to as the "Provider") may change the Terms from time to time. Your access to the Site in any
    way is evidence of your acceptance of the Terms and the enforcement of said Terms. Provider reserves the right, in
    its sole discretion, to change, modify, or otherwise alter these Terms at any time effective upon posting of the
    modified Terms on the Site. Please review the Terms periodically. Your continued use of the Site or any materials or
    services accessible through it, after such posting or notification means you accept the modifications. The use by
    you of any content or services accessible through the Site may be subject to your acceptance of separate agreements
    with Provider or third parties.</p>
<p><strong>License and Site Access.</strong> Provider grants you a limited license to use the Site. You may not download
    (other than page caching), or modify any portion of it, except with the express written or e-mailed consent of
    Provider. The license to use the Site does not include any resale or commercial use of the Site or its contents; or
    any derivative use of this Site or its contents; or any use of data mining, robots or similar data gathering and
    extraction tools or processes. You agree not to store in any form, distribute, transmit, display, reproduce, reverse
    engineer, decompile,disassemble,modify, create derivative works from, sell or otherwise exploit any of the content
    on this site for any commercial purpose. By using the Site, you warrant to Provider that you will not use the Site,
    or any of the content obtained from the Site, for any purpose that is unlawful or prohibited by these Terms.
    Provider does not grant any license or other authorization to any user of its trademarks, registered trademarks,
    service marks, or other copyrightable material or other intellectual property, by placing them on this Site. If you
    violate any of these Terms, your permission to use the Site automatically terminates.</p>
<p>In consideration of your use of the Site, you agree to: (a) provide true, accurate, current and complete information
    about yourself as prompted by the registration form, if applicable, and (b) to maintain and update this information
    to keep it true, accurate, current and complete. If any information provided by you is untrue, inaccurate, not
    current or incomplete, Provider has the right to terminate your account and refuse any and all current or future use
    of the Site. You agree not to resell or transfer the Site or use of or access to the Site.</p>
<p><strong>Electronic Communications.</strong> When you visit the Site or send e-mails to us, you are communicating with
    us electronically. You consent to receive communications from us electronically. We will communicate with you by
    e-mail or by posting notices on this site. You agree that all agreements, notices, disclosures and other
    communications that we provide to you electronically satisfy any legal requirement that such communication be in
    writing.</p>
<p>You are responsible for providing, at your expense, any access to the Internet and any required equipment.</p>
<p><strong>Conduct.</strong> By using the Site, you agree that you will not do any of the following:</p>
<ol type="a">
    <li>
        <p>Restrict or inhibit any other authorized user from using and enjoying the Site;</p>
    </li>
    <li>
        <p>Post or transmit any unlawful, fraudulent, libelous, defamatory, obscene, pornographic, profane, threatening,
            abusive, hateful, offensive, or otherwise objectionable information of any kind, including without
            limitation any transmissions constituting or encouraging conduct that would constitute a criminal offense,
            give rise to civil liability, or otherwise violate any local, state, national or foreign law, including
            without limitation the U.S. export control laws and regulations;</p>
    </li>
    <li>
        <p>Post or transmit any advertisements, solicitations, chain letters, pyramid schemes, investment opportunities
            or schemes or other unsolicited commercial communication (except as otherwise expressly permitted by
            Provider) or engage in spamming or flooding;</p>
    </li>
    <li>
        <p>Post or transmit any information or software which contains a virus, trojan horse, worm or other harmful
            component;</p>
    </li>
    <li>
        <p>Post, publish, transmit, reproduce, distribute or in any way exploit any information, software or other
            material obtained through the Site for commercial purposes (other than as expressly permitted by the
            Provider of such information, software or other material);</p>
    </li>
    <li>
        <p>Upload, post, publish, transmit, reproduce, or distribute in any way, information, software or other material
            obtained through the Site which is protected by copyright, or other proprietary right, or derivative works
            with respect thereto, without obtaining permission of the copyright owner or right holder.</p>
    </li>
    <li>
        <p>Use the Site for any purpose other than the operation of the franchised business;</p>
    </li>
    <li>
        <p>Use the Site in violation of the franchise agreement or franchise manual, as may be amended in
            Provider&rsquo;s sole discretion from time to time; or.</p>
    </li>
    <li>
        <p>Transfer or assign the license granted to you under the Terms without prior written consent of Provider.</p>
    </li>
</ol>
<p>Provider has no obligation to monitor the Site. However, you acknowledge and agree</p>
<p>that Provider has the right to monitor the Site electronically from time to time and to</p>
<p>disclose any information as necessary or appropriate to satisfy any law, regulation or</p>
<p>other governmental request, to operate the Site properly, or to protect itself or its</p>
<p>subscribers. Provider reserves the right to refuse to post or to remove any information</p>
<p>or materials, in whole or in part, that, in its sole discretion, are unacceptable,</p>
<p>undesirable, inappropriate or in violation of these Terms.</p>
<p><strong>Feedback.</strong> Provider may provide you with a mechanism to provide feedback, suggestions and ideas, if
    you choose, about its software and services. You agree that Provider may, in its sole discretion, use the feedback
    you provide in any way, including in future modifications of the Site, multimedia works and/or advertising and
    promotional materials relating thereto. You hereby grant Provider a perpetual, worldwide, fully transferable,
    irrevocable, royalty free license to use, reproduce, modify, create derivative works from, distribute and display
    the feedback in any manner and for any purpose.</p>
<p><strong>Data. </strong>Use of the Site constitutes your acknowledgement and agreement that all data within the Site
    is the exclusive property of the Provider. You have been granted the limited right to access and use the data solely
    for the operation of your franchised business and shall not use the Site or data for any other purpose without the
    prior written consent of Provider.</p>
<p>Provider shall take reasonable measures to maintain the confidentiality and integrity of the data. However, Provider
    shall not be liable for any loss, corruption, or unauthorized disclosure of the data, except where such loss or
    disclosure is caused by Provider's gross negligence or willful misconduct.</p>
<p><strong>Third Party Services.</strong> In connection with your use of the Site, you may be made aware of services,
    products, offers and promotions provided by third parties (&ldquo;Third Party Services&rdquo;), and not by Provider.
    If you decide to use Third Party Services, you are responsible for reviewing and understanding the terms and
    conditions governing any Third Party Services. You agree that the third party, and not Provider, is responsible for
    the performance of the Third Party Services.You agree that Provider has no responsibility for any loss or damages
    arising from or related to your use of Third Party Services.</p>
<p><strong>Disclaimer of Warranties.</strong> Your use of the Site, including any applets, software, and content
    contained therein, is entirely at your own risk. THE SITE IS PROVIDED &ldquo;AS IS,&rdquo; AND TO THE MAXIMUM EXTENT
    PERMITTED BY APPLICABLE LAW, PROVIDER, ITS AFFILIATES, LICENSORS, THIRD-PARTY CONTENT OR SERVICE PROVIDERS,
    DISTRIBUTORS, DEALERS AND SUPPLIERS (COLLECTIVELY &ldquo;SUPPLIERS&rdquo;) DISCLAIM ALL GUARANTEES AND WARRANTIES,
    WHETHER EXPRESS, IMPLIED, OR STATUTORY REGARDING THE SITE AND RELATED MATERIALS INCLUDING ANY WARRANTY OF FITNESS
    FOR A PARTICULAR PURPOSE, TITLE, MERCHANTABILITY AND NON-INFRINGEMENT. PROVIDER DOES NOT WARRANT OR GUARANTEE THE
    ACCURACY, RELIABILITY, COMPLETENESS, USEFULNESS, NON-INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS, OR QUALITY OF ANY
    CONTENT ON THE SITE, REGARDLESS OF WHO ORIGINATES THAT CONTENT. PROVIDER DOES NOT WARRANT THAT THE SITE IS SECURE,
    FREE FROM BUGS, VIRUSES, INTERRUPTION, ERRORS, THEFT OR DESTRUCTION OR THAT THE SITE WILL MEET YOUR REQUIREMENTS.
    YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOU BEAR ALL RISKS ASSOCIATED WITH USING OR RELYING ON THAT CONTENT.
    PROVIDER IS NOT LIABLE OR RESPONSIBLE IN ANY WAY FOR ANY CONTENT POSTED ON OR LINKED FROM THE SITE, INCLUDING, BUT
    NOT LIMITED TO, ANY ERRORS OR OMISSIONS IN CONTENT, OR FOR ANY LOSSES OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF
    THE USE OF OR RELIANCE ON ANY CONTENT.</p>
<p><strong>Limitation of Liability.</strong> THE ENTIRE CUMULATIVE LIABILITY OF PROVIDER AND ITS SUPPLIERS FOR ALL
    MATTERS ARISING FROM OR RELATING TO THESE TERMS SHALL BE LIMITED TO THE AMOUNT PAID BY THE CUSTOMER FOR THE RELATED
    SERVICES OR CONTENT PURCHASED FROM PROVIDER, ITS AUTHORIZED RESELLER OR ITS SERVICE PROVIDERS. TO THE MAXIMUM EXTENT
    PERMITTED BY APPLICABLE LAW, PROVIDER AND ITS SUPPLIERS SHALL NOT BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL,
    EXEMPLARY OR CONSEQUENTIAL DAMAGES OR FOR DAMAGES RELATING TO TELECOMMUNICATION FAILURES, LOSS, CORRUPTION, SECURITY
    OR THEFT OF DATA, VIRUSES, SPYWARE,OR THE LIKE, WHETHER BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT
    LIABILITY OR OTHERWISE, EVEN IF PROVIDER OR ITS SUPPLIERS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND
    EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. THE LIMITATIONS OF DAMAGES SET
    FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN PROVIDER AND YOU. PROVIDER WOULD NOT BE
    ABLE TO HAVE PROVIDED THE SERVICE WITHOUT SUCH LIMITATIONS.</p>
<p><strong>Termination and Amendment.</strong> Your privilege to use or access the Site may be terminated by Provider
    immediately and without notice if you fail to comply with any term or condition of the Terms. Upon such termination,
    you must immediately cease accessing or using the Site and agree not to re-register or otherwise make use of the
    Site. Furthermore, you acknowledge that Provider reserves the right to take action &mdash; technical, legal or
    otherwise &mdash; to block, nullify or deny your ability to access the Site. You understand that Provider may
    exercise this right in its sole discretion.</p>
<p>Provider reserves the right, in its sole discretion, at any time and from time to time to change, modify or
    discontinue, temporarily or permanently, the Site (or any part thereof). Provider shall not be liable to you or
    other third party for any such modification, suspension or discontinuance except as expressly provided herein.</p>
<p>You agree to defend, indemnify and hold Provider and its affiliates harmless from any and all claims, liabilities,
    costs and expenses, including reasonable attorneys&rsquo; fees, arising in any way from your use of the Site or the
    placement or transmission of any message, information, software or other materials through the Site by you or users
    of your account or related to any violation of these Terms by you or users of your account.</p>
<p><strong>Miscellaneous.</strong> Except as expressly set forth in these Terms, these Terms are a complete statement of
    the agreement between you and Provider, and set forth the entire liability of Provider and its Suppliers and your
    exclusive remedy with respect to your access and use of the Site. In the event of a conflict between these Terms and
    the Privacy Policy, these Terms shall prevail. The Suppliers, agents, distributors, dealers, and employees of
    Provider are not authorized to make modifications to the Terms, or to make any additional representations,
    commitments or warranties binding on Provider. Any waiver of the terms herein by Provider must be in a writing
    signed by an authorized officer of Provider and expressly referencing the applicable provisions of the Terms. If any
    provision of the Terms is invalid or unenforceable under applicable law, then it shall be changed and interpreted to
    accomplish the objectives of such provision to the greatest extent possible under applicable law, and the remaining
    provisions will continue in full force and effect. The Terms will be governed by Delaware law as applied to
    agreements entered into and to be performed entirely within Delaware, without regard to its choice of law or
    conflicts of law principles that would require application of law of a different jurisdiction, and applicable
    federal law. Headings are included for convenience only, and shall not be considered in interpreting these Terms.
    The Terms do not limit any rights that Provider may have under trade secret, trademark, copyright, patent or other
    laws.</p>
<p>You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or
    related to use of the Site or the Terms must be filed within one (1) year after such claim or cause of action arose
    or be forever barred.</p>