import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FranchiseService } from '../../../franchise/services/franchise-service.service';
import { AppSettings } from '../../app.settings';
import { StorageService } from '../../services/storage.service';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-export-report',
  templateUrl: './export-report.component.html',
  styleUrls: ['./export-report.component.scss']
})
export class ExportReportComponent implements OnInit {
  url: any;
  id: any;

  constructor(private router: Router,
    private storageService: StorageService,
    private route: ActivatedRoute,
    private franchiseService: FranchiseService,
    private uiService: UiService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    if (!this.storageService.getItem(AppSettings.TOKEN_KEY)) {
      this.storageService.setItem(AppSettings.DOWNLOAD_ID, this.id);
      this.router.navigate(['/signin']);
    } else {
      this.storageService.removeItem(AppSettings.DOWNLOAD_ID);
      if (this.id && this.storageService.getItem(AppSettings.TOKEN_KEY)) {
        this.franchiseService.downloadReport(this.id).subscribe((result) => {
          if (result) {
            this.downloadFile(result.signUrl);
          }
        }, error => {
          this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPE.ERROR);
        });
      } else {
        this.uiService.showMessage('Something wents wrong', AppSettings.MESSAGE_TYPE.ERROR);
        this.storageService.setItem(AppSettings.DOWNLOAD_ID, this.id);
        this.router.navigate(['/signin']);
      }
    }
  }

  downloadFile(url) {
    const current = new Date();
    const extension = url.substring(url.lastIndexOf('.') + 1).toLowerCase();
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = url;
    link.download = 'export_' + current.getTime() + '.' + extension;
    document.body.appendChild(link);
    link.click();
    this.storageService.removeItem(AppSettings.DOWNLOAD_ID);
    setTimeout(() => {
      link.remove();
      //window.close();
    }, 8000);
  }

}
