import { Directive, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appPreventSpecialCharacters]'
})
export class PreventSpecialCharactersDirective {

  // regexStr = '^[a-zA-Z0-9_\b ]*$';
  qoutes = `'"`;
  regexStr = '^[-a-zA-Z0-9_@.!*?,()^&' + this.qoutes + ' ]*$';
  @Input() isAlphaNumeric: boolean;

  constructor(private el: ElementRef) { }

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    return new RegExp(this.regexStr).test(event.key);
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    this.copyPasteValidate(event);
  }

  validateFields(event: ClipboardEvent) {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('text/plain').replace(/[^a-zA-Z0-9 ]/g, '');
    document.execCommand('insertHTML', false, pasteData);
  }

  copyPasteValidate(event: ClipboardEvent) {
    if (!new RegExp(this.regexStr).test(event.clipboardData.getData('text/plain'))) {
      event.preventDefault();
      return;
    }
    const pasteData = event.clipboardData.getData('text/plain').replace(this.regexStr, '');
    document.execCommand('paste', false, pasteData);
  }
}
