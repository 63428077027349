import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aggrement-terms-of-service',
  templateUrl: './aggrement-terms-of-service.component.html',
  styleUrls: ['./aggrement-terms-of-service.component.scss']
})
export class AggrementTermsOfServiceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
