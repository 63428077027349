import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-chart-top-clients',
  templateUrl: './chart-top-clients.component.html',
  styleUrls: ['./chart-top-clients.component.scss']
})
export class ChartTopClientsComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('barCanvas') barCanvas: ElementRef | undefined;
  barChart: any;
  actualValue = '';
  comparedValue = '';
  @Input() inputDataObject;

  chartOptions = {
    radius: '100%',
    plugins: {
      legend: {
        position: 'right',
        align: 'center',
        padding: {
          top: 10,
          left:100
      },
        title: {
          display: true,
          text: 'Top Clients',
          position: 'left',
          align: 'start',
          padding: {
            top: 10,
            left:10,
            right:0
        },
          font: {
            family: 'Montserrat',
            size: 15,
            weight: '600',
          },
         // padding: 5
        },
        labels: {
          usePointStyle: true,
          font: {
            family: 'Montserrat'
          },
        },
      },
      tooltip: {
        yAlign: 'bottom',
        displayColors: false,
        titleFont: {
          font: {
            family: 'Montserrat',
            // size: 16,
            weight: 'bold',
          },
        },
        titleMarginBottom: -6,
        bodySpacing: 6,
        bodyFont: {
          font: {
            family: 'Montserrat',
          },
        },
        padding: 12,
        // boxPadding: 4,
        callbacks: {
          title: (context) => {
            return context[0].label;
          },
          beforeBody: (context) => {
            const length = context[0].label.length;
            return '_'.repeat(length + 6);
          },
          label: (context) => {
            console.log(context);
            const label = [];
            label.push(`Gross Percent: ${context.formattedValue}%`);
            label.push(`Sales: $${this.inputDataObject.sales[context.dataIndex]}`);
            label.push(`Event Count: ${this.inputDataObject.eventsCount[context.dataIndex]}`);
            label.push(`Sales per Event: $${this.inputDataObject.perEvent[context.dataIndex]}`);

            return label;
          },
        },
      },
    },
    animation: {
      animateRotate: true,
      animateScale: true,
    }
  };

  constructor() { }

  ngOnInit(): void {
    this.getSetLastValues();
    if (document.getElementsByTagName('body')[0].classList.contains('removeAllPadding')) {
      document.getElementsByTagName('body')[0].classList.remove('removeAllPadding');
    }
  }

  ngAfterViewInit(): void {
    this.barChartMethod();
  }

  ngOnChanges() {
    this.barChartMethod();
  }

  barChartMethod() {
    this.barChart = new Chart(this.barCanvas?.nativeElement, {
      type: 'pie',
      data: {
        labels: this.inputDataObject.labels,
        datasets: [{
          label: 'Sales',
          data: this.inputDataObject.eventSalesDataSet1,
          // weight: 4,
          // backgroundColor: this.getRandomColor(),
          backgroundColor: [
            FILL_COLORS.red,
            FILL_COLORS.pink,
            FILL_COLORS.magenta,
            FILL_COLORS.voilet,
            FILL_COLORS.blue,
            FILL_COLORS.aqua,
            FILL_COLORS.orange,
            FILL_COLORS.yellow,
            FILL_COLORS.green,
            FILL_COLORS.lime,
            FILL_COLORS.grey,
          ],
        },
        ]
      },
      options: this.chartOptions,
    });
  }

  getRandomColor() {
    const colorList = [];
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < this.inputDataObject.eventSalesDataSet1.length; i++) {
      color += letters[Math.floor(Math.random() * 16)];
      colorList.push(color);
    }

    return colorList;
  }

  generateXAxisLabel(mode) {
    if (mode === "Event Count") {
      return mode;
    } else {
      return mode + " ( in USD )"
    }
  }

  generateUnit(mode?) {
    const selectedMode = mode ? mode : this.inputDataObject.mode;
    if (selectedMode === "Event Count") {
      return '';
    } else {
      return 'US$';
    }
  }

  getSetLastValues() {
    const set1 = this.inputDataObject.eventSalesDataSet1;
    const set2 = this.inputDataObject.eventSalesDataSet2;
    this.actualValue = set1[set1.length - 1];
    this.comparedValue = set2[set2.length - 1];

    console.log(this.inputDataObject);
  }

  ngOnDestroy() {
    this.barChart.destroy();
  }
}

export const FILL_COLORS = {
  red: 'rgb(255, 99, 132)',
  pink: 'rgb(220, 95, 171)',
  magenta: 'rgb(192, 94, 219)',
  voilet: 'rgb(131, 88, 218)',
  blue: 'rgb(54, 162, 235)',
  aqua: 'rgb(0, 255, 255)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(100, 200, 125)',
  lime: 'rgb(0, 255, 0)',
  grey: 'rgb(201, 203, 207)',
};
