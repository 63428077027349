<div class="page-container">
    <div class="page-header">
        <div class="page-heading">
            <h1 class="heading" translate>label_manage_waffle_menu</h1>
        </div>
        <div class="page-actions">
            <button pButton (click)="onAddWaffle()" class="extra-large-btn" type="button"
                [label]="'btn_add_new'|translate"></button>
        </div>
    </div>

    <div class="page-body">
        <!-- <app-mi-grid-v2 [url]="gridurl" [columnDefs]="columns" (actionBtnClickedEvent)="onActionBtnClicked($event)"
            [actionButtonsDefs]="buttons" [isExportVisible]="false" divClass="data-grid-lines" [searchBar]="searchBar"
            [exportUrl]="exportBaseUrl" (isRecordsVisible)="isRecordsVisible($event)">
        </app-mi-grid-v2> -->

        <p-table [value]="products" [columns]="cols" responsiveLayout="scroll" (onRowReorder)="onRowReorder($event)">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th style="width:3rem"></th>
                    <th *ngFor="let col of columns">
                        {{col.header}}
                    </th>
                    <th >Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
                <tr [pReorderableRow]="index">
                    <td>
                        <span class="pi pi-bars" pReorderableRowHandle></span>
                    </td>
                    <td *ngFor="let col of columns" class="align-center">
                        <img src="{{rowData[col.field]}}"  class = "waffle-img-width" *ngIf="col.field == 'signUrl' "/>
                        <span *ngIf="col.field !== 'signUrl' ">{{rowData[col.field]}}</span>
                        
                        
                        
                        
                    </td>
                    <td class="action-icons1 ng-star-inserted" >
                        <div class="action-icon1">
                            <div appclickoutside="" class="grid-menu1" style="display: flex; justify-content: center;">
                                <div class="grid-menu-item ng-star-inserted">
                                    <div title="Edit" class="ng-star-inserted"></div>
                                    <div class="edit-pencil-icon ng-star-inserted" title="Edit" (click)="onActionBtnClicked('edit',rowData)"></div>
                                </div>
                                <div class="grid-menu-item ng-star-inserted" *ngIf="rowData.activated">
                                    <div title="" class="ng-star-inserted">
                                        <div class="event-hold-icon deactivate" title="Deactivate" (click)="onActionBtnClicked('status',rowData)"></div>
                                    </div>
                                </div>
                                <div class="grid-menu-item ng-star-inserted" *ngIf="!rowData.activated">
                                    <div title="" class="ng-star-inserted">
                                        <div class="event-open-icon activate" title="Activate" (click)="onActionBtnClicked('status',rowData)"></div>
                                    </div>
                                </div>

                                <div class="grid-menu-item ng-star-inserted">
                                    <div title="Details" class="ng-star-inserted"></div>
                                    <div class="event-delete-icon ng-star-inserted" title="Delete" (click)="onActionBtnClicked('delete',rowData)"></div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>


    </div>

</div>