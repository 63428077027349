import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNoWhiteSpace]'
})
export class NoWhitespaceDirective {
  constructor(private elementRef: ElementRef, private ngControl: NgControl) { }

  @HostListener('blur') trimText() {
    this.elementRef.nativeElement.value = (this.elementRef.nativeElement.value || '').trim();
    this.ngControl.control.setValue(this.elementRef.nativeElement.value);
  }
}
