import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-staff-layout',
  templateUrl: './staff-layout.component.html',
  styleUrls: ['./staff-layout.component.scss']
})
export class StaffLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    if (screen.width < 770) {
      if (!document.getElementsByTagName('body')[0].classList.contains('closed')) {
        document.getElementsByTagName('body')[0].classList.toggle('closed');
      }
    }
  }

  clickOutsideOfSideBar() {
    if (screen.width < 740) {
      if (!document.getElementsByTagName('body')[0].classList.contains('closed')) {
        document.getElementsByTagName('body')[0].classList.toggle('closed');
      }
    }
  }

}
