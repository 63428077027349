export const environment = {
  production: false,
  basePath: 'https://devapi.konaos.com/api/v1',
  basePathCustomer: 'https://devapi.konaos.com/api/v2',
  idleTimeEnable: true,
  baseBrowserUrl: 'https://devadmin.konaos.com/#/',
  mapKey: 'AIzaSyB5QIwjz_0xE8ddZWPxaE2okoEWny2yIf4',  // dev
  loginSecret: '6KKIkko2VZxSJKyFQ7t8uj51WODr3Y6HOpDh0xnLAdM=',
  countryCode: '+91',
  supportedCountry: ['IN', 'US'],
  brandIds: {
    kona: {brandId: '66704154faed4c5991533eb5253815d9', domain: 'devcustomer-kona-ice.konaos.com'},
    toms: {brandId: '4553cb46d02d40e4ab2732673e141ac3', domain: 'devcustomer-toms.konaos.com'},
    anns: {brandId: 'ffc8d7f43fd64dbb99e97c9aa42e96aa', domain: 'devcustomer-toms.konaos.com'}
  }

  // production: false,
  // basePath: 'https://uatapi.konaos.com/api/v1',
  // basePathCustomer: 'https://uatapi.konaos.com/api/v2',
  // idleTimeEnable: true,
  // baseBrowserUrl: 'https://uatadmin.konaos.com/#/',
  // mapKey: 'AIzaSyCrdSGYKHIR1_0t4HO2uX5V7Kgg_c1cwnE',
  // loginSecret: '6KKIkko2VZxSJKyFQ7t8uj51WODr3Y6HOpDh0xnLAdM=',
  // countryCode: '+1',
  // supportedCountry: ['US'],
  // brandIds: {
  //   kona: {brandId: '66704154faed4c5991533eb5253815d9', domain: 'uatcustomer-kona-ice.konaos.com'},
  //   toms: {brandId: '4553cb46d02d40e4ab2732673e141ac3', domain: 'uatcustomer-toms.konaos.com'}
  // }
};
