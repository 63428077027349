import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appFourDecimal]'
})
export class FourDecimalDirective {

  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'v'];

  constructor(private el: ElementRef, private ngControl: NgControl) {
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    if (!this.regex.test(event.clipboardData.getData('text/plain'))) {
      event.preventDefault();
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key === 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

  @HostListener('blur') trimText() {
    if (this.el.nativeElement.value.includes('v')) {
      this.el.nativeElement.value = ('').trim();
      this.ngControl.control.setValue(this.el.nativeElement.value);
    }
    
  }

}
