import { Injectable, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { AppSettings } from '../app.settings';
import { CommonBindingDataService } from './common-binding-data.service';

@Injectable()
export class UiService {

  private currentLanguage = 'en_US';

  private _languageChangeEvent = new EventEmitter<string>();

  private _sidebarToggledEvent = new Subject<string>();

  private _LoaderStateChangeEvent = new EventEmitter<string>();

  private _profileUpdateEvent = new Subject<string>();
  showMessageEvent = new Subject<any>();

  message: any = {
    severity: '',
    summary: '',
    detail: ''
  };
  isShowMessage: boolean = false;
  constructor(
    private common: CommonBindingDataService,
    // private restApiService: RestApiService
  ) { }

  changeLanguage(language: string) {
    this.currentLanguage = language;
    this._languageChangeEvent.emit(language);
  }

  get languageChangeEvent(): EventEmitter<string> {
    return this._languageChangeEvent;
  }


  changeLoaderStatus(state: string) {
    this._LoaderStateChangeEvent.emit(state);
  }
  get LoaderStatusChangeEvent(): EventEmitter<string> {
    return this._LoaderStateChangeEvent;
  }

  sidebarToggled() {
    this._sidebarToggledEvent.next();
  }

  get sidebarToggledEvent(): Subject<string> {
    return this._sidebarToggledEvent;
  }

  profileUpdated() {
    this._profileUpdateEvent.next();
  }

  get profileUpdatedEvent(): Subject<string> {
    return this._profileUpdateEvent;
  }

  /**
  *
  * @param message : Message that want to show
  * @param type : Type of message Success,Warning and Error
  */
  showMessage(message: string, type: string = AppSettings.MESSAGE_TYPE.SUCCESS) {
    this.message = '';
    // Message display setting.
    const messageSetting = {
      severity: type,
      summary: '',
      detail: message
    };

    switch (type) {
      case AppSettings.MESSAGE_TYPE.SUCCESS:
        messageSetting.severity = AppSettings.MESSAGE_TYPE.SUCCESS;
        messageSetting.summary = this.common.getLabel('msg_success_summary');
        break;
      case AppSettings.MESSAGE_TYPE.ERROR:
        messageSetting.severity = AppSettings.MESSAGE_TYPE.ERROR;
        messageSetting.summary = this.common.getLabel('msg_error_summary');
        break;
      case AppSettings.MESSAGE_TYPE.WARNING:
        messageSetting.severity = AppSettings.MESSAGE_TYPE.WARNING;
        messageSetting.summary = this.common.getLabel('msg_warning_summary');
        break;
      case AppSettings.MESSAGE_TYPE.INFO:
        messageSetting.severity = AppSettings.MESSAGE_TYPE.INFO;
        messageSetting.summary = this.common.getLabel('msg_info_summary');
        break;
      default:
        messageSetting.severity = AppSettings.MESSAGE_TYPE.SUCCESS;
        messageSetting.summary = this.common.getLabel('msg_success_summary');
        break;
    }
    // this.isShowMessage = true;
    this.message = messageSetting;
    this.showMessageEvent.next(this.message);
  }

}
