import { AfterContentChecked, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FranchiseService } from '../../../franchise/services/franchise-service.service';

@Component({
  selector: 'app-notification-strip',
  templateUrl: './notification-strip.component.html',
  styleUrls: ['./notification-strip.component.scss']
})
export class NotificationStripComponent implements OnInit, OnChanges {

  @ViewChild('notifications') notifications: ElementRef;
  displayPopup:boolean = false;
  @Input() broadcastData;
  @Input() isStaff: boolean;
  constructor(
    private franchiseService: FranchiseService
  ) { }

  ngOnInit(): void {
    
  }
  ngOnChanges() {
    this.displayPopup = !this.broadcastData.broadcastMessageViewed && this.broadcastData.broadcastMessage;
    // console.log('displayPopup',this.displayPopup)
    if(this.broadcastData && !this.broadcastData.broadcastMessageViewed && this.broadcastData.broadcastMessage) {
      // const pageHeader = document.querySelector('.admin-panel');
      let pannelClass = this.isStaff?'.staff-panel':'.admin-panel';
      const pageHeader = document.querySelector(pannelClass);
      pageHeader.classList.add('notification-strip');
      setTimeout(() => {
        document.querySelector('.broadcast-message').innerHTML = this.broadcastData.broadcastMessage;
      }, 300);
    }
  }

  closeMessageBox() {
    this.displayPopup = false;
      // const pageHeader = document.querySelector('.admin-panel');
      let pannelClass = this.isStaff?'.staff-panel':'.admin-panel';
      const pageHeader = document.querySelector(pannelClass);
      pageHeader.classList.remove('notification-strip');
      let id = this.broadcastData.userId?this.broadcastData.userId:this.broadcastData.franchiseId;
    this.franchiseService.updateViewedBroadcastMessage(id).subscribe((res)=>{
      // console.log(res)
    });
  }

}
