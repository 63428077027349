<app-loader></app-loader>
<div class="admin-panel">
  <div class="sidebar franchise-sidebar">
    <app-sidebar></app-sidebar>
  </div>
  <app-header class="top-bar grid col-nogutter"></app-header>

  <div class="main-panel" id="main-panel" (click) = "clickOutsideOfSideBar()">
    <div class="main-container" id="main-container">
      <div class="main-content" id="main-content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
<app-message></app-message>
<p-toast [style]="{marginTop: '40px'}"></p-toast>
