import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../services/storage.service';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  constructor( 
    private storageService: StorageService
  ) {
    
   }

  ngOnInit() {
    if (screen.width < 770) {
      if (!document.getElementsByTagName('body')[0].classList.contains('closed')) {
        document.getElementsByTagName('body')[0].classList.toggle('closed');
      }
    }
  }

  clickOutsideOfSideBar() {
    if (screen.width < 740) {
      if (!document.getElementsByTagName('body')[0].classList.contains('closed')) {
        document.getElementsByTagName('body')[0].classList.toggle('closed');
      }
    }
  }

  ngOnDestroy() {
    this.storageService.removeItem('persistSelectedBrands');
  }

}
