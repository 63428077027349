import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-footer-terms-content',
  templateUrl: './footer-terms-content.component.html',
})
export class FooterTermsContentComponent implements OnInit {
  @Output() openPrivacy: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  showPrivacy() {
    this.openPrivacy.emit();
  }

}
