import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-footer-privacy-content',
  templateUrl: './footer-privacy-content.component.html',
})
export class FooterPrivacyContentComponent implements OnInit {
  @Output() openTerms: EventEmitter<any> = new EventEmitter();
  @Output() openCcpa: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  showTerms() {
    this.openTerms.emit();
  }

  showCcpa() {
    this.openCcpa.emit();
  }

}
