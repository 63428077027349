<div class="customer-header" *ngIf="isDisplayHeader">
    <div class="logo col-12 grid mar-pad-0">
        <div class="col-1 mar-pad-0 back-btn" *ngIf = "!hideBack">
            <a  href="javascript:void(0)" (click)="onBack()" class="sprite back"></a>
        </div>
        <div class="col-11 mar-pad-0">
        <img src="./assets/images/header-logo.svg" class="logo-img" (click)="navigateToHome()"/>
    </div>
    </div>
</div>
