import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appPreventSomeSpecialCharacters]'
})
export class PreventSomeSpecialCharactersDirective {
  private preventQuote = /["<>]/;
  private special = /[<>]/;
  private allowParenthesisWithoutQuote = /["<>]/;

  @Input() allowQuote;
  @Input() allowParenthesis;

  constructor(private eleRef: ElementRef) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // if(this.allowQuote == 'no' && this.allowParenthesis == 'yes') {
    //   if (this.allowParenthesisWithoutQuote.test(event.key)) {
    //     event.preventDefault();
    //   }
    // } else if(this.allowQuote == 'yes' ) {
    //   if (this.special.test(event.key)) {
    //     event.preventDefault();
    //   }
    // }else {
    //   if (this.preventQuote.test(event.key)) {
    //     event.preventDefault();
    //   }
    // }

    this.checkValidation(event.key);
    
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    this.checkValidation(event.clipboardData.getData('text/plain'));
  }

  checkValidation(text) {
    if(this.allowQuote == 'no' && this.allowParenthesis == 'yes') {
      if (this.allowParenthesisWithoutQuote.test(text)) {
        event.preventDefault();
      }
    } else if(this.allowQuote == 'yes' ) {
      if (this.special.test(text)) {
        event.preventDefault();
      }
    }else {
      if (this.preventQuote.test(text)) {
        event.preventDefault();
      }
    }
  }

}